import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams, useSearch } from '@tanstack/react-router';
import { Error } from 'components/Error';
import { Loading } from 'components/Loading';
import { callAPI, checkKeyAPI, loginAPI } from './home.api';
import { homeRoute } from './home.route';
import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import { socket } from 'lib/socket';
// import { useClient, useMic } from 'lib/agora'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CallIcon from '@mui/icons-material/Call';
import Box from '@mui/material/Box';
import { secondsToHms } from 'util/sec-to-hms';
import CallEndIcon from '@mui/icons-material/CallEnd';
import css from './home.module.css';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import audio from '../../ringing.mp3';
import { logger } from 'util/logger';
import { Autocomplete, Grid, TextField } from '@mui/material';
import {
  getCountries,
  getPhoneCode,
  isValidPhoneNumber,
} from 'libphonenumber-js';

import uuid4 from "uuid4";
import { roomClient } from "lib/agora"
import {
  QRTCMediaDeviceChangeEvent,
  QRTCParams,
} from "qrtc_web_sdk/RoomParams";
type Step = 'number' | 'extension' | 'ringing' | 'answer';

const userAudioDom = new Audio(audio);

const codes = [
  ...new Set(getCountries().map((country) => `+${getPhoneCode(country)}`)),
].sort();

export function HomePage() {


  const [seconds, setSeconds] = useState(0);
  const [step, setStep] = useState<Step>('number');
  const [number, setNumber] = useState('');
  const [sockerError, setSocketError] = useState(false);
  const [shake, setShake] = useState(true);
  const [dot, setDot] = useState('   ');
  const [isPlayAudio, setPlayAudio] = useState(false);
  const [isPlayRing, setPlayRing] = useState(false);
  const [code, setCode] = useState('+976');
  const [cancel, setCancel] = useState(false);
  const [channelName, setChannelName] = useState('');
  const [channelHash, setChannelHash] = useState('');
  // const [token, setToken] = useState('');

  const { type: isNumberDisabled } = useSearch({ from: homeRoute.id });
  const { key } = useParams({ from: homeRoute.id });

  const checkQuery = useQuery({
    queryKey: ['check-key', key],
    queryFn: () => checkKeyAPI(key),
    enabled: Boolean(key),
    retry: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retryOnMount: false,
    onSuccess(data) {
      logger({ from: 'CHECK QUERY', data });
    },
  });
  const loginQuery = useQuery({
    queryKey: ['login', key],
    queryFn: () =>
      loginAPI(
        checkQuery.data?.data?.ext_name,
        checkQuery.data?.data?.ext_password
      ),
    enabled: Boolean(checkQuery.data),
    retry: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retryOnMount: false,
    onSuccess(data) {
      logger({ from: 'LOGIN QUERY', data });
    },
    onError(e){
      logger({error: e, dataL: "err"})
    }
  });
  
  const callMutation = useMutation({
    mutationFn: callAPI,
    mutationKey: ['call'],
    async onSuccess(data) {
      if (cancel) return setCancel(false);

      const { channelName, roomAppId, hash, roomId, confToken } = data;

      logger({ from: 'CALL MUTAION', data });

      // client.setClientRole('host');
      const roomInfo: QRTCParams = {
        serverUrl: 'wss://rtc.callpro.mn/websocket/',
        sdkAppId: roomAppId,
        userSig: "",
        userId: `${roomId}_click-${uuid4()}`,
        userName: "Web",
        roomId: roomId,
        maxRetryTime: 10*1000,
        xConferenceToken:confToken
      }
      
      await roomClient.enterRoom(roomInfo);
      
      // await client.join(roomAppId, aChannel, channelToken, 1);

      socket.emit('room', channelName);

      setChannelName(data.channelName);
      setChannelHash(data.hash);

      socket.emit(
        'message',
        JSON.stringify({
          hash,
          channelName,
          type: 'call',
          fromNumber: number !== "" ? code + ' ' + number : ' ' + loginQuery.data!.result.number,
          fromName: number !== "" ? code + ' ' + number : ' ' + checkQuery.data?.data?.extension_name
          
        })
      );
    },
  });

  useEffect(() => {
    logger({ from: 'STEP', step });

    if (step === 'answer') {
      const secondsInterval = setInterval(() => {
        setSeconds((second) => second + 1);
      }, 1000);

      return () => clearInterval(secondsInterval);
    }

    if (step === 'ringing') {
      const shakeInterval = setInterval(() => {
        setShake((shake) => !shake);
      }, 1000);

      const dotInterval = setInterval(() => {
        setDot((dot) => {
          if (dot === '   ') return '.  ';
          else if (dot === '.  ') return '.. ';
          else if (dot === '.. ') return '...';
          else return '   ';
        });
      }, 500);

      return () => {
        clearInterval(shakeInterval);
        clearInterval(dotInterval);
      };
    }
  }, [step]);

  useEffect(() => {
    const audio2 = new Audio(audio);

    audio2.loop = true;
    console.log("Za duyydaj bna ");
    isPlayRing ? audio2.play() : audio2.pause();
  }, [isPlayRing]);

  useEffect(() => {
    console.log("Za IsPlayAudio bna ");
    isPlayAudio ? userAudioDom.play() : userAudioDom.pause();
  }, [isPlayAudio]);

  useEffect(() => {
    socket.on('connect', () => {
      logger({ from: 'SOCKET CONNECTION', socket });
    });

    socket.on('disconnect', () => {
      logger({ from: 'SOCKET DISCONNECT', socket });
    });

    socket.on('connect_error', () => {
      setSocketError(true);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
    };
  }, []);

  useEffect(() => {
    socket.on('message', (data: any) => {
      if (data.type === 'hangup') {
        logger({ type: 'bye2', channelName });
        onEndCall();
      } else if (data.type === 'bye') {
        logger({ type: 'bye2', channelName });
        onEndCall();
      } else if (data.type === 'ringing') {
        setStep('ringing');
        // setPlayRing(true);
      } else if (data.type === 'answer') {
        setStep('answer');
        setPlayRing(false);
      }

      logger({ from: 'SOCKET MESSAGE', data });
    });

    return () => {
      socket.off('message');
    };
  }, [channelName]);

  useEffect(() => {
    if (channelName) {
      if (!channelName) return alert('Channel name is empty');


      roomClient.on( "remoteUserEnterRoom", ( userInfo: any ) => {
        console.log("remoteUserEnterRoom: ");
       } );

      roomClient.on( "remoteUserLeaveRoom", ( userInfo: any ) => { 
        console.log("remoteUserLeaveRoom: ");
      } );
      roomClient.on( "enterRoom", async () => { 
        await roomClient.startLocalAudio(false);
      });
      // roomClient.on("deviceChanged", (deviceEvent)=> {
      //   switch (deviceEvent.event) {
      //     case QRTCMediaDeviceChangeEvent.QRTCMediaDevice_WebCamAdded:
      //         console.log(`[App] New camera id=${deviceEvent.deviceId}`);
      //         break;
      //     case QRTCMediaDeviceChangeEvent.QRTCMediaDevice_WebCamRemoved:
      //         console.log(`[App] Removed camera id=${deviceEvent.deviceId}`);
      //         break;
      //     case QRTCMediaDeviceChangeEvent.QRTCMediaDevice_MicAdded:
      //         console.log(`[App] New microphone id=${deviceEvent.deviceId}`);
      //         roomClient.setCurrentMic(deviceEvent.deviceId);
      //         break;
      //     case QRTCMediaDeviceChangeEvent.QRTCMediaDevice_MicRemoved:
      //         console.log(`[App] Removed microphone id=${deviceEvent.deviceId}`);
      //         break;
      //     case QRTCMediaDeviceChangeEvent.QRTCMediaDevice_SpeakerAdded:
      //         console.log(`[App] New speaker id=${deviceEvent.deviceId}`);
      //         roomClient.setCurrentSpeaker(deviceEvent.deviceId, [user.userAudioDom]);
      //         break;
      //     case QRTCMediaDeviceChangeEvent.QRTCMediaDevice_SpeakerRemoved:
      //         console.log(`[App] Removed speaker id=${deviceEvent.deviceId}`);
      //         break;
      //     default:
      //         break;
      //   }
      // })
      roomClient.on( "error", (data) => {
        console.log("ERROR: ", data);
       } );
      roomClient.on("userAudioAvailable", async (audioInfo) => {
        console.log(
            `[userAudioAvailable] audioInfo=${JSON.stringify(audioInfo)}`
        );
        const { userId, available } = audioInfo;
        if (available && userAudioDom) {
          console.log("DOM available: ", available);
          console.log("userAudio.current: ", userAudioDom);
          setPlayAudio(true);
          await roomClient.startRemoteAudio(
              userId,
              // @ts-ignore:
              userAudioDom
          )
            
        } else {
            //需要刷新一下对应的video控件
        }
    });

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomClient,channelName]);

  function onNumber(event: ChangeEvent<HTMLInputElement>) {
    const number = event.target.value;

    if (isNaN(Number(number))) return;

    const blacklists = ['9', '8', '7', '6', undefined];

    if (!blacklists.includes(number[0]) && code === '+976') return;

    setNumber(number);
  }

  function onNext() {
    setStep('extension');
  }

  function onStartCall(shortcut: number) {
    setStep('ringing');

    callMutation.mutate({
      callToken: loginQuery.data!.result.callToken,
      destination: String(shortcut),
      fromNumber: number !== "" ? number : loginQuery.data!.result.number,
      fromName: number !== "" ? "" : checkQuery.data?.data?.extension_name
    });
  }

  async function onEndCall() {
    logger({ type: 'end', channelName, channelHash });

    if (!channelName) return;

    socket.emit(
      'message',
      JSON.stringify({
        type: 'hangup',
        channelName: channelName,
        hash: channelHash,
      })
    );

    socket.emit('leave', channelName);

    setStep('number');
    setSeconds(0);
    setPlayRing(false);
    setChannelName('');
    setChannelHash('');

    await roomClient.close()
  }

  function onCode(code: string) {
    setCode(code);
  }

  function onEndBeforeCall() {
    setStep('number');
    setCancel(true);
  }

  if (!key) return <Error message="Key not found" />;
  if (sockerError)
    return <Error display="Хүсэлт биелэх боломжгүй" message="Socket error" />;
  // if (error)
  //   return <Error message="Mic error" display="Та микрофоноо зөвшөөрнө үү" />;
  if (checkQuery.isLoading) return <Loading />;
  if (checkQuery.isError) return <Error message="API check api error" />;
  // if (checkQuery.data?.message === 'Too Many Requests')
  //   return (
  //     <Error display="Оролдлогын тоо дууслаа" message="API check api error" />
  //   );
  if (checkQuery.data.success === 'false')
    return <Error message="API check api response error" />;

  if (loginQuery.isLoading) return <Loading />;
  if (loginQuery.isError) return <Error message="API login error" />;
  if (callMutation.isError) return <Error message="Call mutation error" />;

  if (step === 'number' && !isNumberDisabled) {
    return (
      <Grid
        container
        spacing={1}
        component="form"
        onSubmit={onNext}
        justifyContent="center"
        width={350}
      >
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography sx={{ textAlign: 'center' }}>
            Та өөрийн утасны дугаараа оруулна уу
          </Typography>

        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            value={code}
            options={codes}
            renderInput={(params) => (
              <TextField {...params} label="" size="small" />
            )}
            disableClearable
            onChange={(_e, num) => onCode(num)}
            noOptionsText="Алга"
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            // label={'Утасны дугаараа оруулна уу'}
            // placeholder="Дугаараа оруулна уу"
            fullWidth
            size="small"
            onChange={onNumber}
            value={number}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button
            endIcon={<NavigateNextIcon fontSize="small" />}
            variant="contained"
            disabled={!isValidPhoneNumber(code + number)}
            type="submit"
            sx={{ backgroundColor: '#0d0c54' }}
            fullWidth
          >
            Үргэлжлүүлэх
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (step === 'extension' || (step === 'number' && isNumberDisabled)) {
    return (
      <MenuList sx={{ width: '100%' }}>
        {checkQuery.data.data.shortcuts.map((shortcut) => (
          <MenuItem
            key={shortcut.number}
            sx={{
              py: 2,
              backgroundColor: '#0d0c54',
              color: 'white',
              '&:hover': {
                backgroundColor: '#19186e',
              },
              border: '1px solid transparent',
              borderRadius: '12px',
              mb: 1,
            }}
            onClick={() => onStartCall(shortcut.number)}
          >
            <ListItemIcon>
              <CallIcon sx={{ fill: 'white' }} />
            </ListItemIcon>
            <ListItemText>{shortcut.name}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  if (step === 'ringing') {
    return (
      <Box sx={{ display: 'grid', placeItems: 'center' }}>
        <Typography sx={{ mb: 3 }}>Дуудаж байна{dot}</Typography>
        <RingVolumeIcon
          className={shake ? css.rotate : ''}
          sx={{ fontSize: 56, color: '#0d0c54', mb: 3 }}
        />

        <Button
          startIcon={<CallEndIcon />}
          onClick={onEndBeforeCall}
          color="error"
          variant="contained"
        >
          Дуудлага таслах
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <Typography
        sx={{
          fontSize: 32,
          textAlign: 'center',
          mb: 2,
        }}
      >
        {secondsToHms(seconds)}
      </Typography>
      <Button
        startIcon={<CallEndIcon />}
        onClick={onEndCall}
        color="error"
        variant="contained"
      >
        Дуудлага таслах
      </Button>
    </Box>
  );
}
