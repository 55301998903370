import CryptoJS from 'crypto-js';

export function decrypt(data: string) {
  const bytes = CryptoJS.AES.decrypt(
    data as string,
    process.env.REACT_APP_CRYPTO_KEY || ''
  );
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}
