export type QRTCParams =
	{
		serverUrl: string,
		sdkAppId: string,
		userSig: string,
		userId: string,
		userName: string,
		roomId: string,
		maxRetryTime?:number, //Maximum reconnection time, in milliseconds.
		xConferenceToken?:string, //meeting token for verification
		avatarUrl?:string, 
		acceptLanguage?:string
		disableDataStats?: boolean | null //Whether to disable data statistics
		rtcResolutionMode?: number, //Video resolution mode
	}

	export class QRTCError extends Error
{
	errorName:string;
	errorCode: number;
	constructor(errorName:string, message: string, errorCode:number)
	{
		super(message);
		this.name = 'YRTCError';
        this.errorName = errorName;
		this.errorCode = errorCode;
	}
}
/* eslint-disable */
export enum QRTCErrorInfo {
	//Enter room related
	ERR_ROOM_ENTER_FAIL = 80000101, //Failed to enter the room
	ERR_ENTER_ROOM_PARAM_NULL = 80000102, //Room parameters are empty
	ERR_SDK_APPID_INVALID = 80000103, //parameter sdkAppId error
	ERR_ROOM_ID_INVALID = 80000104, //parameter roomId error
	ERR_USER_ID_INVALID = 80000105, //parameter userID error
	ERR_USER_SIG_INVALID = 80000106, //parameter userSig error
	ERR_ENTER_ROOM_TIMEOUT = 80000107, //Request to enter the room timed out, please check your network
	//Request timeout related
	ERR_REQUEST_TIMEOUT = 80000201, //General signaling request timed out
	ERR_CREATE_RTC_TRANSPORT_TIMEOUT = 80000202, //createWebRtcTransport Media channel creation response timed out
	ERR_CONNECT_RTC_TRANSPORT_TIMEOUT = 80000203, //connectWebRtcTransport Media channel connect response timed out
	ERR_CONSUME_RTC_TIMEOUT = 80000204, //Media stream consume response timed out
	ERR_PRODUCE_RTC_TIMEOUT = 80000205, //Media stream produce response timed out
	ERR_LEAVE_RTC_ROOM_TIMEOUT = 80000206, //Leave room request timed out
	//Device operation related
	ERR_CAMERA_START_FAIL = 80000301, //Failed to open the camera
	ERR_MIC_START_FAIL = 80000302, //Failed to open the micphone
	ERR_CAMERA_NO_GRANT = 80000303, //The camera device is not authorized, it may be rejected by the user
	ERR_MIC_NO_GRANT = 80000304, //The microphone device is not authorized, it may be rejected by the user
	ERR_MIC_INPUT_INTERRUPT = 80000305, //Audio input is interrupted/restored
	ERR_SPEAKER_OUTPUT_INTERRUPT = 80000306, //Audio output is interrupted/restored
	ERR_MIC_INSERT_OR_REMOVE = 80000307, //Local microphone device hot-plug
	ERR_SPEAKER_INSERT_OR_REMOVE = 80000308, //Local speaker device hot-plug
	ERR_CAMERA_INSERT_OR_REMOVE = 80000309, //Local camera device hot-plug
	ERR_CAMERA_OCCUPIED = 80000310, //Camera is occupied
	ERR_MIC_OCCUPIED = 80000311, //Microphone is occupied
	//Media channel related
	NETWORK_ICE_CONNECTED = 80000501, //Give the upper layer 'connected' for different ICE connection states
	NETWORK_ICE_DISCONNECTED = 80000502,//Give the upper layer 'disconnected' for different ICE connection states
	NETWORK_ICE_FAILED = 80000503,//Give the upper layer 'failed' for different ICE connection states
	NETWORK_ICE_CLOSED = 80000504,//Give the upper layer 'closed' for different ICE connection states
	NETWORK_CONSUME_NO_FIRST_FRAME_IN3S = 80000505,//Subscribe to media but the first frame of data not arrive within 3 seconds（onFirstFrameArrived）
	NETWORK_PRODUCE_NO_FIRST_FRAME_IN3S = 80000507,//If media stream is not pushed within 3 seconds after successful publishing
	NETWORK_CONSUME_NO__FRAME_IN5S = 80000508,//media packets of the specified stream are not received for several seconds (5/10/15)
	NETWORK_CONSUME_PACKET_DROP_UPTO = 80000509,//If the packet loss rate of a subscribed stream is greater than 5%, 10%, or 15%.
	NETWORK_PRODUCE_PACKET_DROP_UPTO = 80000510,//If the packet loss rate of a published stream is greater than 5%, 10%, or 15%.
	NETWORK_PRODUCE_NO_FRAME_IN10S = 80000511,//If no audio or video stream is sent for 10 seconds continuously
	NETWORK_CONSUME_FRAME_RECOVERY = 80000518, //Media packets of the subscribed stream are received again
	NETWORK_CONSUME_NO_PACKET_IN5S = 80000528,//If audio packets of the specified stream are not received for several seconds (5/10/15) continuously
	NETWORK_CONSUME_PACKET_RECOVERY = 80000538, //Audio packets of the subscribed stream are received again.

}

export enum QRTCVideoResolution {
	QRTCVideoResolution_120_120,
	QRTCVideoResolution_160_160,
	QRTCVideoResolution_270_270,
	QRTCVideoResolution_480_480,
	QRTCVideoResolution_160_120,
	QRTCVideoResolution_240_180,
	QRTCVideoResolution_280_210,
	QRTCVideoResolution_320_240,
	QRTCVideoResolution_400_300,
	QRTCVideoResolution_480_360,
	QRTCVideoResolution_640_480,
	QRTCVideoResolution_960_720,
	QRTCVideoResolution_160_90,
	QRTCVideoResolution_256_144,
	QRTCVideoResolution_320_180,
	QRTCVideoResolution_480_270,
	QRTCVideoResolution_640_360,
	QRTCVideoResolution_960_540,
	QRTCVideoResolution_1280_720,
	QRTCVideoResolution_1920_1080
}

export type ResolutionInfo = {
	resWidth: number,
	resHeight: number,
	canScaleDown: boolean,
	scaleDownBy: number,
	maxUpBitrate: number, // Maximum bitrate for the big stream
	maxDownBitrate: number // Maximum bitrate for the small stream
}

export enum QRTCVideoResolutionMode {
	QRTCVideoResolutionModeLandscape, // Landscape resolution
	QRTCVideoResolutionModePortrait // Portrait resolution
}

export enum QRTCVideoStreamType {
	QRTCVideoStreamTypeBig,
	QRTCVideoStreamTypeSmall,
	QRTCVideoStreamTypeSub // Substream for screen sharing
}

export type QRTCVideoEncParams = {
	videoResolution: QRTCVideoResolution,
	resMode: QRTCVideoResolutionMode,
	videoFps: number,
	minVideoBitrate: number,
	maxVideoBitrate: number,
	enableAdjustRes?: boolean // Whether to allow the SDK to dynamically adjust the resolution. If enabled, it will affect cloud recording. For scenarios that require cloud recording, it is recommended to set it to NO. If the video resolution changes during the call, the MP4 recorded by the cloud may not be played normally on most players.
	// For video call mode, if cloud recording is not required, it can be set to YES. In this case, the SDK will automatically select the appropriate resolution based on the current available bandwidth (only for TRTCVideoStreamTypeBig).
}

export enum QRTCMediaDeviceType {
	QRTCMediaDeviceTypeUnknown,
	QRTCMediaDeviceTypeAudioInput,
	QRTCMediaDeviceTypeAudioOutput,
	QRTCMediaDeviceTypeVideoCamera,
	QRTCMediaDeviceTypeVideoWindow,
	QRTCMediaDeviceTypeVideoScreen
}
export type DeviceInfo =
	{
		flag: string,
		name: string,
		version: string
	};

export type QRTCMediaDeviceInfo =
	{
		type: QRTCMediaDeviceType,
		deviceId: string,
		deviceName: string
	}

export type QRTCVolumeInfo =
	{
		userId: string,
		volume: number // The volume of the speaker, ranging from 0 to 100
	}

export type WebCamInfo =
	{
		device: MediaDeviceInfo|null,
		resolution: string

	};


export type PeerInfo =
	{
		id: string,
		displayName: string,
		device: any
	}

export type NeedConsumeInfo = {
	peerId: string,
	producerId: string,
	id?: string,
	kind?: 'audio' | 'video',
	rtpParameters?: any,
	//type,
	appData: any,
	producerPaused?: boolean,
	accept?: any
}

// Network status enumeration
export enum QRTCQuality {
	QRTCQuality_Unknown, 	// Undefined
	QRTCQuality_Excellent, // Best
	QRTCQuality_Good, // Good
	QRTCQuality_Poor, // Average
	QRTCQuality_Bad, // Poor
	QRTCQuality_Vbad, // Very bad
	QRTCQuality_Down // Unavailable
}

// Network quality information object
export type QRTCQualityInfo = {
	userId: string,
	quality: QRTCQuality
}



export enum QRTCVideoFillMode {
	QRTCVideoFillMode_Fill,
	QRTCVideoFillMode_Fit
}

export enum QRTCMediaDeviceChangeEvent {
	QRTCMediaDevice_WebCamAdded,
	QRTCMediaDevice_WebCamRemoved,
	QRTCMediaDevice_MicAdded,
	QRTCMediaDevice_MicRemoved,
	QRTCMediaDevice_SpeakerAdded,
	QRTCMediaDevice_SpeakerRemoved,
	QRTCMediaDevice_Unknown
}

//just for live

export type CameraGroup = {
	id: string,
	name: string
}

export type ProducerInfo = 
	{
		id: string,
		kind: string
	}

export type LivePeerInfo =
	{
		id: string, // The id of the live broadcaster
		displayName: string,
		device: string,
		producers:[ProducerInfo]
	}

	export enum QRTCConnectionState {
		BeforeConnection, // First connection
		Connected,// Connected
		Disconnected,// Connection disconnected
		Reconnected // Reconnected successfully
	}

	
// Local audio statistics information
export interface LocalAudioStats
{
	packetBitrate:number; // Upstream audio rate
	packetsLostRatio:number; // Upstream audio packet loss rate
	jitterBufferDelay:number; // Upstream audio delay
	jitter:number; // Upstream audio network jitter
}

// Remote video statistics information
export interface LocalVideoStats
{
	packetBitrate:number;// Upstream video rate
	packetsLostRatio:number;// Upstream video packet loss rate
	jitterBufferDelay:number;// Upstream video delay
	jitter:number;// Upstream video network jitter
	// Upstream video resolution, resolution cannot be averaged
	framesPerSecond:number;// Upstream video frame rate
}

export interface LocalShareStats
{
	packetBitrate:number;// Upstream video rate
	packetsLostRatio:number;// Upstream video packet loss rate
	jitterBufferDelay:number;// Upstream video delay
	jitter:number;// Upstream video network jitter
	// Upstream video resolution, resolution cannot be averaged
	framesPerSecond:number;// Upstream video frame rate
}


// Remote audio statistics information
export interface RemoteAudioStats
{
	packetBitrate:number; // Downstream audio rate
	packetsLostRatio:number; // Downstream audio packet loss rate
	jitterBufferDelay:number; // Downstream audio delay
	jitter:number; // Downstream audio network jitter
}

// Remote video statistics information
export interface RemoteVideoStats
{
	packetBitrate:number;// Downstream video rate
	packetsLostRatio:number;// Downstream video packet loss rate
	jitterBufferDelay:number;// Downstream video delay
	jitter:number;// Downstream video network jitter
	// Downstream video resolution, resolution cannot be averaged
	framesPerSecond:number;// Downstream video frame rate
}

export interface RemoteShareStats
{
	packetBitrate:number;// Downstream video rate
	packetsLostRatio:number;// Downstream video packet loss rate
	jitterBufferDelay:number;// Downstream video delay
	jitter:number;// Downstream video network jitter
	// Downstream video resolution, resolution cannot be averaged
	framesPerSecond:number;// Downstream video frame rate
}

export enum QRTCAudioProfile {
	AUDIO_PROFILE_STANDARD,	//48000	single channel	40kbps
	AUDIO_PROFILE_HIGH,	//48000	single channel	128kbps
    AUDIO_PROFILE_STANDARD_STEREO,	//48000	dual channel	64kbps
	AUDIO_PROFILE_HIGH_STEREO,	//48000	dual channel	192kbps
}

/* eslint-enable */
// export type NeedConsumeInfo = {
// 	peerId: string,
// 	producerId: string,
// 	kind?: 'audio' | 'video',
// 	appData: any
// }