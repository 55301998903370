import { generateRandomNumber } from './utils';

export class Message
{
	static parse(raw)
	{
		let object;
		const message = {};

		try
		{
			object = JSON.parse(raw);
		}
		catch (error)
		{
			console.log('parse() | invalid JSON: %s', error);

			return;
		}

		if (typeof object !== 'object' || Array.isArray(object))
		{
			console.log('parse() | not an object');

			return;
		}

		// Request.
		if (object.request)
		{
			message.request = true;

			if (typeof object.method !== 'string')
			{
				console.log('parse() | missing/invalid method field');

				return;
			}

			if (typeof object.id !== 'number')
			{
				console.log('parse() | missing/invalid id field');

				return;
			}

			message.id = object.id;
			message.method = object.method;
			message.data = object.data || {};
		}
		// Response.
		else if (object.response)
		{
			message.response = true;

			if (typeof object.id !== 'number')
			{
				console.log('parse() | missing/invalid id field');

				return;
			}

			message.id = object.id;

			// Success.
			if (object.ok)
			{
				message.ok = true;
				message.data = object.data || {};
			}
			// Error.
			else
			{
				message.ok = false;
				message.errorCode = object.errorCode;
				message.errorReason = object.errorReason;
			}
		}
		// Notification.
		else if (object.notification)
		{
			message.notification = true;

			if (typeof object.method !== 'string')
			{
				console.log('parse() | missing/invalid method field');

				return;
			}

			message.method = object.method;
			message.data = object.data || {};
		}
		// Invalid.
		else
		{
			console.log('parse() | missing request/response field');

			return;
		}

		return message;
	}

	static createRequest(method, data)
	{
		const request =
		{
			data    : data || {},
			id      : generateRandomNumber(),
			method  : method,
			request : true,
		};

		return request;
	}

	static createSuccessResponse(request, data)
	{
		const response =
		{
			data     : data || {},
			id       : request.id,
			ok       : true,
			response : true,
		};

		return response;
	}

	static createErrorResponse(request, errorCode, errorReason)
	{
		const response =
		{
			errorCode   : errorCode,
			errorReason : errorReason,
			id          : request.id,
			ok          : false,
			response    : true,
		};

		return response;
	}

	static createNotification(method, data)
	{
		const notification =
		{
			data         : data || {},
			method       : method,
			notification : true,
		};

		return notification;
	}
}

//module.exports = Message;
