import { ThemeProvider } from '@mui/material/styles';
import { ReactNode } from 'react';
import { createTheme, CssBaseline } from '@mui/material';

const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        size: 'small',
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          // margin: '0 50px',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Nunito',
  },
});

export type MuiProviderProps = {
  children: ReactNode;
};

export function MuiProvider(props: MuiProviderProps) {
  const { children } = props;

  return (
    <ThemeProvider
      theme={{
        ...theme,
        palette: {
          ...theme?.palette,
          mode: 'light',
        },
      }}
    >
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
