import { decrypt } from 'lib/crypto';
import { Call, CheckApi, LoginData } from '../../types';

type CheckApiResponse = {
  data: CheckApi;
  success: 'true' | 'false';
  reason?: string;
  message?: string;
};

type LoginResponse = {
  result: {
    appId: string;
    callToken: string;
    number: string;
    refreshToken: string;
    rtmToken: string;
    tenant: string;
  };
  type: 'success' | 'error';
  show: 'true' | 'false';
};

type CallAPIPayload = {
  callToken: LoginData['callToken'];
  destination: string;
  fromNumber: string;
  fromName: string | null | undefined;
};

type CallApiResponse = {
  data: Call;
  status: 'error' | 'success';
};

export async function checkKeyAPI(key: string) {
  const response = await fetch(
    `https://api.callpro.mn/v1/webrtccompany?key=${key}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': key,
      },
    }
  );

  const json = await response.json();

  const data = json as CheckApiResponse;
  data.data.ext_password = decrypt(data.data.ext_password);
  return data;
}

export async function loginAPI(name?: string, password?: string) {
  if (!name && !password) throw new Error('Name and password');

  const response = await fetch(`https://api.callpro.mn/v1/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-API-KEY': process.env.REACT_APP_API_KEY || '',
    },
    body: JSON.stringify({
      name,
      password,
      type: 'web',
    }),
  });

  const json = await response.json();

  const data = json as LoginResponse;

  return data;
}

export async function callAPI(payload: CallAPIPayload) {
  const response = await fetch(`https://api.callpro.mn/v1/call`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-API-KEY': process.env.REACT_APP_API_KEY || '',
    },
    body: JSON.stringify({
      ...payload,
      type: 'sip',
      video: 0,
    }),
  });

  const json = await response.json();
  const data = json as CallApiResponse;

  return data.data;
}
