import {
  RouterProvider as TanStackRouterProvider,
  ReactRouter,
} from '@tanstack/react-router';
import { appRoute } from 'app/app.route';
import { homeRoute } from 'app/home/home.route';

export type IndexSearch = {
  key: string;
};

const routeTree = appRoute.addChildren([homeRoute]);

export const router = new ReactRouter({ routeTree });

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export function RouterProvider() {
  return <TanStackRouterProvider router={router} />;
}
