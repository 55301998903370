import * as sdpTransform from 'sdp-transform';
import * as utils from '../utils';
import * as ortc from '../ortc';
import * as sdpCommonUtils from './sdp/commonUtils';
import * as sdpUnifiedPlanUtils from './sdp/unifiedPlanUtils';
import {
	HandlerFactory,
	HandlerInterface,
	HandlerRunOptions,
	HandlerSendOptions,
	HandlerSendResult,
	HandlerReceiveOptions,
	HandlerReceiveResult,
	HandlerSendDataChannelOptions,
	HandlerSendDataChannelResult,
	HandlerReceiveDataChannelOptions,
	HandlerReceiveDataChannelResult
} from './HandlerInterface';
import { RemoteSdp } from './sdp/RemoteSdp';
import { parse as parseScalabilityMode } from '../scalabilityModes';
import { IceParameters, DtlsRole } from '../Transport';
import {
	RtpCapabilities,
	RtpParameters,
	RtpEncodingParameters
} from '../RtpParameters';
import { SctpCapabilities, SctpStreamParameters } from '../SctpParameters';

const SCTP_NUM_STREAMS = { MIS: 1024, OS: 1024 };

export class Chrome74 extends HandlerInterface
{
	// Handler direction.
	private _direction: 'send' | 'recv' = 'send';
	// Remote SDP handler.
	private _remoteSdp: RemoteSdp|null = null;
	// Generic sending RTP parameters for audio and video.
	private _sendingRtpParametersByKind: { [key: string]: RtpParameters }|null = null;
	// Generic sending RTP parameters for audio and video suitable for the SDP
	// remote answer.
	private _sendingRemoteRtpParametersByKind: { [key: string]: RtpParameters }|null = null;
	// RTCPeerConnection instance.
	private _pc: any;
	// Map of RTCTransceivers indexed by MID.
	private readonly _mapMidTransceiver: Map<string, RTCRtpTransceiver> =
		new Map();
	// Local stream for sending.
	private readonly _sendStream = new MediaStream();
	// Whether a DataChannel m=application section has been created.
	private _hasDataChannelMediaSection = false;
	// Sending DataChannel id value counter. Incremented for each new DataChannel.
	private _nextSendSctpStreamId = 0;
	// Got transport local and remote parameters.
	private _transportReady = false;

	/**
	 * Creates a factory function.
	 */
	static createFactory(): HandlerFactory
	{
		return (): Chrome74 => new Chrome74();
	}

	constructor()
	{
		super();
	}

	get name(): string
	{
		return 'Chrome74';
	}

	close(): void
	{
		console.log('[Chrome74] close()');

		// Close RTCPeerConnection.
		if (this._pc)
		{
			try { this._pc.close(); }
			catch (error) {}
		}
	}

	async getNativeRtpCapabilities(): Promise<RtpCapabilities>
	{
		console.log('[Chrome74] getNativeRtpCapabilities()');

		const pc = new (RTCPeerConnection as any)(
			{
				bundlePolicy       : 'max-bundle',
				iceServers         : [],
				iceTransportPolicy : 'all',
				rtcpMuxPolicy      : 'require',
				sdpSemantics       : 'unified-plan'
			});

		try
		{
			pc.addTransceiver('audio');
			pc.addTransceiver('video');

			const offer = await pc.createOffer();

			try { pc.close(); }
			catch (error) {}

			const sdpObject = sdpTransform.parse(offer.sdp);
			const nativeRtpCapabilities =
				sdpCommonUtils.extractRtpCapabilities({ sdpObject });

			return nativeRtpCapabilities;
		}
		catch (error)
		{
			try { pc.close(); }
			catch (error2) {}

			throw error;
		}
	}

	// eslint-disable-next-line require-await
	async getNativeSctpCapabilities(): Promise<SctpCapabilities>
	{
		console.log('[Chrome74] getNativeSctpCapabilities()');

		return {
			numStreams : SCTP_NUM_STREAMS
		};
	}

	run(
		{
			direction,
			iceParameters,
			iceCandidates,
			dtlsParameters,
			sctpParameters,
			iceServers,
			iceTransportPolicy,
			additionalSettings,
			proprietaryConstraints,
			extendedRtpCapabilities
		}: HandlerRunOptions
	): void
	{
		console.log('[Chrome74] run()');

		this._direction = direction;

		this._remoteSdp = new RemoteSdp(
			{
				dtlsParameters,
				iceCandidates,
				iceParameters,
				sctpParameters
			});

		this._sendingRtpParametersByKind =
		{
			audio : ortc.getSendingRtpParameters('audio', extendedRtpCapabilities),
			video : ortc.getSendingRtpParameters('video', extendedRtpCapabilities)
		};

		this._sendingRemoteRtpParametersByKind =
		{
			audio : ortc.getSendingRemoteRtpParameters('audio', extendedRtpCapabilities),
			video : ortc.getSendingRemoteRtpParameters('video', extendedRtpCapabilities)
		};

		this._pc = new (RTCPeerConnection as any)(
			{
				bundlePolicy       : 'max-bundle',
				iceServers         : iceServers || [],
				iceTransportPolicy : iceTransportPolicy || 'all',
				rtcpMuxPolicy      : 'require',
				sdpSemantics       : 'unified-plan',
				...additionalSettings
			},
			proprietaryConstraints);

		// Handle RTCPeerConnection connection status.
		this._pc.addEventListener('iceconnectionstatechange', () =>
		{
			// eslint-disable-next-line default-case
			switch (this._pc.iceConnectionState)
			{
				case 'checking':
					this.emit('@connectionstatechange', 'connecting');
					break;
				case 'connected':
				case 'completed':
					this.emit('@connectionstatechange', 'connected');
					break;
				case 'failed':
					this.emit('@connectionstatechange', 'failed');
					break;
				case 'disconnected':
					this.emit('@connectionstatechange', 'disconnected');
					break;
				case 'closed':
					this.emit('@connectionstatechange', 'closed');
					break;
			}
		});
	}

	// eslint-disable-next-line require-await
	async updateIceServers(iceServers: RTCIceServer[]): Promise<void>
	{
		console.log('[Chrome74] updateIceServers()');

		const configuration = this._pc.getConfiguration();

		configuration.iceServers = iceServers;

		this._pc.setConfiguration(configuration);
	}

	async restartIce(iceParameters: IceParameters): Promise<void>
	{
		console.log('[Chrome74] restartIce()');

		// Provide the remote SDP handler with new remote ICE parameters.
		this._remoteSdp!.updateIceParameters(iceParameters);

		if (!this._transportReady)
			return;

		if (this._direction === 'send')
		{
			const offer = await this._pc.createOffer({ iceRestart: true });

			console.log(
				`[Chrome74] restartIce() | calling pc.setLocalDescription() [offer:${offer}]`);

			await this._pc.setLocalDescription(offer);

			const answer = { sdp: this._remoteSdp!.getSdp(), type: 'answer' };

			console.log(
				`[Chrome74] restartIce() | calling pc.setRemoteDescription() [answer:${answer}]`);

			await this._pc.setRemoteDescription(answer);
		}
		else
		{
			const offer = { sdp: this._remoteSdp!.getSdp(), type: 'offer' };

			console.log(
				`[Chrome74] restartIce() | calling pc.setRemoteDescription() [offer:${offer}]`);

			await this._pc.setRemoteDescription(offer);

			const answer = await this._pc.createAnswer();

			console.log(
				`[Chrome74] restartIce() | calling pc.setLocalDescription() [answer:${answer}]`);

			await this._pc.setLocalDescription(answer);
		}
	}

	// eslint-disable-next-line require-await
	async getTransportStats(): Promise<RTCStatsReport>
	{
		return this._pc.getStats();
	}

	async send(
		{ track, encodings, codecOptions, codec }: HandlerSendOptions
	): Promise<HandlerSendResult>
	{
		this._assertSendDirection();

		console.log(`[Chrome74] send() [kind:${track.kind}, track.id:${track.id}]`);

		if (encodings && encodings.length > 1)
		{
			encodings.forEach((encoding: RtpEncodingParameters, idx: number) =>
			{
				encoding.rid = `r${idx}`;
			});
		}

		const sendingRtpParameters =
			utils.clone(this._sendingRtpParametersByKind![track.kind]);

		// This may throw.
		sendingRtpParameters.codecs =
			ortc.reduceCodecs(sendingRtpParameters.codecs, codec);

		const sendingRemoteRtpParameters =
			utils.clone(this._sendingRemoteRtpParametersByKind![track.kind]);

		// This may throw.
		sendingRemoteRtpParameters.codecs =
			ortc.reduceCodecs(sendingRemoteRtpParameters.codecs, codec);

		const mediaSectionIdx = this._remoteSdp!.getNextMediaSectionIdx();
		const transceiver = this._pc.addTransceiver(
			track,
			{
				direction     : 'sendonly',
				sendEncodings : encodings,
				streams       : [ this._sendStream ],
			});
		let offer = await this._pc.createOffer();
		let localSdpObject = sdpTransform.parse(offer.sdp);
		let offerMediaObject;

		if (!this._transportReady)
			await this._setupTransport({ localDtlsRole: 'server', localSdpObject });

		// Special case for VP9 with SVC.
		let hackVp9Svc = false;

		const layers =
			parseScalabilityMode((encodings || [{}])[0].scalabilityMode || '');

		if (
			encodings &&
			encodings.length === 1 &&
			layers.spatialLayers > 1 &&
			sendingRtpParameters.codecs[0].mimeType.toLowerCase() === 'video/vp9'
		)
		{
			console.log('[Chrome74] send() | enabling legacy simulcast for VP9 SVC');

			hackVp9Svc = true;
			localSdpObject = sdpTransform.parse(offer.sdp);
			offerMediaObject = localSdpObject.media[mediaSectionIdx.idx];

			sdpUnifiedPlanUtils.addLegacySimulcast(
				{
					numStreams : layers.spatialLayers,
					offerMediaObject,
				});

			offer = { sdp: sdpTransform.write(localSdpObject), type: 'offer' };
		}

		console.log(
			`[Chrome74] send() | calling pc.setLocalDescription() [offer:${offer}]`);

		await this._pc.setLocalDescription(offer);

		// We can now get the transceiver.mid.
		const localId = transceiver.mid;

		// Set MID.
		sendingRtpParameters.mid = localId;

		localSdpObject = sdpTransform.parse(this._pc.localDescription.sdp);
		offerMediaObject = localSdpObject.media[mediaSectionIdx.idx];

		// Set RTCP CNAME.
		sendingRtpParameters.rtcp.cname =
			sdpCommonUtils.getCname({ offerMediaObject });

		// Set RTP encodings by parsing the SDP offer if no encodings are given.
		if (!encodings)
		{
			sendingRtpParameters.encodings =
				sdpUnifiedPlanUtils.getRtpEncodings({ offerMediaObject });
		}
		// Set RTP encodings by parsing the SDP offer and complete them with given
		// one if just a single encoding has been given.
		else if (encodings.length === 1)
		{
			let newEncodings =
				sdpUnifiedPlanUtils.getRtpEncodings({ offerMediaObject });

			Object.assign(newEncodings[0], encodings[0]);

			// Hack for VP9 SVC.
			if (hackVp9Svc)
				newEncodings = [ newEncodings[0] ];

			sendingRtpParameters.encodings = newEncodings;
		}
		// Otherwise if more than 1 encoding are given use them verbatim.
		else
		{
			sendingRtpParameters.encodings = encodings;
		}

		// If VP8 or H264 and there is effective simulcast, add scalabilityMode to
		// each encoding.
		if (
			sendingRtpParameters.encodings.length > 1 &&
			(
				sendingRtpParameters.codecs[0].mimeType.toLowerCase() === 'video/vp8' ||
				sendingRtpParameters.codecs[0].mimeType.toLowerCase() === 'video/h264'
			)
		)
		{
			for (const encoding of sendingRtpParameters.encodings)
			{
				encoding.scalabilityMode = 'S1T3';
			}
		}

		this._remoteSdp!.send(
			{
				answerRtpParameters : sendingRemoteRtpParameters,
				codecOptions,
				extmapAllowMixed    : true,
				offerMediaObject,
				offerRtpParameters  : sendingRtpParameters,
				reuseMid            : mediaSectionIdx.reuseMid
			});

		const answer = { sdp: this._remoteSdp!.getSdp(), type: 'answer' };

		console.log(
			`[Chrome74] send() | calling pc.setRemoteDescription() [answer:${answer}]`);

		await this._pc.setRemoteDescription(answer);

		// Store in the map.
		this._mapMidTransceiver.set(localId, transceiver);

		return {
			localId,
			rtpParameters : sendingRtpParameters,
			rtpSender     : transceiver.sender
		};
	}

	async stopSending(localId: string): Promise<void>
	{
		this._assertSendDirection();

		console.log(`[Chrome74] stopSending() [localId:${localId}]` );

		const transceiver = this._mapMidTransceiver.get(localId);

		if (!transceiver)
			throw new Error('associated RTCRtpTransceiver not found');

		transceiver.sender.replaceTrack(null);
		this._pc.removeTrack(transceiver.sender);
		this._remoteSdp!.closeMediaSection(transceiver!.mid!);

		const offer = await this._pc.createOffer();

		console.log(
			`[Chrome74] stopSending() | calling pc.setLocalDescription() [offer:${offer}]`);

		await this._pc.setLocalDescription(offer);

		const answer = { sdp: this._remoteSdp!.getSdp(), type: 'answer' };

		console.log(
			`[Chrome74] stopSending() | calling pc.setRemoteDescription() [answer:${answer}]`);

		await this._pc.setRemoteDescription(answer);
	}

	async replaceTrack(
		localId: string, track: MediaStreamTrack | null
	): Promise<void>
	{
		this._assertSendDirection();

		if (track)
		{
			console.log(
				`[Chrome74] replaceTrack() [localId:${localId}, track.id:${track.id}]`);
		}
		else
		{
			console.log(`[Chrome74] replaceTrack() [localId:${localId}, no track]` );
		}

		const transceiver = this._mapMidTransceiver.get(localId);

		if (!transceiver)
			throw new Error('associated RTCRtpTransceiver not found');

		await transceiver.sender.replaceTrack(track);
	}

	async setMaxSpatialLayer(localId: string, spatialLayer: number): Promise<void>
	{
		this._assertSendDirection();

		console.log(
			`[Chrome74] setMaxSpatialLayer() [localId:${localId}, spatialLayer:${spatialLayer}]`);

		const transceiver = this._mapMidTransceiver.get(localId);

		if (!transceiver)
			throw new Error('associated RTCRtpTransceiver not found');

		const parameters = transceiver.sender.getParameters();

		parameters.encodings.forEach((encoding: RTCRtpEncodingParameters, idx: number) =>
		{
			if (idx <= spatialLayer)
				encoding.active = true;
			else
				encoding.active = false;
		});

		await transceiver.sender.setParameters(parameters);
	}

	async setRtpEncodingParameters(localId: string, params: any): Promise<void>
	{
		this._assertSendDirection();

		console.log(
			`[Chrome74] setRtpEncodingParameters() [localId:${localId}, params:${params}]` );

		const transceiver = this._mapMidTransceiver.get(localId);

		if (!transceiver)
			throw new Error('associated RTCRtpTransceiver not found');

		const parameters = transceiver.sender.getParameters();

		parameters.encodings.forEach((encoding: RTCRtpEncodingParameters, idx: number) =>
		{
			parameters.encodings[idx] = { ...encoding, ...params };
		});

		await transceiver.sender.setParameters(parameters);
	}

	// eslint-disable-next-line require-await
	async getSenderStats(localId: string): Promise<RTCStatsReport>
	{
		this._assertSendDirection();

		const transceiver = this._mapMidTransceiver.get(localId);

		if (!transceiver)
			throw new Error('associated RTCRtpTransceiver not found');

		return transceiver.sender.getStats();
	}

	async sendDataChannel(
		{
			ordered,
			maxPacketLifeTime,
			maxRetransmits,
			label,
			protocol,
			priority
		}: HandlerSendDataChannelOptions
	): Promise<HandlerSendDataChannelResult>
	{
		this._assertSendDirection();

		const options =
		{
			id         : this._nextSendSctpStreamId,
			maxPacketLifeTime,
			maxRetransmits,
			negotiated : true,
			ordered,
			priority,
			protocol
		};

		console.log(`[Chrome74] sendDataChannel() [options:${options}]` );

		const dataChannel = this._pc.createDataChannel(label, options);

		// Increase next id.
		this._nextSendSctpStreamId =
			++this._nextSendSctpStreamId % SCTP_NUM_STREAMS.MIS;

		// If this is the first DataChannel we need to create the SDP answer with
		// m=application section.
		if (!this._hasDataChannelMediaSection)
		{
			const offer = await this._pc.createOffer();
			const localSdpObject = sdpTransform.parse(offer.sdp);
			const offerMediaObject = localSdpObject.media
				.find((m: any) => m.type === 'application');

			if (!this._transportReady)
				await this._setupTransport({ localDtlsRole: 'server', localSdpObject });

			console.log(
				`[Chrome74] sendDataChannel() | calling pc.setLocalDescription() [offer:${offer}]`);

			await this._pc.setLocalDescription(offer);

			this._remoteSdp!.sendSctpAssociation({ offerMediaObject });

			const answer = { sdp: this._remoteSdp!.getSdp(), type: 'answer' };

			console.log(
				`[Chrome74] sendDataChannel() | calling pc.setRemoteDescription() [answer:${answer}]`);

			await this._pc.setRemoteDescription(answer);

			this._hasDataChannelMediaSection = true;
		}

		const sctpStreamParameters: SctpStreamParameters =
		{
			maxPacketLifeTime : options.maxPacketLifeTime,
			maxRetransmits    : options.maxRetransmits,
			ordered           : options.ordered,
			streamId          : options.id
		};

		return { dataChannel, sctpStreamParameters };
	}

	async receive(
		{ trackId, kind, rtpParameters }: HandlerReceiveOptions
	): Promise<HandlerReceiveResult>
	{
		this._assertRecvDirection();

		console.log(`[Chrome74] receive() [trackId:${trackId}, kind:${kind}]`);

		const localId = rtpParameters.mid || String(this._mapMidTransceiver.size);

		this._remoteSdp!.receive(
			{
				kind,
				mid                : localId,
				offerRtpParameters : rtpParameters,
				streamId           : rtpParameters!.rtcp!.cname!,
				trackId
			});

		const offer = { sdp: this._remoteSdp!.getSdp(), type: 'offer' };

		console.log(
			`[Chrome74] receive() | calling pc.setRemoteDescription() [offer:${offer}]`);

		await this._pc.setRemoteDescription(offer);

		let answer = await this._pc.createAnswer();
		const localSdpObject = sdpTransform.parse(answer.sdp);
		const answerMediaObject = localSdpObject.media
			.find((m: any) => String(m.mid) === localId);

		// May need to modify codec parameters in the answer based on codec
		// parameters in the offer.
		sdpCommonUtils.applyCodecParameters(
			{
				answerMediaObject,
				offerRtpParameters : rtpParameters
			});

		answer = { sdp: sdpTransform.write(localSdpObject), type: 'answer' };

		if (!this._transportReady)
			await this._setupTransport({ localDtlsRole: 'client', localSdpObject });

		console.log(
			`[Chrome74] receive() | calling pc.setLocalDescription() [answer:${answer}]`);

		await this._pc.setLocalDescription(answer);

		const transceiver = this._pc.getTransceivers()
			.find((t: RTCRtpTransceiver) => t.mid === localId);

		if (!transceiver)
			throw new Error('new RTCRtpTransceiver not found');

		// Store in the map.
		this._mapMidTransceiver.set(localId, transceiver);

		return {
			localId,
			rtpReceiver : transceiver.receiver,
			track       : transceiver.receiver.track
		};
	}

	async stopReceiving(localId: string): Promise<void>
	{
		this._assertRecvDirection();

		console.log(`[Chrome74] stopReceiving() [localId:${localId}]` );

		const transceiver = this._mapMidTransceiver.get(localId);

		if (!transceiver)
			throw new Error('associated RTCRtpTransceiver not found');

		this._remoteSdp!.closeMediaSection(transceiver!.mid!);

		const offer = { sdp: this._remoteSdp!.getSdp(), type: 'offer' };

		console.log(`[Chrome74] stopReceiving() | calling pc.setRemoteDescription() [offer:${offer}]`);

		await this._pc.setRemoteDescription(offer);

		const answer = await this._pc.createAnswer();

		console.log(
			`[Chrome74] stopReceiving() | calling pc.setLocalDescription() [answer:${answer}]`);

		await this._pc.setLocalDescription(answer);
	}

	// eslint-disable-next-line require-await
	async getReceiverStats(localId: string): Promise<RTCStatsReport>
	{
		this._assertRecvDirection();

		const transceiver = this._mapMidTransceiver.get(localId);

		if (!transceiver)
			throw new Error('associated RTCRtpTransceiver not found');

		return transceiver.receiver.getStats();
	}

	async receiveDataChannel(
		{ sctpStreamParameters, label, protocol }: HandlerReceiveDataChannelOptions
	): Promise<HandlerReceiveDataChannelResult>
	{
		this._assertRecvDirection();

		const {
			streamId,
			ordered,
			maxPacketLifeTime,
			maxRetransmits
		}: SctpStreamParameters = sctpStreamParameters;

		const options =
		{
			id         : streamId,
			maxPacketLifeTime,
			maxRetransmits,
			negotiated : true,
			ordered,
			protocol
		};

		console.log(`[Chrome74] receiveDataChannel() [options:${options}]` );

		const dataChannel = this._pc.createDataChannel(label, options);

		// If this is the first DataChannel we need to create the SDP offer with
		// m=application section.
		if (!this._hasDataChannelMediaSection)
		{
			this._remoteSdp!.receiveSctpAssociation();

			const offer = { sdp: this._remoteSdp!.getSdp(), type: 'offer' };

			console.log(
				`[Chrome74] receiveDataChannel() | calling pc.setRemoteDescription() [offer:${offer}]`);

			await this._pc.setRemoteDescription(offer);

			const answer = await this._pc.createAnswer();

			if (!this._transportReady)
			{
				const localSdpObject = sdpTransform.parse(answer.sdp);

				await this._setupTransport({ localDtlsRole: 'client', localSdpObject });
			}

			console.log(
				`[Chrome74] receiveDataChannel() | calling pc.setRemoteDescription() [answer:${answer}]`);

			await this._pc.setLocalDescription(answer);

			this._hasDataChannelMediaSection = true;
		}

		return { dataChannel };
	}

	private async _setupTransport(
		{
			localDtlsRole,
			localSdpObject
		}:
		{
			localDtlsRole: DtlsRole;
			localSdpObject?: any;
		}
	): Promise<void>
	{
		if (!localSdpObject)
			localSdpObject = sdpTransform.parse(this._pc.localDescription.sdp);

		// Get our local DTLS parameters.
		const dtlsParameters =
			sdpCommonUtils.extractDtlsParameters({ sdpObject: localSdpObject });

		// Set our DTLS role.
		dtlsParameters.role = localDtlsRole;

		// Update the remote DTLS role in the SDP.
		this._remoteSdp!.updateDtlsRole(
			localDtlsRole === 'client' ? 'server' : 'client');

		// Need to tell the remote transport about our parameters.
		await this.safeEmitAsPromise('@connect', { dtlsParameters });

		this._transportReady = true;
	}

	private _assertSendDirection(): void
	{
		if (this._direction !== 'send')
		{
			throw new Error(
				'method can just be called for handlers with "send" direction');
		}
	}

	private _assertRecvDirection(): void
	{
		if (this._direction !== 'recv')
		{
			throw new Error(
				'method can just be called for handlers with "recv" direction');
		}
	}
}
