import { Route } from '@tanstack/router';
import { HomePage } from './home.page';
import { homeSearchSchema } from './home.schema';
import { appRoute } from 'app/app.route';

export const homeRoute = new Route({
  getParentRoute: () => appRoute,
  path: '$key',
  component: HomePage,
  validateSearch: (search) => homeSearchSchema.parse(search),
});
