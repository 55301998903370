import { EnhancedEventEmitter } from './EnhancedEventEmitter';
import { InvalidStateError } from './errors';
import { RtpParameters } from './RtpParameters';

export type ConsumerOptions =
{
	id?: string;
	recvTransportId?: string;
	producerId?: string;
	kind?: 'audio' | 'video';
	rtpParameters?: RtpParameters;
	appData?: any;
}

// eslint-disable-next-line no-shadow
export enum VoiceEnergyState  {
   start = "start", // eslint-disable-line
   stop = "stop", // eslint-disable-line
   unmute = "unmute", // eslint-disable-line
   mute = "mute" // eslint-disable-line
}

export class Consumer extends EnhancedEventEmitter
{
	// Id.
	private readonly _id: string;
	// recvtransport id
	private readonly _recvTransportId: string;
	// Local id.
	private readonly _localId: string;
	// Associated Producer id.
	private readonly _producerId: string;
	// Closed flag.
	private _closed = false;
	// Associated RTCRtpReceiver.
	private readonly _rtpReceiver?: RTCRtpReceiver;
	// Remote track.
	private readonly _track: MediaStreamTrack;
	// RTP parameters.
	private readonly _rtpParameters: RtpParameters;
	// Paused flag.
	private _paused: boolean;
	// App custom data.
	private readonly _appData: any;
	// Observer instance.
	protected readonly _observer = new EnhancedEventEmitter();
	// 远端是否暂停了
	private _remotePaused: boolean;
	// 当前语音激励状态
	private _voiceEnergyState: VoiceEnergyState;
	// 当前接收的frames数量
	private _framesLastReceived: number;
	// 多少秒收不到frames(音频对应是packets)
	private _noFrameRecvInterval: number;
	// 第一帧是否收到
	private _firstFrameArrived: boolean;
	//当前接收的音频packets数量
	private _packetsLastReceived:number;
	//当前丢包的音频packets数量
	private _bytesLastReceived:number;
	//多少秒收不到packets
	private _noPacketRecvInterval: number;
	//第一个packet是否收到
	private _firstPacketArrived: boolean;

	/**
	 * @emits transportclose
	 * @emits trackended
	 * @emits @getstats
	 * @emits @close
	 */
	constructor(
		{
			id,
			recvTransportId,
			localId,
			producerId,
			rtpReceiver,
			track,
			rtpParameters,
			appData
		}:
		{
			id: string;
			recvTransportId: string;
			localId: string;
			producerId: string;
			rtpReceiver?: RTCRtpReceiver;
			track: MediaStreamTrack;
			rtpParameters: RtpParameters;
			appData: any;
		}
	)
	{
		super();

		console.log('constructor()');

		this._id = id;
		this._recvTransportId = recvTransportId;
		this._localId = localId;
		this._producerId = producerId;
		this._rtpReceiver = rtpReceiver;
		this._track = track;
		this._rtpParameters = rtpParameters;
		this._paused = !track.enabled;
		this._appData = appData;
		this._remotePaused = true; 
		this._voiceEnergyState = VoiceEnergyState.stop;
		this._framesLastReceived = 0;
		this._noFrameRecvInterval = 0;
		this._firstFrameArrived = false;
		this._packetsLastReceived = 0;
		this._bytesLastReceived = 0;
		this._noPacketRecvInterval = 0;
		this._firstPacketArrived = false;
		this._onTrackEnded = this._onTrackEnded.bind(this);

		this._handleTrack();
	}

	/**
	 * Consumer id.
	 */
	get id(): string
	{
		return this._id;
	}

	/**
	 * RecvTransport id
	 */
	get recvTransportId():string
	{
		return this._recvTransportId;
	}

	/**
	 * Local id.
	 */
	get localId(): string
	{
		return this._localId;
	}

	/**
	 * Associated Producer id.
	 */
	get producerId(): string
	{
		return this._producerId;
	}

	/**
	 * Whether the Consumer is closed.
	 */
	get closed(): boolean
	{
		return this._closed;
	}

	/**
	 * Media kind.
	 */
	get kind(): string
	{
		return this._track.kind;
	}

	/**
	 * Associated RTCRtpReceiver.
	 */
	get rtpReceiver(): RTCRtpReceiver | undefined
	{
		return this._rtpReceiver;
	}

	/**
	 * The associated track.
	 */
	get track(): MediaStreamTrack
	{
		return this._track;
	}

	/**
	 * RTP parameters.
	 */
	get rtpParameters(): RtpParameters
	{
		return this._rtpParameters;
	}

	/**
	 * Whether the Consumer is paused.
	 */
	get paused(): boolean
	{
		return this._paused;
	}

	/**
	 * App custom data.
	 */
	get appData(): any
	{
		return this._appData;
	}

	/**
	 * Invalid setter.
	 */
	set appData(appData) // eslint-disable-line no-unused-vars
	{
		console.log(appData);
		throw new Error('cannot override appData object');
	}

	/**
	 * Observer.
	 *
	 * @emits close
	 * @emits pause
	 * @emits resume
	 * @emits trackended
	 */
	 get observer(): EnhancedEventEmitter
	 {
		 return this._observer;
	 }
 

	get remotePaused(): boolean
	{
		return this._remotePaused;
	}

	SetRemotePaused(paused:boolean){
		console.log(`[Consumer] SetRemotePaused=${paused}`)
       this._remotePaused = paused
	}

	get voiceEnergyState(): VoiceEnergyState
	{
		return this._voiceEnergyState;
	}

	SetVoiceEnergyState(state:VoiceEnergyState){
       this._voiceEnergyState = state
	}

	get framesLastReceived(): number {
		return this._framesLastReceived;
	}

	SetFramesLastReceived(framesNum:number){
		this._framesLastReceived = framesNum;
	}

	get noFrameRecvInterval(): number {
		return this._noFrameRecvInterval;
	}

	SetNoFrameRecvInterval(interval:number) {
		this._noFrameRecvInterval = interval;
	}

	get firstFrameArrived(): boolean {
		return this._firstFrameArrived;
	}

	SetFirstFrameArrived(isArrived:boolean) {
		this._firstFrameArrived = isArrived;
	}

	//audio packet
	get packetsLastReceived(): number {
		return this._packetsLastReceived;
	}

	SetPacketsLastReceived(framesNum:number){
		this._packetsLastReceived = framesNum;
	}

	get bytesLastReceived(): number {
		return this._bytesLastReceived;
	}

	SetBytesLastReceived(bytesNum:number){
		this._bytesLastReceived = bytesNum;
	}

	get noPacketRecvInterval(): number {
		return this._noPacketRecvInterval;
	}

	SetNoPacketRecvInterval(interval:number) {
		this._noPacketRecvInterval = interval;
	}

	get firstPacketArrived(): boolean {
		return this._firstPacketArrived;
	}

	SetFirstPacketArrived(isArrived:boolean) {
		this._firstPacketArrived = isArrived;
	}

	

	/**
	 * Closes the Consumer.
	 */
	close(): void
	{
		if (this._closed)
			return;

		console.log('close()');

		this._closed = true;

		this._destroyTrack();

		this.emit('@close');

		// Emit observer event.
		this._observer.safeEmit('close');
	}

	/**
	 * Transport was closed.
	 */
	transportClosed(): void
	{
		if (this._closed)
			return;

		console.log('transportClosed()');

		this._closed = true;

		this._destroyTrack();

		this.safeEmit('transportclose');

		// Emit observer event.
		this._observer.safeEmit('close');
	}

	/**
	 * Get associated RTCRtpReceiver stats.
	 */
	async getStats(): Promise<RTCStatsReport> // eslint-disable-line
	{
		if (this._closed)
			throw new InvalidStateError('closed');

		return this.safeEmitAsPromise('@getstats');
	}

	/**
	 * Pauses receiving media.
	 */
	pause(): void
	{
		console.log('pause()');

		if (this._closed)
		{
			console.log('pause() | Consumer closed');

			return;
		}

		this._paused = true;
		this._track.enabled = false;

		// Emit observer event.
		this._observer.safeEmit('pause');
	}

	/**
	 * Resumes receiving media.
	 */
	resume(): void
	{
		console.log('resume()');

		if (this._closed)
		{
			console.log('resume() | Consumer closed');

			return;
		}

		this._paused = false;
		this._track.enabled = true;

		// Emit observer event.
		this._observer.safeEmit('resume');
	}

	private _onTrackEnded(): void
	{
		console.log('track "ended" event');

		this.safeEmit('trackended');

		// Emit observer event.
		this._observer.safeEmit('trackended');
	}

	private _handleTrack(): void
	{
		this._track.addEventListener('ended', this._onTrackEnded);
	}

	private _destroyTrack(): void
	{
		try
		{
			this._track.removeEventListener('ended', this._onTrackEnded);
			this._track.stop();
		}
		catch (error)
		{}
	}
}
