const ScalabilityModeRegex = new RegExp('^[LS]([1-9]\\d{0,1})T([1-9]\\d{0,1})');

export type ScalabilityMode_ =
{
	spatialLayers: number;
	temporalLayers: number;
}

export function parse(scalabilityMode: string): ScalabilityMode_
{
	const match = ScalabilityModeRegex.exec(scalabilityMode);

	if (match)
	{
		return {
			spatialLayers  : Number(match[1]),
			temporalLayers : Number(match[2])
		};
	}
	return {
		spatialLayers  : 1,
		temporalLayers : 1
	};
}
