import { WebSocketTransport, Peer } from "./core/protoo-client";
import * as mediasoupClient from "./core";
import { Device, BuiltinHandlerName } from "./core/Device";
import { Transport } from "./core/Transport";
import * as cookiesManager from "./cookiesManager";
import { Producer } from "./core/Producer";
import { DataProducer } from "./core/DataProducer";
import { Consumer } from './core/Consumer';
import { DataConsumer } from "./core/DataConsumer";
import { EnhancedEventEmitter } from "./core/EnhancedEventEmitter";
import deviceInfo from "./deviceInfo";
import * as retry from "retry";
import {
  QRTCParams,
  QRTCVideoResolution,
  ResolutionInfo,
  QRTCVideoResolutionMode,
  QRTCVideoStreamType,
  QRTCVideoEncParams,
  QRTCMediaDeviceType,
  DeviceInfo,
  QRTCMediaDeviceInfo,
  QRTCVolumeInfo,
  WebCamInfo,
  NeedConsumeInfo,
  QRTCQualityInfo,
  QRTCQuality,
  QRTCVideoFillMode,
  QRTCMediaDeviceChangeEvent,
  QRTCError,
  QRTCErrorInfo,
  RemoteAudioStats,
	RemoteVideoStats, 
  RemoteShareStats, 
  LocalAudioStats, 
  LocalShareStats, 
  LocalVideoStats,
  QRTCAudioProfile
} from "./RoomParams";
import { getSubSet, ping } from "./core/utils";
import SoundMeter from "./SoundMeter";

const PC_PROPRIETARY_CONSTRAINTS = {
  optional: [{ googDscp: true }],
};

export default class RoomClient extends EnhancedEventEmitter {
  // Closed flag.
  private _closed: boolean;
  // Loaded flag.
  private _device: DeviceInfo;
  // Whether we want to force RTC over TCP.
  private _forceTcp: boolean;
  //force H264
  private _forceH264: boolean;

  // Whether we want DataChannels.
  // @type {Boolean}
  private _useDataChannel: boolean;
  // Custom mediasoup-client handler name (to override default browser
  // detection if desired).
  // @type {String}
  private _handlerName: string;
  // Whether simulcast should be used.
  // @type {Boolean}
  private _useSimulcast: boolean;

  // Whether simulcast should be used in desktop sharing.
  // @type {Boolean}
  private _useSharingSimulcast: boolean;

  // Protoo URL.
  // @type {String}
  private _protooUrl: string;

  // protoo-client Peer instance.
  // @type {protooClient.Peer}
  private _protoo: any;

  // mediasoup-client Device instance.
  // @type {mediasoupClient.Device}
  private _mediasoupDevice: Device|null;

  // mediasoup Transport for sending.
  // @type {mediasoupClient.Transport}
  private _sendTransport: Transport|null;

  // Local mic mediasoup Producer.
  // @type {mediasoupClient.Producer}
  private _micProducer: Producer|null;

  // Local webcam mediasoup Producer.
  // @type {mediasoupClient.Producer}
  private _webcamProducer: Producer|null;

  // Local share mediasoup Producer.
  // @type {mediasoupClient.Producer}
  private _shareProducer: Producer|null;

  // Local chat DataProducer.
  // @type {mediasoupClient.DataProducer}
  private _chatDataProducer: DataProducer|null;

  // Local bot DataProducer.
  // @type {mediasoupClient.DataProducer}
  private _botDataProducer: DataProducer|null;

  //peerList
  private _peers: Map<string, any>;

  //need consume list 先用producerId做索引
  private _needConsumes: Map<string, NeedConsumeInfo>;

  //Multiple recvTransports may be received because the media server is distributed
  private _recvTransports: Map<string, Transport>;

  // mediasoup Consumers.
  // @type {Map<String, mediasoupClient.Consumer>}
  private _consumers: Map<string, Consumer>;

  // mediasoup DataConsumers.
  // @type {Map<String, mediasoupClient.DataConsumer>}
  private _dataConsumers: Map<string, DataConsumer>;

  // Map of webcam MediaDeviceInfos indexed by deviceId.
  // @type {Map<String, MediaDeviceInfos>}
  private _webcams: Map<string, MediaDeviceInfo>; //找不到MediaDeviceInfos的定义啊

  // Local Webcam.
  // @type {Object} with:
  // - {MediaDeviceInfo} [device]
  // - {String} [resolution] - 'qvga' / 'vga' / 'hd'.
  private _webcam: WebCamInfo = {
    device: null,
    resolution: "qvga",
  };

  private _encoderParam: QRTCVideoEncParams = {
    enableAdjustRes: true,
    maxVideoBitrate: 500,
    minVideoBitrate: 200,
    resMode: QRTCVideoResolutionMode.QRTCVideoResolutionModeLandscape,
    videoFps: 24,
    videoResolution: QRTCVideoResolution.QRTCVideoResolution_480_360, //just like zoom
  };

  private _audioProfile: QRTCAudioProfile;
  private _audioLevelQueryInterval: any;
  private _roomInfo: QRTCParams|null;
  private _audioVolumn: number;
  private _audioPlayoutVolumn: number;

  private _audioStartWithMute: boolean;
  private _audioLocalMute: boolean;

  private _remoteVideoDoms: Map<string, HTMLVideoElement>;

  private _camDeviceList: QRTCMediaDeviceInfo[] = [];
  private _micDeviceList: QRTCMediaDeviceInfo[] = [];
  private _speakerDeviceList: QRTCMediaDeviceInfo[] = [];
  private _voiceTimeout: any;

  private _audioLevelMap: Map<string, number> = new Map();
  private _averageRemoteVideoStats: RemoteVideoStats = {
    framesPerSecond: 0,
    jitter: 0,
    jitterBufferDelay: 0,
    packetBitrate: 0,
    packetsLostRatio: 0
  };
  private _averageRemoteAudioStats: RemoteAudioStats = {
    jitter: 0,
    jitterBufferDelay: 0,
    packetBitrate: 0,
    packetsLostRatio: 0
  };
  private _averageRemoteShareStats: RemoteShareStats = {
    framesPerSecond: 0,
    jitter: 0,
    jitterBufferDelay: 0,
    packetBitrate: 0,
    packetsLostRatio: 0
  };
  private _localVideoStats: LocalVideoStats = {
    framesPerSecond: 0,
    jitter: 0,
    jitterBufferDelay: 0,
    packetBitrate: 0,
    packetsLostRatio: 0
  };
  private _localAudioStats: LocalAudioStats = {
    jitter: 0,
    jitterBufferDelay: 0,
    packetBitrate: 0,
    packetsLostRatio: 0
  };
  private _localShareStats: LocalShareStats = {
    framesPerSecond: 0,
    jitter: 0,
    jitterBufferDelay: 0,
    packetBitrate: 0,
    packetsLostRatio: 0
  };
  constructor() {
    super();

    // Closed flag.
    // @type {Boolean}
    this._closed = false;

    // Device info.
    // @type {Object}
    this._device = deviceInfo();

    // Whether we want to force RTC over TCP.
    // @type {Boolean}
    this._forceTcp = false;

    // Force H264 codec for sending.
    this._forceH264 = true;

    // Whether we want DataChannels.
    // @type {Boolean}
    this._useDataChannel = false;

    // Custom mediasoup-client handler name (to override default browser
    // detection if desired).
    // @type {String}
    this._handlerName = "";

    // Whether simulcast should be used.
    // @type {Boolean}
    this._useSimulcast = false;

    // Whether simulcast should be used in desktop sharing.
    // @type {Boolean}
    this._useSharingSimulcast = false;

    // Protoo URL.
    // @type {String}
    this._protooUrl = ``;

    // protoo-client Peer instance.
    // @type {protooClient.Peer}
    this._protoo = null;

    // mediasoup-client Device instance.
    // @type {mediasoupClient.Device}
    this._mediasoupDevice = null;

    // mediasoup Transport for sending.
    // @type {mediasoupClient.Transport}
    this._sendTransport = null;

    // Local mic mediasoup Producer.
    // @type {mediasoupClient.Producer}
    this._micProducer = null;

    // Local webcam mediasoup Producer.
    // @type {mediasoupClient.Producer}
    this._webcamProducer = null;

    // Local share mediasoup Producer.
    // @type {mediasoupClient.Producer}
    this._shareProducer = null;

    // Local chat DataProducer.
    // @type {mediasoupClient.DataProducer}
    this._chatDataProducer = null;

    // Local bot DataProducer.
    // @type {mediasoupClient.DataProducer}
    this._botDataProducer = null;

    //peer list
    this._peers = new Map();

    //recv transport list
    this._recvTransports = new Map();

    //need consume list
    this._needConsumes = new Map();

    // mediasoup Consumers.
    // @type {Map<String, mediasoupClient.Consumer>}
    this._consumers = new Map();

    // mediasoup DataConsumers.
    // @type {Map<String, mediasoupClient.DataConsumer>}
    this._dataConsumers = new Map();

    // Map of webcam MediaDeviceInfos indexed by deviceId.
    // @type {Map<String, MediaDeviceInfos>}
    this._webcams = new Map();

    this._roomInfo = null;

    this._audioVolumn = 0;
    this._audioPlayoutVolumn = 0;
    //this._localQRTCView = null
    this._remoteVideoDoms = new Map();
    this._audioStartWithMute = false;
    this._audioLocalMute = false;
    this._voiceTimeout = null;
    this._audioProfile = QRTCAudioProfile.AUDIO_PROFILE_STANDARD;
    this._audioLevelMap = new Map();
  }

  async close() {
    if (this._closed) return;

    this.cleanStatus();
    this._closed = true;

    await this.safeRequest("leave");

    this._protoo.close();
  }

  async exitRoom(silence: boolean = false) {
    if (this._closed) return;

    this.cleanStatus();
    this._closed = true;

    if (!silence) {
      await this.safeRequest("leave");
    }
    this._protoo.close();
  }

  async cleanStatus() { // eslint-disable-line
    this._closed = false;
    this._device = deviceInfo();
    this._forceTcp = false;
    this._forceH264 = true;
    this._useSimulcast = false;
    this._useSharingSimulcast = false;
    this._mediasoupDevice = null;
    this._micProducer = null;
    this._webcamProducer = null;
    this._shareProducer = null;
    this._peers = new Map();
    this._audioLevelMap = new Map();
    this._needConsumes = new Map();
    this._consumers = new Map();
    this._webcams = new Map();
    this._audioVolumn = 0;
    this._audioPlayoutVolumn = 0;
    this._remoteVideoDoms = new Map();
    if (this._audioLevelQueryInterval) {
      clearInterval(this._audioLevelQueryInterval);
      this._audioLevelQueryInterval = null;
    }

    if (this._voiceTimeout) {
      clearTimeout(this._voiceTimeout);
      this._voiceTimeout = null;
    }
    // Close mediasoup Transports.
    if (this._sendTransport) {
      this._sendTransport.close();
      this._sendTransport = null;
    }
    for (const recvTransport of this._recvTransports.values()) {
      recvTransport.close();
    }
    this._recvTransports = new Map();
  }

  //safe request to server
  async safeRequest(method: string, data: any = undefined) {
    try {
      await this._protoo.request(method, data);
    } catch (error) {
      console.log(`request failed due to: ${error}`); 
    }
  }

  //###########################################SDK interface begin###########################################
  //Set local video properties, which need to be set before opening the camera
  setVideoEncoderParam(encoderParam: QRTCVideoEncParams) {
    this._encoderParam = encoderParam;
  }

  //Set the properties of local audio, which need to be set before opening the audio. The default is standard.
  // Audio Profile	Sampling Rate	Channel	Bitrate(kbps)
  // standard	48000	Mono	40
  // high	48000	Mono	128
  setAudioProfile(profile: QRTCAudioProfile) {
    this._audioProfile = profile;
  }

  //enter room
  async enterRoom(roomInfo: QRTCParams) { // eslint-disable-line
    // error -3316
    if (
      roomInfo.roomId === null ||
      roomInfo.userId === null ||
      roomInfo.userId === "" ||
      roomInfo.serverUrl === null
    ) {
      this.safeEmit("error", { code: -3316 });
      return;
    }
    if (roomInfo.maxRetryTime == null) {
      roomInfo.maxRetryTime = 900 * 1000;
    }

    if (roomInfo.userId.length < 4) {
      this.safeEmit("error", { code: -3319 });
      return;
    }

    if (roomInfo.roomId.length < 4) {
      this.safeEmit("error", { code: -3318 });
      return;
    }
    roomInfo.xConferenceToken = roomInfo.xConferenceToken
      ? roomInfo.xConferenceToken
      : "1234567890";
    roomInfo.avatarUrl = roomInfo.avatarUrl ? roomInfo.avatarUrl : "";
    roomInfo.acceptLanguage = roomInfo.acceptLanguage
      ? roomInfo.acceptLanguage
      : "zh-CN";
    //roomInfo.serverUrl = 'live.100tt.com.cn/sigle-server'
    this._roomInfo = roomInfo;
    this._protooUrl = `${roomInfo.serverUrl}?roomId=${roomInfo.roomId}&peerId=${roomInfo.userId}&forceH264=false&forceVP9=false`;
    // this.reportLog(`[RoomClient] enterRoom url=${this._protooUrl}`);
    const protooTransport = new WebSocketTransport(
      this._protooUrl,
      {
        clientConfig: { keepalive: true, keepaliveInterval: 30 * 1000 },
        maxRetryTime: roomInfo.maxRetryTime,
      }
    );
    this._protoo = new Peer(protooTransport);
    //Enable device change monitoring
    this.enableDevicesMonitor();

    this._protoo.on("open", async () => {
      this.reportLog(`[RoomClient] websocket connected!`);
      this.cleanStatus();
      try {
        await this._enterRoom();
      } catch (error) {
        throw (error);
      }	 
    });
    this._protoo.on("failed", () => {
      this.safeEmit("error", "WebSocket connection failed");
    });

    this._protoo.on("error", () => {
      //this.safeEmit('error', { code: -3308 })
    });

    this._protoo.on("disconnected", () => {
      this.safeEmit("connectionLost", "WebSocket disconnected");
      // Close mediasoup Transports.
      //this.cleanStatus();

      this.safeEmit("connectionLost", "room closed");
    });

    this._protoo.on("close", () => {
      this.reportLog(`[RoomClient] protoo on close!`);
      if (this._closed){ 
        this.safeEmit("exitRoom",{ "reason": 4 });
        return;
      }
      this.close();
      this.safeEmit("connectionLost");
    });

    this._protoo.on("connectionTimeOut", () => {
      this.reportLog(`[RoomClient] protoo connectionTimeOut!`);
      this.safeEmit("connectionTimeOut");
    });

    // eslint-disable-next-line no-unused-vars
    this._protoo.on(
      "request",
      async (request: any, accept: any, reject: any) => { // eslint-disable-line
        // eslint-disable-next-line default-case
        switch (request.method) {
          case "heartBeat": {
            this.reportLog(`[RoomClient] heartBeat`);
            accept();
            break;
          }
        }
      }
    );

    this._protoo.on("notification", (notification: any) => { // eslint-disable-line
      // this.reportLog(
      //   `[RoomClient] proto "notification" event [method:${notification.method}, data:${notification.data}]`
      // );

      switch (notification.method) {
        case "producerCreated": {
          // this.reportLog(
          //   `[TraceVideo] [RoomClient] producerCreated:${JSON.stringify(
          //     notification.data
          //   )}`
          // );
          const { peerId, producerId, kind, paused, appData } =
            notification.data;
          //should store the producer info
          this._needConsumes.set(producerId, {
            appData,
            kind,
            peerId,
            producerId,
          });
          if (!paused) {
            if (appData.share !== undefined) {
              this.safeEmit("userShareAvailable", {
                available: true,
                userId: peerId
              });
            } else if( kind === "audio" ){
              this._audioLevelMap.set(peerId,0);
            }
            this.safeEmit(
              kind === "video" ? "userVideoAvailable" : "userAudioAvailable",
              {
                available: true,
                userId: peerId
              }
            );
          }
          break;
        }
        case "newPeerJoined": {
          // this.reportLog(
          //   `[RoomClient] newPeerJoined:${JSON.stringify(notification.data)}`
          // );
          const { peerId, displayName, device, timeMs, avatarUrl } =
            notification.data;
          this._peers.set(peerId, notification.data);

          //There is a situation where a user's browser is refreshed, their peerId does not change, and they re-enter the room, 
          //but it is stored on my end. Or, if a user's browser is refreshed, their peerId does not change, and they re-enter the room, 
          //but their previous consumption information is still stored on my end. At this point, the previous consumption information 
          //corresponding to the same peerId needs to be cleared first, so that the correct subscription can be re-established
          for (const needConsumerValue of this._needConsumes.values()) {
            if (peerId === needConsumerValue.peerId) {
              this._needConsumes.delete(needConsumerValue.producerId);
            }
          }
          for (const consumerValue of this._consumers.values()) {
            if (peerId === consumerValue.appData.peerId) {
              this._consumers.delete(consumerValue.id);
            }
          }

          this.safeEmit("remoteUserEnterRoom", {
            avatarUrl,
            timeMs,
            userDevice: device,
            userId: peerId,
            userName: displayName,
          });
          break;
        }
        case "peerLeft": {
          // this.reportLog(
          //   `[RoomClient] peerLeft:${JSON.stringify(notification.data)}`
          // );
          const { peerId } = notification.data;
          this._peers.delete(peerId);
          //There is a situation where a remote user suddenly loses their connection and then reconnects, and the local end may not receive the consumerClosed message. 
          //At this point, the previous consumption information corresponding to the same peerId needs to be cleared first, so that the correct subscription can be re-established
          for (const needConsumerValue of this._needConsumes.values()) {
            if (peerId === needConsumerValue.peerId) {
              this._needConsumes.delete(needConsumerValue.producerId);
            }
          }
          for (const consumerValue of this._consumers.values()) {
            if (peerId === consumerValue.appData.peerId) {
              this._consumers.delete(consumerValue.id);
            }
          }

          this.safeEmit("remoteUserLeaveRoom", {
            userId: peerId,
          });
          break;
        }
        case "producerScore": {
          const { score } = notification.data;
          let quality: QRTCQuality = QRTCQuality.QRTCQuality_Good;
          if (score === 0) {
            quality = QRTCQuality.QRTCQuality_Down;
          } else if (score > 0 && score <= 2) {
            quality = QRTCQuality.QRTCQuality_Vbad;
          } else if (score > 2 && score <= 4) {
            quality = QRTCQuality.QRTCQuality_Bad;
          } else if (score > 4 && score <= 6) {
            quality = QRTCQuality.QRTCQuality_Poor;
          } else if (score > 6 && score <= 9) {
            quality = QRTCQuality.QRTCQuality_Good;
          } else if (score === 10) {
            quality = QRTCQuality.QRTCQuality_Excellent;
          }
          if(!this._roomInfo){
            return;
          }
          const qualityInfo: QRTCQualityInfo = {
            quality: quality,
            userId: this._roomInfo.userId,
          };
          this.safeEmit("networkQuality", qualityInfo);
          //const { producerId, score } = notification.data;

          break;
        }
        case "displayNameChanged": {
          const { peerId, displayName, oldDisplayName } = notification.data;
          this.safeEmit("displayNameChanged", {
            displayName,
            oldDisplayName,
            peerId,
          });
          break;
        }

        case "consumerClosed": {
          //Represents that a certain audio or video stream has been closed
          const { peerId, producerId, consumerId } = notification.data;
          //No need to consume it anymore
          this._needConsumes.delete(producerId);
          //cosumerClosed:{"consumerId":"7f83e645-4f87-49eb-bfe0-4ce35160e62b"}
          // this.reportLog(
          //   `[RoomClient] consumerClosed: ${JSON.stringify(notification.data)}`
          // );
          const consumer = this._consumers.get(consumerId);
          if (!consumer) break;
          consumer.close();
          this._consumers.delete(consumerId);
          this._remoteVideoDoms.delete(peerId);

          //TODO
          if (consumer.appData.share !== undefined) {
            this.reportLog(`[RoomClient] userShareAvailable false`);
            this.safeEmit("userShareAvailable", {
              available: false, //unavailable
              paused: false,
              userId: peerId,
            });
          } else {
            this.safeEmit(
              consumer.kind === "video"
                ? "userVideoAvailable"
                : "userAudioAvailable",
              {
                available: false, //unavailable
                userId: peerId,
              }
            );
          }
          break;
        }

        case "consumerPaused": {
          const { consumerId, peerId, appData, kind, producerId } =
            notification.data;
          // console.log(
          //   `[RoomClient bug] consumerPaused with data=${JSON.stringify(
          //     notification.data
          //   )}`
          // );
          const consumer = this._consumers.get(consumerId);

          if (!consumer) {
            if (kind === "audio") {
              //this.reportLog(`[RoomClient AudioTrace] consumerPaused not found consumer for user:${peerId} consumerId:${consumerId}`, true)
            } else {
              if (consumer !== undefined) {
                if ("pause" in consumer) {
                  (consumer as any).pause(); // type guard to ensure consumer is not undefined
                }
              }
            }
            //this.reportLog(`[RoomClient] consumerPaused not found consumer`);
          }

          if (appData !== undefined && appData.share !== undefined) {
            this.safeEmit("userShareAvailable", {
              available: true,
              paused: true,
              userId: peerId,
              // available: false, //unavailable
              //appData,
              //producerId,
              //status: 3
            });
            //consumer.SetRemotePaused(true);
          } else {
            if (kind === "audio") {
              //this.reportLog(`[RoomClient AudioTrace] consumerPaused emit userAudioAvailable for user:${peerId}`, true)
            }
            this.safeEmit(
              kind === "video" ? "userVideoAvailable" : "userAudioAvailable",
              {
                appData,
                available: false, //unavailable
                producerId,
                userId: peerId,
              }
            );
          }
          break;
        }

        case "consumerResumed": {
          // this.reportLog(
          //   `[RoomClient] consumerResumed with notification data=${JSON.stringify(
          //     notification.data
          //   )}`
          // );
          const { peerId, consumerId, appData, kind, producerId } =
            notification.data;
          this._needConsumes.set(producerId, {
            appData,
            kind,
            peerId,
            producerId,
          });

          const consumer = this._consumers.get(consumerId);

          //If not found, it means that this stream has not been consumed yet, so active consumption needs to be triggered
          if (!consumer) {
            if (kind === "audio") {
              //this.reportLog(`[RoomClient AudioTrace] consumerResumed have not consume this stream for user:${peerId}`, true)
            }
            //this.reportLog(`[RoomClient AudioTrace] consumerResumed have not consume this stream for user:${peerId}`)
          } else {
            consumer.resume();
            if (kind === "audio") {
            }
          }

          if (appData !== undefined && appData.share !== undefined) {
            this.safeEmit("userShareAvailable", {
              available: true,
              userId: peerId,
              //appData,
              //producerId,
              //status: 4
            });
          } else {
            if (kind === "audio") {
              // this.reportLog(
              //   `[RoomClient AudioTrace] emit userAudioAvailable for user:${peerId}`
              // );
            }
            this.safeEmit(
              kind === "video" ? "userVideoAvailable" : "userAudioAvailable",
              {
                available: true,
                userId: peerId,
                //appData,
                //producerId
              }
            );
          }

          //If found, it means that this stream has been consumed before, and the corresponding consumer needs to be resumed
          //consumer.resume();
          break;
        }

        case "consumerLayersChanged": {
          // const { consumerId, spatialLayer, temporalLayer } = notification.data;
          // const consumer = this._consumers.get(consumerId);

          // if (!consumer)
          // 	break;

          break;
        }

        case "consumerScore": {
          // const { consumerId, score } = notification.data;
          break;
        }

        case "activeSpeaker": {
          // const { peerId } = notification.data;
          break;
        }
        // case 'voiceEnergyNotify':
        //   {
        //     const {voiceEnergy} = notification.data;
        //     console.log(`[RoomClient] voiceEnergyNotify userSpeaking voiceEnergy=${JSON.stringify(voiceEnergy)}`)

        //     let action = ''
        //     let peerId = ''
        //     let maxVoice = 0

        //     for(const voiceEnergyValue of voiceEnergy) {
        //       action = voiceEnergyValue.action
        //       peerId = voiceEnergyValue.peerID
        //       //Set the status of the corresponding consumer
        //       for (const consumer of this._consumers.values()){
        //         if(consumer.appData.peerId == peerId && consumer.kind == 'audio'){
        //           const state: VoiceEnergyState = <VoiceEnergyState>action;
        //           consumer.SetVoiceEnergyState(state);
        //           //console.log(`[RoomClient] voiceEnergyNotify SetVoiceEnergyState=${state}`)
        //           break;
        //         }
        //       }

        //       if ((action == "start" || action == "unmute") && voiceEnergyValue.voice > maxVoice) {
        //         maxVoice = voiceEnergyValue.voice
        //       }

        //       if (action == "stop" || action == "mute") {
        //         break
        //       }
        //     }

        //     console.log(`[RoomClient] voiceEnergyNotify userSpeaking userId=${peerId} action=${action} maxVoice=${maxVoice}`)
        //     this.safeEmit('userSpeaking',{
        //       action: action,
        //       userId: peerId
        //     })

        //     break;
        //   }
        case "rtcControlNotify": {
          this.safeEmit("customCommand", notification.data);
          break;
        }

        case "peerKicked": {
          //The user has been kicked out of the room
          const { peerId } = notification.data;
          if(!this._roomInfo){
            return;
          }
          const isMe = this._roomInfo.userId === peerId;

          if (isMe) {
            this.safeEmit("exitRoom", {
              reason: 1, //1：Kicked out of the current room by the server; 2: The host actively ends the current meeting EndConference; 3. The server forcibly ends the current meeting forceEndConference
            });
          } else {
            this.safeEmit("remoteUserLeaveRoom", {
              reason: 2, //0 Represents that the user actively exits the room, 1 represents that the user times out and exits, and 2 represents that the user is kicked out of the room
              userId: peerId,
            });
          }

          break;
        }
        case "roomDestroyed": {
          const { reason } = notification!["data"];
          console.log(`[RoomClient] roomDestroyed reason=${reason}`)
          const isForce = reason === "forceEndConference";
          this.safeEmit("exitRoom", {
            reason: isForce ? 3 : 2, //1: Kicked out of the current room by the server; 2: The host actively ends the current meeting EndConference; 3. The server forcibly ends the current meeting forceEndConference.
          });
          break;
        }
        case "customCmdMsg": {
          //Whiteboard custom protocol
          const { peerId, cmdId, seq, data } = notification.data;
          if(cmdId == 999){
            this.safeEmit("recvRoomTextMsg", {
              message: data,
              peerId,
            });

          }else if(cmdId > 1000 && cmdId<= 1100){
            //default for custom text
            this.safeEmit("recvRoomCustomMsg", {
              cmd: cmdId-1000,
              message: data,
              peerId,
            });
          }else{
            this.safeEmit("recvCustomCmdMsg", {
              cmdId,
              data,
              peerId,
              seq,
            });
          }

          break;
        }

        default: {
          // this.reportLog(
          //   `unknown protoo notification.method ${notification.method}`
          // );
        }
      }
    });
  }

  //Start local video
  async startLocalPreview(qrtcView?: HTMLVideoElement) {
    //First check if there is a sendTransport, if not, create one
    await this._createSendTransport();
    //Then turn on the camera
    const devicesCookie = cookiesManager.getDevices();
    if (!devicesCookie || devicesCookie.webcamEnabled) {
      await this.enableWebcam(qrtcView);
    }
  }

  //shutdown local video
  async stopLocalPreview() { // eslint-disable-line
    this.disableWebcam();
  }

  //pause local video
  async muteLocalVideo(mute: boolean) {
    if (!this._webcamProducer) {
      return;
    }
    if(!this._sendTransport){
      return;
    }
    if (mute) {
      this._webcamProducer.pause();
      try {
        await this._protoo.request("pauseProducer", {
          producerId: this._webcamProducer.id,
          transportId: this._sendTransport.id,
          transportType: "webrtc",
        });
      } catch (error) {
        this.reportLog(`[RoomClient] muteLocalVideo failed:${error}`);
      }
    } else {
      this._webcamProducer.resume();

      try {
        await this._protoo.request("resumeProducer", {
          producerId: this._webcamProducer.id,
          transportId: this._sendTransport.id,
          transportType: "webrtc",
        });
      } catch (error) {
        this.reportLog(`[RoomClient] unMuteLocalVideo failed:${error}`);
      }
    }
  }

  //start local audio
  async startLocalAudio(mute: boolean) {
    await this._createSendTransport();
    //turn on the micphone
    this._audioStartWithMute = mute;
    this._audioLocalMute = mute;
    this.enableMic(mute);
  }

  //stop local audio
  async stopLocalAudio() { // eslint-disable-line
    this.disableMic();
  }

  //Mute/unmute local audio
  async muteLocalAudio(mute: boolean) {
    if (!this._micProducer) {
      await this.startLocalAudio(mute);
      return;
    }
    if(!this._sendTransport){
      return;
    }
    if (mute) {
      this._micProducer.pause();
      try {
        await this._protoo.request("pauseProducer", {
          producerId: this._micProducer.id,
          transportId: this._sendTransport.id,
          transportType: "webrtc",
        });
      } catch (error) {
        this.reportLog(`muteMic() | failed: ${error}`);
      }
    } else {
      this._micProducer.resume();

      try {
        await this._protoo.request("resumeProducer", {
          producerId: this._micProducer.id,
          transportId: this._sendTransport.id,
          transportType: "webrtc",
        });
      } catch (error) {
        this.reportLog(`unmuteMic() | failed: ${error}`);
      }
    }
    this._audioLocalMute = mute;
  }

  //Actively pull the video of the remote peer
  async startRemoteVideo(userId: string, qrtcView?: HTMLVideoElement) {
    //There are two situations. One is that the video has been consumed before and is only paused, so sending a resume to the server is sufficient
    // console.log(`[TraceVideo] [RoomClient] startRemoteVideo userId=${userId}`);
    let consumer: Consumer|null = null;
    for (const consumerValue of this._consumers.values()) {
      if (
        userId === consumerValue.appData.peerId &&
        consumerValue.kind === "video" &&
        consumerValue.appData.share === undefined
      ) {
        consumer = consumerValue;
        break;
      }
    }
    if (consumer) {
      //Sends a message to resume the video
      await this._resumeConsumer(consumer);
      if (qrtcView) {
        const stream = new MediaStream();
        stream.addTrack(consumer.track);
        this._remoteVideoDoms.set(userId, qrtcView);
        (qrtcView as any).srcObject = stream;
        (qrtcView as any)
          .play()
          .catch((error: any) =>
            this.reportLog(`videoElem.play() failed:  ${error}`)
          );
      }
      return;
    }
    //The other situation is that the video has not been consumed before, so consumption needs to be initiated
    let needConsumeInfo: NeedConsumeInfo|null = null;
    for (const needConsumerValue of this._needConsumes.values()) {
      if (
        userId === needConsumerValue.peerId &&
        needConsumerValue.kind === "video" &&
        needConsumerValue.appData.share === undefined
      ) {
        needConsumeInfo = needConsumerValue;
        // this.reportLog(
        //   `[TraceVideo] [RoomClient] startRemoteVideo needConsumeInfo=${JSON.stringify(
        //     needConsumeInfo
        //   )}`
        // );
        break;
      }
    }

    if (needConsumeInfo) {
      const transportId = await this._createRecvTransport(
        needConsumeInfo.producerId
      );
      //Sends a consumption request
      // this.reportLog(
      //   `[TraceVideo] [RoomClient] createRecvTransport transportId=${JSON.stringify(
      //     transportId
      //   )}`
      // );

      if(!this._mediasoupDevice){
        return
      }

      // const consumeRequest = {
      //   appData: {
      //     peerId: userId,
      //   },
      //   producerId: needConsumeInfo.producerId,
      //   rtpCapabilities: this._mediasoupDevice.rtpCapabilities,
      //   transportId: transportId,
      //   transportType: "webrtc",
      // };
      // console.log(
      //   `[RoomClient] consumeRequest=${JSON.stringify(consumeRequest)}`
      // );
      const consumerInfo = await this._protoo.request("consume", {
        appData: {
          peerId: userId,
        },
        producerId: needConsumeInfo.producerId,
        rtpCapabilities: this._mediasoupDevice.rtpCapabilities,
        transportId: transportId,
        transportType: "webrtc",
      });
      // this.reportLog(
      //   `[TraceVideo] [RoomClient] consume result=${JSON.stringify(
      //     consumerInfo
      //   )}`
      // );
      //begin consume
      const {
        id,
        producerId,
        kind, // eslint-disable-line
        //producerPaused, 
        rtpParameters,
        type, // eslint-disable-line
      } = consumerInfo;
      try {
        const recvTransport = this._recvTransports.get(transportId);
        if (!recvTransport) {
          return;
        }
        // eslint-disable-next-line no-shadow
        const consumer = await recvTransport.consume({
          appData: { ...needConsumeInfo.appData, peerId: userId }, // Trick.
          id,
          kind: "video", //TODO respond's kind always audio so direct set as "video"
          producerId,
          recvTransportId: transportId,
          rtpParameters,
          //codecOptions, TODO make sure this is redundant
          
        });
        // this.reportLog(
        //   `[TraceVideo] RoomClient] startRemoteVideo consumer created with transportId=${consumer.recvTransportId}`
        // );

        // Store in the map.
        this._consumers.set(consumer.id, consumer);

        consumer.on("transportclose", () => {
          this._consumers.delete(consumer.id);
          // this.reportLog(
          //   `[TraceVideo] transportClosed with consumer id=${consumer.id}`
          // );
        });

        //If the server pauses the keyframe, a resume needs to be sent
        //if (producerPaused) {
        await this._resumeConsumer(consumer);
        //}

        if (qrtcView) {
          const stream = new MediaStream();
          stream.addTrack(consumer.track);
          this._remoteVideoDoms.set(userId, qrtcView);
          (qrtcView as any).srcObject = stream;
          (qrtcView as any)
            .play()
            .catch((error: any) =>
              this.reportLog(`videoElem.play() failed: ${error}`)
            );
        }
      } catch (error) {
        this.reportLog(`"newConsumer" request failed: ${error}`);
        throw error;
      }
    }
  }

  //Stop pulling the video of the remote peer
  async stopRemoteVideo(userId: string) { // eslint-disable-line
    let consumer: Consumer|null = null;
    for (const consumerValue of this._consumers.values()) {
      if (
        userId === consumerValue.appData.peerId &&
        consumerValue.kind === "video" &&
        consumerValue.appData.share === undefined
      ) {
        consumer = consumerValue;
        // this.reportLog(
        //   `[RoomClient] stopRemoteVideo consumeInfo=${JSON.stringify(consumer)}`
        // );
        break;
      }
    }
    if (consumer) {
      consumer.close();
      this._consumers.delete(consumer.id);
    }
  }

  async stopRemoteAudio(userId: string) { // eslint-disable-line
    let consumer: Consumer|null = null;
    for (const consumerValue of this._consumers.values()) {
      if (userId === consumerValue.appData.peerId && consumerValue.kind === "audio") {
        consumer = consumerValue;
        // this.reportLog(
        //   `[RoomClient] stopRemoteAudio consumeInfo=${JSON.stringify(consumer)}`
        // );
        break;
      }
    }
    if (consumer) {
      consumer.close();
      this._consumers.delete(consumer.id);
    }
  }

  // Stop all remote videos
  async stopAllRemoteViews() {
    for (const consumerInfo of this._consumers.values()) {
      await this._protoo.request("closeConsumer", {
        appData: {},
        consumerId: consumerInfo.id,
        producerId: consumerInfo.producerId,
        transportId: consumerInfo.recvTransportId,
        transportType: "webrtc",
      });
    }
  }

  //Actively pull the audio of the remote peer
  async startRemoteAudio(userId: string, qrtcAudio: HTMLAudioElement) {
    // this.reportLog(`[RoomClient] startRemoteAudio begin userId=${userId}`);
    //There are two situations. One is that the audio has been consumed before and is only paused, so sending a resume to the server is sufficient
    let consumer: Consumer|null = null;
    for (const consumerValue of this._consumers.values()) {
      if (
        userId === consumerValue.appData.peerId &&
        consumerValue.kind === "audio"
      ) {
        consumer = consumerValue;
        break;
      }
    }
    if (consumer) {
      //Sends a message to resume the audio
      await this._resumeConsumer(consumer);
      const stream = new MediaStream();
      stream.addTrack(consumer.track);
      (qrtcAudio as any).srcObject = stream;
      (qrtcAudio as any)
        .play()
        .catch((error: any) =>
          this.reportLog(`audioElem.play() failed: ${error}`)
        );
      // this.reportLog(
      //   `[RoomClient] startRemoteAudio consumer already existed userId=${userId}`
      // );
      return;
    }

    //The other situation is that the video has not been consumed before, so consumption needs to be initiated
    let needConsumeInfo: NeedConsumeInfo|null = null;
    for (const needConsumerValue of this._needConsumes.values()) {
      if (
        userId === needConsumerValue.peerId &&
        needConsumerValue.kind === "audio"
      ) {
        needConsumeInfo = needConsumerValue;
        // this.reportLog(
        //   `[RoomClient] startRemoteAudio userId=${userId} needConsumeInfo=${JSON.stringify(
        //     needConsumeInfo
        //   )}`
        // );
        break;
      }
    }
    if (needConsumeInfo) {
      const transportId = await this._createRecvTransport(
        needConsumeInfo.producerId
      );
      //Sends a consumption request
      // this.reportLog(
      //   `[RoomClient] createRecvTransport userId=${userId} transportId=${JSON.stringify(
      //     transportId
      //   )}`
      // );
      if(!this._mediasoupDevice){
        return;
      }
      const consumerInfo = await this._protoo.request("consume", {
        appData: {
          peerId: userId,
          share: false,
        },
        paused: true,
        producerId: needConsumeInfo.producerId,
        rtpCapabilities: this._mediasoupDevice.rtpCapabilities,
        transportId: transportId,
        transportType: "webrtc",
      });
      // this.reportLog(
      //   `[RoomClient] startRemoteAudio userId=${userId} consume result=${JSON.stringify(
      //     consumerInfo
      //   )}`
      // );
      //begin consume
      const { id, producerId, kind, rtpParameters } =
        consumerInfo;
      try {
        const recvTransport = this._recvTransports.get(transportId);
        if (!recvTransport) {
          // this.reportLog(`[RoomClient] startRemoteAudio userId=${userId} recvTransport not found`);
          return;
        }
        // eslint-disable-next-line no-shadow
        const consumer = await recvTransport.consume({
          appData: { ...needConsumeInfo.appData, peerId: userId }, // Trick.
          id,
          kind,
          producerId,
          recvTransportId: transportId,
          rtpParameters,
          //codecOptions, TODO make sure this is redundant
        });
        // this.reportLog(
        //   `[RoomClient] startRemoteAudio userId=${userId} consumer created with transportId=${consumer.recvTransportId}`
        // );
        // Store in the map.
        this._consumers.set(consumer.id, consumer);

        consumer.on("transportclose", () => {
          // this.reportLog(
          //   `[RoomClient] startRemoteAudio userId=${userId} transportclose`
          // );
          this._consumers.delete(consumer.id);
        });

        const stream = new MediaStream();
        stream.addTrack(consumer.track);
        (qrtcAudio as any).srcObject = stream;
        (qrtcAudio as any)
          .play()
          .catch((error: any) =>
            this.reportLog(`audioElem.play() failed: ${error}`)
          );
        //If the server pauses the keyframe, a resume needs to be sent
        await this._protoo.request("resumeConsumer", {
          consumerId: consumer.id,
          producerId: needConsumeInfo.producerId,
          transportId: transportId,
          transportType: "webrtc",
        });
      } catch (error) {
        this.reportLog(`"newConsumer" request failed:${error}`);
        throw error;
      }
    }
  }

  //Pause/resume receiving the specified remote video stream
  async muteRemoteVideo(userId: string, mute: boolean) {
    //find the coresponding consumer
    let consumer: Consumer|null = null;
    for (const consumerValue of this._consumers.values()) {
      if (
        userId === consumerValue.appData.peerId &&
        consumerValue.kind === "video" &&
        consumerValue.appData.share === undefined
      ) {
        consumer = consumerValue;
        break;
      }
    }
    if (consumer) {
      if (mute) {
        await this._pauseConsumer(consumer);
      } else {
        await this._resumeConsumer(consumer);
      }
    }
  }

  //Pause/resume receiving all remote video stream
  async muteAllRemoteViews(mute: boolean) {
    await this._protoo.request(
      mute ? "unViewAll" : "viewAll",
      {}
    );
    // this.reportLog(
    //   `[RoomClient] muteAllRemoteViews result: ${JSON.stringify(muteAllResult)}`
    // );
  }

  //Pause/resume receiving all remote audio stream
  async muteAllRemoteAudios(mute: boolean) {
    await this._protoo.request(
      mute ? "muteAll" : "unMuteAll",
      {}
    );
    // this.reportLog(
    //   `[RoomClient] muteAllRemoteAudios result: ${JSON.stringify(
    //     muteAllResult
    //   )}`
    // );
  }

  //Mute/unmute the sound of the specified remote user
  async muteRemoteAudio(userId: string, mute: boolean) {
    //Find the corresponding consumer
    let consumer: Consumer|null = null;
    for (const consumerValue of this._consumers.values()) {
      if (
        userId === consumerValue.appData.peerId &&
        consumerValue.kind === "audio"
      ) {
        consumer = consumerValue;
        // this.reportLog(
        //   `[RoomClient] muteRemoteAudio consumeInfo=${JSON.stringify(consumer)}`
        // );
        break;
      }
    }
    if (consumer) {
      if (mute) {
        await this._pauseConsumer(consumer);
      } else {
        await this._resumeConsumer(consumer);
      }
    }
  }

  //Enable dual-stream encoding of large and small video streams. Returns 0 on success, -1 if the large stream is already at the lowest quality.
  enableEncSmallVideoStream(enable: boolean): number {
    //smallVideoEncParam: QRTCVideoEncParams){
    if (enable) {
      this._useSimulcast = true;
      //If the camera is already open, close it and reopen it. No need to dynamically change the settings, just set them at the beginning.
      const resInfo = this.getResolutionInfo(this._encoderParam.videoResolution);
      if (resInfo.canScaleDown) {
        return 0;
      }
      return -1;
    } 
    
    this._useSimulcast = false;
    return -1;
  }

  //Selects the large or small video stream to watch for the specified userId. If the corresponding uid has not enabled the large or small video stream, this operation has no effect.
  async setRemoteVideoStreamType(userId: string, type: QRTCVideoStreamType) {
    //Check if the video of userId exists. Find it from needConsume. If the corresponding person stops pushing the stream, delete it. Important!!!!!
    //The protocol of 100doc is different. Because there is no consume yet in the protocol of 100doc, there is no consumerId. Therefore, it needs to be found from _consumers.
    // this.reportLog(`[RoomClient] setRemoteVideoStreamType userId=${userId}`);
    let consumer: Consumer|null = null;
    for (const consumerValue of this._consumers.values()) {
      if (
        userId === consumerValue.appData.peerId &&
        consumerValue.kind === "video" &&
        consumerValue.appData.share === undefined
      ) {
        consumer = consumerValue;
        // this.reportLog(
        //   `[RoomClient] setRemoteVideoStreamType consumeInfo=${JSON.stringify(
        //     consumer
        //   )}`
        // );
        break;
      }
    }

    if (consumer) {
      try {
        const layInfo = {
          consumerId: consumer.id,
          preferredLayers: {
            spatialLayer:
              type === QRTCVideoStreamType.QRTCVideoStreamTypeSmall ? 0 : 1,
          },
          producerId: consumer.producerId,
          transportId: consumer.recvTransportId,
          transportType: "webrtc",
        };
        this.reportLog(`[RoomClient] layerInfo=${JSON.stringify(layInfo)}`);
        const setRemoteVideoStreamTypeResult = await this._protoo.request(
          "setPreferredLayers",
          {
            consumerId: consumer.id,
            preferredLayers: {
              spatialLayer:
                type === QRTCVideoStreamType.QRTCVideoStreamTypeSmall ? 0 : 1,
            },
            producerId: consumer.producerId,
            transportId: consumer.recvTransportId,
            transportType: "webrtc",
          }
        );
        this.reportLog(
          `setRemoteVideoStreamTypeResult=${setRemoteVideoStreamTypeResult}`
        );
      } catch (error) {
        this.reportLog(`setConsumerPreferredLayers() | failed:${error}`);
      }
    }
  }

// Screen sharing
// Parameters:
// view: The parent view of the rendering control, which can be set to nil to indicate that the preview effect of screen sharing is not displayed.
// encParam: The screen sharing video encoding parameters, which can be set to nil to allow the SDK to choose the best encoding parameters (resolution, bitrate, etc.).
  async startScreenCapture( // eslint-disable-line
    encParam: QRTCVideoEncParams
  ) {
    this.enableShare(encParam);
  }

    // Screen sharing for Electron version
    async startScreenCaptureForElectron(shareStream: MediaStream){

      if (this._shareProducer) return;
      if(!this._mediasoupDevice){
        return;
      }
      if (!this._mediasoupDevice.canProduce("video")) {
        return;
      }
  
      const track = shareStream.getVideoTracks()[0];
  
      let codec;
      const codecOptions = {
        videoGoogleStartBitrate: 1000,
      };

      if(this._mediasoupDevice.rtpCapabilities == undefined){
        return;
      }
      if(this._mediasoupDevice.rtpCapabilities.codecs == undefined){
        return;
      }
  
      if (this._forceH264) {
        codec = this._mediasoupDevice.rtpCapabilities.codecs.find(
          (c) => c.mimeType.toLowerCase() === "video/h264"
        );
  
        if (!codec) {
          throw new Error("desired H264 codec+configuration is not supported");
        }
      }
  
      const encodings = [
        { maxBitrate: this._encoderParam.maxVideoBitrate * 1000 },
      ];
      if(!this._sendTransport){
        return;
      }
      if(!this._roomInfo){
        return;
      }
      this._shareProducer = await this._sendTransport.produce({
        appData: {
          peerId: this._roomInfo.userId,
          peerName: this._roomInfo.userName,
          share: true,
        },
        codec,
        codecOptions,
        encodings,
        track,
      });
  
      this.safeEmit("screenCaptureStarted");
  
  
      this._shareProducer.on("transportclose", () => {
        this._shareProducer = null;
      });
  
      this._shareProducer.on("trackended", async () => {
        await this.disableShare().catch(() => { }); // eslint-disable-line
      });
    }

// Stop screen sharing
  async stopScreenCapture() {
    await this.disableShare();
  }

// Stop Electron screen sharing
  async stopScreenCaptureForElectron() {
    await this.disableShare();
  }

// Pause screen sharing
  async pauseScreenCapture() {
    if(!this._shareProducer){
      return;
    }
    this._shareProducer.pause();
    try {
      await this._protoo.request("pauseProducer", {
        producerId: this._shareProducer.id,
      });
    } catch (error) {
      this.reportLog(`[RoomClient] pauseScreenCapture() | failed: ${error}`);
    }
    this.safeEmit("screenCapturePaused");
  }

// Resume screen sharing
  async resumeScreenCapture() {
    this.reportLog("resumeScreenCapture()");
    if(!this._shareProducer){
      return;
    }
    if(!this._sendTransport){
      return;
    }
    this._shareProducer.resume();

    try {
      await this._protoo.request("resumeProducer", {
        producerId: this._shareProducer.id,
        transportId: this._sendTransport.id,
        transportType: "webrtc",
      });
    } catch (error) {
      this.reportLog(`[RoomClient] resumeScreenCapture() | failed: ${error}`);
    }
    this.safeEmit("screenCaptureResumed");
  }

  //Actively pull the remote peer's desktop sharing stream
  async startRemoteShare(userId: string, qrtcView: HTMLVideoElement) {
    if(!this._mediasoupDevice){
      return;
    }
    //There are two situations. One is that the video has been consumed before and is only paused, so sending a resume to the server is sufficient.
    let consumer: Consumer|null = null;
    for (const consumerValue of this._consumers.values()) {
      if (
        userId === consumerValue.appData.peerId &&
        consumerValue.kind === "video" &&
        consumerValue.appData.share !== undefined
      ) {
        consumer = consumerValue;
        break;
      }
    }
    if (consumer) {
      //Sends a message to resume the video
      await this._resumeConsumer(consumer);
      const stream = new MediaStream();
      stream.addTrack(consumer.track);
      this._remoteVideoDoms.set(userId, qrtcView);
      (qrtcView as any).srcObject = stream;
      (qrtcView as any)
        .play()
        .catch((error: any) =>
          this.reportLog(`videoElem.play() failed:  ${error}`)
        );
      return;
    }
    //The other situation is that the video has not been consumed before, so consumption needs to be initiated.
    let needConsumeInfo: NeedConsumeInfo|null = null;
    for (const needConsumerValue of this._needConsumes.values()) {
      if (
        userId === needConsumerValue.peerId &&
        needConsumerValue.kind === "video" &&
        needConsumerValue.appData.share !== undefined
      ) {
        needConsumeInfo = needConsumerValue;
        // this.reportLog(
        //   `[RoomClient] startRemoteShare needConsumeInfo=${JSON.stringify(
        //     needConsumeInfo
        //   )}`
        // );
        break;
      }
    }
    if (needConsumeInfo) {
      const peerId = needConsumeInfo.peerId;
      const transportId = await this._createRecvTransport(
        needConsumeInfo.producerId
      );
      //Send a consumption request
      // this.reportLog(
      //   `[RoomClient] createRecvTransport transportId=${JSON.stringify(
      //     transportId
      //   )}`
      // );
      const consumerInfo = await this._protoo.request("consume", {
        appData: { ...needConsumeInfo.appData, peerId },
        producerId: needConsumeInfo.producerId,
        rtpCapabilities: this._mediasoupDevice.rtpCapabilities,
        transportId: transportId,
        transportType: "webrtc",
      });
      // this.reportLog(
      //   `[RoomClient] consume result=${JSON.stringify(consumerInfo)}`
      // );
      //begin consume
      const { id, producerId, kind, producerPaused, rtpParameters, type } = // eslint-disable-line
        consumerInfo;
      try {
        const recvTransport = this._recvTransports.get(transportId);
        if (!recvTransport) {
          return;
        }
        // eslint-disable-next-line no-shadow
        const consumer = await recvTransport.consume({
          appData: { ...needConsumeInfo.appData, peerId: userId }, // Trick.
          id,
          kind,
          producerId,
          recvTransportId: transportId,
          rtpParameters,
          //codecOptions, TODO make sure this is redundant
          
        });

        // Store in the map.
        this._consumers.set(consumer.id, consumer);

        consumer.on("transportclose", () => {
          this._consumers.delete(consumer.id);
        });

        //If the server pauses the keyframe, a resume needs to be sent
        if (producerPaused) {
          await this._protoo.request("resumeConsumer", {
            consumerId: consumer.id,
            producerId: needConsumeInfo.producerId,
            transportId: transportId,
            transportType: "webrtc",
          });
        }

        const stream = new MediaStream();
        stream.addTrack(consumer.track);
        (qrtcView as any).srcObject = null;
        (qrtcView as any).srcObject = stream;
        (qrtcView as any)
          .play()
          .catch((error: any) =>
            this.reportLog(`videoElem.play() failed: ${error}`)
          );
      } catch (error) {
        this.reportLog(`"newConsumer" request failed: ${error}`);
        throw error;
      }
    }
  }

  //Stop pulling the desktop sharing stream of the remote peer.
  async stopRemoteShare(userId: string) { // eslint-disable-line
    let consumer: Consumer|null = null;
    for (const consumerValue of this._consumers.values()) {
      if (
        userId === consumerValue.appData.peerId &&
        consumerValue.kind === "video" &&
        consumerValue.appData.share !== undefined
      ) {
        consumer = consumerValue;
        // this.reportLog(
        //   `[RoomClient] stopRemoteShare consumeInfo=${JSON.stringify(consumer)}`
        // );
        break;
      }
    }
    if (consumer) {
      consumer.close();
      this._consumers.delete(consumer.id);
    }
  }

  //Get the list of cameras
  async getCameraDeviceList() {
    const deviceList = await navigator.mediaDevices.enumerateDevices();

    const camDevices: QRTCMediaDeviceInfo[] = [];
    for (let i = 0; i !== deviceList.length; ++i) {
      // eslint-disable-next-line no-shadow
      const deviceInfo = deviceList[i];
      //option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === "videoinput") {
        const device: QRTCMediaDeviceInfo = {
          deviceId: deviceInfo.deviceId,
          deviceName: deviceInfo.label || `camera ${i + 1}`,
          type: QRTCMediaDeviceType.QRTCMediaDeviceTypeVideoCamera,
        };
        camDevices.push(device);
      }
    }
    // this.reportLog(
    //   `[RoomClient] getCameraDeviceList:${JSON.stringify(camDevices)}`
    // );
    if (camDevices.length === 0) {
      this.safeEmit("warning", { code: 1111 });
    }
    return camDevices;
  }

  async getCurrentCameraDevice() { // eslint-disable-line
    return this._webcam.device!.deviceId;
  }

  //After switching, it needs to be rendered to the original window. At this point, the upper layer needs to call startlocalpreview
  async setCurrentCameraDevice(deviceId: string) {
    try {
      // Reset video resolution to HD.
      this._webcam.resolution = "hd";
      // Closing the current video track before asking for a new one (mobiles do not like
      // having both front/back cameras open at the same time).
      this._webcamProducer!.track!.stop();

      this.reportLog("changeWebcam() | calling getUserMedia()");

      const resolutionInfo = this.getResolutionInfo(
        this._encoderParam.videoResolution
      );
      const fps = this._encoderParam.videoFps;

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { ideal: deviceId },
          frameRate: fps,
          height: { ideal: resolutionInfo.resHeight },
          width: { ideal: resolutionInfo.resWidth },
          //...(VIDEO_CONSTRAINS as any)[resolution]
        },
      });

      const track = stream.getVideoTracks()[0];
      await this._webcamProducer!.replaceTrack({ track });
    } catch (error) {
      this.reportLog(`changeWebcam() | failed: ${error}`);
    }
  }

  //Get the list of microphones.
  async getMicDevicesList() {
    const deviceList = await navigator.mediaDevices.enumerateDevices();

    const micDevices: QRTCMediaDeviceInfo[] = [];
    for (let i = 0; i !== deviceList.length; ++i) {
      // eslint-disable-next-line no-shadow
      const deviceInfo = deviceList[i];
      //option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === "audioinput") {
        if (
          deviceInfo.deviceId !== "communications" &&
          deviceInfo.deviceId !== "default"
        ) {
          const device: QRTCMediaDeviceInfo = {
            deviceId: deviceInfo.deviceId,
            deviceName: deviceInfo.label || `microphone ${i + 1}`,
            type: QRTCMediaDeviceType.QRTCMediaDeviceTypeAudioInput,
          };
          micDevices.push(device);
        }
      }
    }
    this.reportLog(
      `[RoomClient] getMicDeviceList:${JSON.stringify(micDevices)}`
    );
    if (micDevices.length === 0) {
      this.safeEmit("warning", { code: 1201 });
    }
    return micDevices;
  }

  async setCurrentMic(deviceId: string) {
    this._micProducer!.track!.stop();

    try {
      this.reportLog("enableMic() | calling getUserMedia()");
      const constraints = {
        audio: { deviceId: deviceId ? { exact: deviceId } : undefined },
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const track = stream.getAudioTracks()[0];

      await this._micProducer!.replaceTrack({ track });
    } catch (error) {
      this.reportLog(`setCurrentMic() | failed: ${error}`);
    }
  }

  //get the lists of speakers
  async getSpeakerDevicesList() {
    const deviceList = await navigator.mediaDevices.enumerateDevices();

    const speakerDevices: QRTCMediaDeviceInfo[] = [];
    for (let i = 0; i !== deviceList.length; ++i) {
      // eslint-disable-next-line no-shadow
      const deviceInfo = deviceList[i];
      //option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === "audiooutput") {
        if (
          deviceInfo.deviceId !== "communications" &&
          deviceInfo.deviceId !== "default"
        ) {
          const device: QRTCMediaDeviceInfo = {
            deviceId: deviceInfo.deviceId,
            deviceName: deviceInfo.label || `speaker ${i + 1}`,
            type: QRTCMediaDeviceType.QRTCMediaDeviceTypeAudioOutput,
          };
          speakerDevices.push(device);
        }
      }
    }
    this.reportLog(
      `[RoomClient] getSpeakerDeviceList:${JSON.stringify(speakerDevices)}`
    );
    return speakerDevices;
  }

  //Need to output the speaker ID to the audio tag elements, which is an array of audio tags. [audio]
  async setCurrentSpeaker(deviceId: string, elements: any = []) { // eslint-disable-line
    //this._currentSpeakerDeviceId = deviceId;
    for (const element of elements) {
      if (typeof element.sinkId !== "undefined") {
        element
          .setSinkId(deviceId)
          .then(() => {
            // console.log(`Success, audio output device attached: ${deviceId}`);
          })
          .catch((error: any) => {
            let errorMessage = error;
            if (error.name === "SecurityError") {
              errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
            }
            console.error(errorMessage);
            // Jump back to first output device in the list as it's the default.
            //audioOutputSelect.selectedIndex = 0;
          });
      } else {
        console.warn("Browser does not support output device selection.");
      }
    }
  }

  // Get the audio information of the local user
  async getLocalUserAudioStats() { // eslint-disable-line
    if (this._webcamProducer) {
      return this._micProducer!.getStats();
    } 
    return null;
  }

  // Get the video information of the local user
  async getLocalUserVideoStats() { // eslint-disable-line
    if (this._micProducer) {
      return this._webcamProducer!.getStats();
    } 
    return null;
  }

  //Get the screen sharing information of the local user
  async getLocalUserShareStats() { // eslint-disable-line
    if (this._shareProducer) {
      return this._shareProducer.getStats();
    } 
    return null;
  }

  //Get the status statistics information of the video and audio streams of the corresponding remote user
  async getRemoteUserAudioStats(userId: string) { // eslint-disable-line
    let consumer: Consumer|null = null;
    for (const consumerValue of this._consumers.values()) {
      if (
        userId === consumerValue.appData.peerId &&
        consumerValue.kind === "audio"
      ) {
        consumer = consumerValue;
        break;
        //this.reportLog(`[RoomClient] getRemoteUserAudioStats consumeInfo=${JSON.stringify(consumer)}`)
      }
    }
    if (consumer) {
      return consumer.getStats();
    } 
    return null;
  }

  //Get the video information of the corresponding remote user
  async getRemoteUserVideoStats(userId: string) { // eslint-disable-line
    let consumer: Consumer|null = null;
    for (const consumerValue of this._consumers.values()) {
      if (
        userId === consumerValue.appData.peerId &&
        consumerValue.kind === "video" &&
        consumerValue.appData.share === undefined
      ) {
        consumer = consumerValue;
        break;
        //this.reportLog(`[RoomClient] getRemoteUserAudioStats consumeInfo=${JSON.stringify(consumer)}`)
      }
    }
    if (consumer) {
      return consumer.getStats();
    } 
    return null;
  }
  //Get the screen sharing information of the corresponding remote user
  async getRemoteUserShareStats(userId: string) { // eslint-disable-line
    let consumer: Consumer|null = null;
    for (const consumerValue of this._consumers.values()) {
      if (
        userId === consumerValue.appData.peerId &&
        consumerValue.kind === "video" &&
        consumerValue.appData.share !== undefined
      ) {
        consumer = consumerValue;
        break;
        //this.reportLog(`[RoomClient] getRemoteUserAudioStats consumeInfo=${JSON.stringify(consumer)}`)
      }
    }
    if (consumer) {
      return consumer.getStats();
    } 
    return null;
  }

  async onFlyBandWidth() { // eslint-disable-line
    const parameters = this._webcamProducer!.rtpSender!.getParameters();
    parameters.encodings[0].maxBitrate = 125 * 1000;
    this._webcamProducer!.rtpSender!.setParameters(parameters);
  }

  // Get the capture volume of the audio
  getAudioCaptureVolume() {
    return this._audioVolumn;
  }

  // Set the capture volume of the audio
  setAudioCaptureVolume(volume: number) {
    if (volume < 0 || volume > 100) {
      return;
    }

    this._audioVolumn = volume;

    //this._gainNode.gain.value = 0.01 * volume;
  }

  // Get the playback volume of the audio
  getAudioPlayoutVolume() {
    return this._audioPlayoutVolumn;
  }

  // Set the playback volume of the audio
  setAudioPlayoutVolume(elements: any, volume: number) {
    elements.forEach((element: any) => {
      element.volume = 0.01 * volume;
    });
  }

  // Set the rendering mode of the remote image
  setRemoteViewFillMode(userId: string, mode: QRTCVideoFillMode) {
    const qrtcView = this._remoteVideoDoms.get(userId);
    if (qrtcView) {
      (qrtcView as any).style["object-fit"] =
        mode === QRTCVideoFillMode.QRTCVideoFillMode_Fill ? "fill" : "contain";
    }
  }

  // get sdk version
  getSDKVersion() {
    return "0.5.0.0";
  }

  //###########################################SDK interface end###########################################

  //#################################private funcs begin#############################################
  //create send transport
  async _createSendTransport() {
    if (!this._sendTransport) {
      //create
      this.reportLog(`[RoomClient] createWebRtcTransport`);
      const transportInfo = await this._protoo.request(
        "createWebRtcTransport",
        {
          appData: {
            consuming: false,
            producing: true,
          },
          direction: "sendonly",
          forceTcp: this._forceTcp,
          workerId: 0,
        }
      );

      // this.reportLog(
      //   `[RoomClient] createWebRtcTransport ret:${JSON.stringify(
      //     transportInfo
      //   )}`
      // );

      const {
        id,
        iceParameters,
        iceCandidates,
        dtlsParameters,
        sctpParameters,
      } = transportInfo;

      this._sendTransport = this._mediasoupDevice!.createSendTransport({
        dtlsParameters,
        iceCandidates,
        iceParameters,
        iceServers: [],
        id,
        proprietaryConstraints: PC_PROPRIETARY_CONSTRAINTS,
        sctpParameters,
      });

      this._sendTransport.on(
        "connect",
        (
          // eslint-disable-next-line no-shadow
          { dtlsParameters },
          callback,
          errback // eslint-disable-line no-shadow
        ) => {
          this._protoo
            .request("connectWebRtcTransport", {
              transportId: this._sendTransport!.id,
              dtlsParameters, // eslint-disable-line
            })
            .then(callback)
            .catch(errback);
        }
      );

      this._sendTransport.on(
        "produce",
        async ({ kind, rtpParameters, appData }, callback, errback) => {
          try {
            // eslint-disable-next-line no-shadow
            const { id } = await this._protoo.request("produce", {
              appData,
              kind,
              paused: kind === "audio" ? this._audioStartWithMute : false,
              rtpParameters,
              transportId: this._sendTransport!.id,
            });

            callback({ id });
          } catch (error) {
            errback(error);
          }
        }
      );

      this._sendTransport.on("connectionstatechange", (connectionState) => {
        this.reportLog(
          `[RoomClient] _sendTransport on connectionstatechange=${connectionState}`
        );
        if (connectionState === "disconnected") {
          // If the connection state is "disconnected", wait for 2 seconds. If it doesn't reconnect, a restart is needed.
          const that = this;
          const operation = retry.operation({
            factor: 2,
            maxTimeout: 2 * 1000, // Try every 2 seconds after that
            minTimeout: 1 * 1000, // Check after 3 seconds
            retries: 3,
          });
          operation.attempt((currentAttempt) => {
            if (!that._sendTransport) {
              operation.stop();
              return;
            }
            if (that._sendTransport.closed) {
              operation.stop();
              return;
            }
            if (that._sendTransport.connectionState !== "connected") {
              this.reportLog(
                `[RoomClient] Attempt restart sendTransport failed for ${currentAttempt}`
              );
              that.restartIce();
              if (operation.retry()) return;
            } else {
              this.reportLog(
                `[RoomClient]  restartIce success after ${currentAttempt} retry times`
              );
              operation.stop();
              return;
            }
          });
        }
      });
    }
  }

  //create receive transport
  async _createRecvTransport(producerId: string): Promise<string> {
    const transportInfo = await this._protoo.request("createWebRtcTransport", {
      appData: {
        consuming: true,
        producerId: producerId,
        producing: false,
      },
      direction: "recvonly",
      forceTcp: this._forceTcp,
      workerId: 0,
      
    });

    const { id, iceParameters, iceCandidates, dtlsParameters, sctpParameters } =
      transportInfo;
    // this.reportLog(
    //   `[RoomClient] createRecvTransport result=${JSON.stringify(transportInfo)}`
    // );
    const that = this;
    const p = new Promise<string>(function (resolve, reject) { // eslint-disable-line
      //find the transport
      let transportExist: boolean = false;

      that._recvTransports.forEach(function (value:any, key:any, map:any) { // eslint-disable-line
        if (id === value.id) {
          transportExist = true;
        }
      });
      if (transportExist) {
        that.reportLog(`[RoomClient] recvTransport already Exist`);
        resolve(id);
        //return id
      } else {
        that.reportLog(`[RoomClient] recvTransport not Exist,creating...`);
        const recvTransport: Transport =
          that._mediasoupDevice!.createRecvTransport({
            dtlsParameters,
            iceCandidates,
            iceParameters,
            iceServers: [],
            id,
            sctpParameters,
          });
        // that.reportLog(
        //   `[RoomClient] createRecvTransport transport=${JSON.stringify(
        //     recvTransport
        //   )}`
        // );
        recvTransport.on(
          "connect",
          (
            // eslint-disable-next-line no-shadow
            { dtlsParameters },
            callback,
            errback // eslint-disable-line no-shadow
          ) => {
            that._protoo
              .request("connectWebRtcTransport", {
                dtlsParameters,
                transportId: recvTransport.id,
              })
              .then(callback)
              .catch(errback);
          }
        );

        that._recvTransports.set(id, recvTransport);
        resolve(id);
        //return id
      }
    });

    return p;
  }

  async enableMic(mute: boolean) { // eslint-disable-line
    this.reportLog("[RoomClient] enableMic()");

    if (this._micProducer) return;

    if (!this._mediasoupDevice!.canProduce("audio")) {
      this.reportLog("[RoomClient] enableMic() | cannot produce audio");
      return;
    }

    let track;

    try {
      this.reportLog("[RoomClient] enableMic() | calling getUserMedia()");
      let stream: any = null;
      try {
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      } catch (error: any) {
        if (error.name === "NotAllowedError") {
          this.safeEmit("error", { code: -1317 });
        } else if (error.name === "AbortError") {
          this.safeEmit("error", { code: -1302 });
        } else if (error.name === "OverConstrainedError") {
          this.safeEmit("error", { code: -1318 });
        } else if (error.name === "NotReadableError") {
          this.safeEmit("error", { code: -131 });
        }
      }

      track = stream.getAudioTracks()[0];
      let opusStereo = false; 
      let opusMaxAverageBitrate = 40000;
      // eslint-disable-next-line default-case
      switch(this._audioProfile) {
        case QRTCAudioProfile.AUDIO_PROFILE_STANDARD:
          opusStereo = false;
          opusMaxAverageBitrate = 40000
          break;
        case QRTCAudioProfile.AUDIO_PROFILE_HIGH:
          opusStereo = false;
          opusMaxAverageBitrate = 128000
          break;
        case QRTCAudioProfile.AUDIO_PROFILE_STANDARD_STEREO:
          opusStereo = true;
          opusMaxAverageBitrate = 64000
          break;
        case QRTCAudioProfile.AUDIO_PROFILE_HIGH_STEREO:
          opusStereo = true;
          opusMaxAverageBitrate = 192000
          break;
      }

      this._micProducer = await this._sendTransport!.produce({
        appData: {
          peerId: this._roomInfo!.userId,
          peerName: this._roomInfo!.userName,
        },
        codecOptions: {
          opusDtx: true,
          opusMaxAverageBitrate,
          opusMaxPlaybackRate: 48000,
          opusStereo,
        },
        track,
        
        
        // NOTE: for testing codec selection.
        // codec : this._mediasoupDevice.rtpCapabilities.codecs
        // 	.find((codec) => codec.mimeType.toLowerCase() === 'audio/pcma')
      });

      this.safeEmit("localAudioTrack", this._micProducer.track);

      this._micProducer.on("transportclose", () => {
        this._micProducer = null;
      });

      this._micProducer.on("trackended", () => {
        this.disableMic().catch(() => {}); // eslint-disable-line
      });
    } catch (error) {
      this.reportLog(`[RoomClient] enableMic() | failed:${error}`);
      if (track) track.stop();
    }
  }

  async disableMic() {
    this.reportLog("disableMic()");

    if (!this._micProducer) return;

    this._micProducer.close();

    try {
      await this._protoo.request("closeProducer", {
        producerId: this._micProducer.id,
        transportId: this._sendTransport!.id,
        transportType: "webrtc",
      });
    } catch (error) {}

    this._micProducer = null;
  }

  // enable local webcam
  async enableWebcam(qrtcView?: HTMLVideoElement) {
    this.reportLog("enableWebcam()");

    if (this._webcamProducer) {
      //if view exist,then render to the view
      if (qrtcView) {
        const streamPlay = new MediaStream();
        streamPlay.addTrack(this._webcamProducer!.track!);
        (qrtcView as any).srcObject = streamPlay;
        (qrtcView as any)
          .play()
          .catch((error: any) =>
            this.reportLog(`videoElem.play() failed: ${error}`)
          );
      }
      return;
    }

    if (!this._mediasoupDevice!.canProduce("video")) {
      this.reportLog("enableWebcam() | cannot produce video");

      return;
    }

    //this._localQRTCView = qrtcView;

    let track;
    let device;

    try {
      await this._updateWebcams();
      device = this._webcam.device;

      //const { resolution } = this._webcam;

      if (!device) throw new Error("no webcam devices");

      this.reportLog("enableWebcam() | calling getUserMedia()");

      const resolutionInfo = this.getResolutionInfo(
        this._encoderParam.videoResolution
      );
      const fps = this._encoderParam.videoFps;
      let stream: any = null;
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: { ideal: device.deviceId },
            frameRate: fps,
            height: { ideal: resolutionInfo.resHeight },
            width: { ideal: resolutionInfo.resWidth },
            //...(VIDEO_CONSTRAINS as any)[resolution]
          },
        });
      } catch (error: any) {
        //this.reportLog(123456, error.name, error.message)
        if (error.name === "NotAllowedError") {
          this.safeEmit("error", { code: -1314 });
        } else if (error.name === "AbortError") {
          this.safeEmit("error", { code: -1301 });
        } else if (error.name === "OverConstrainedError") {
          this.safeEmit("error", { code: -1315 });
        } else if (error.name === "NotReadableError") {
          console.log("[RoomClient] camera NotReadableError");
          this.safeEmit("error", { code: -1316 });
        }
      }

      track = stream.getVideoTracks()[0];

      let encodings;
      let codec;
      const codecOptions = {
        videoGoogleStartBitrate: 1000,
      };

      if (this._forceH264) {
        codec = this._mediasoupDevice!.rtpCapabilities!.codecs!.find(
          (c) => c.mimeType.toLowerCase() === "video/h264"
        );

        if (!codec) {
          throw new Error("desired H264 codec+configuration is not supported");
        }
      }

      //Set the dual-stream mode and check if the user's selected resolution supports dual-streaming.
      if (this._useSimulcast && resolutionInfo.canScaleDown) {
        // If VP9 is the only available video codec then use SVC.
        const firstVideoCodec = // eslint-disable-line
          this._mediasoupDevice!.rtpCapabilities!.codecs!.find(
            (c) => c.kind === "video"
          );

        //let encodings;
        const simulcastInfo = [
          {
            maxBitrate: resolutionInfo.maxDownBitrate * 1000,
            scaleResolutionDownBy: resolutionInfo.scaleDownBy,
            
          },
          {
            maxBitrate: resolutionInfo.maxUpBitrate * 1000,
            scaleResolutionDownBy: 1,
          },
        ];

        this.reportLog(`[RoomClient] firstVideoCodec is not vp9...`);
        encodings = simulcastInfo;

        this._webcamProducer = await this._sendTransport!.produce({
          appData: {
            peerId: this._roomInfo!.userId,
            peerName: this._roomInfo!.userName,
          },
          // NOTE: for testing codec selection.
          codec: this._mediasoupDevice!.rtpCapabilities!.codecs!.find(
            (codec) => codec.mimeType.toLowerCase() === "video/h264" // eslint-disable-line
          ),
          codecOptions: {
            videoGoogleStartBitrate: 1000,
          },
          encodings,
          track,
        });
      } else {
        this.reportLog(`[RoomClient] else......`);
        const encodeInfo = [
          { maxBitrate: this._encoderParam.maxVideoBitrate * 1000 },
        ];
        encodings = encodeInfo;
        this._webcamProducer = await this._sendTransport!.produce({
          appData: {
            peerId: this._roomInfo!.userId,
            peerName: this._roomInfo!.userName,
          },
          codec,
          codecOptions,
          encodings,
          track,
        });
      }

      if (qrtcView) {
        const streamPlay = new MediaStream();
        streamPlay.addTrack(this._webcamProducer!.track!);
        (qrtcView as any).srcObject = streamPlay;
        (qrtcView as any)
          .play()
          .catch((error: any) =>
            this.reportLog(`videoElem.play() failed: ${error}`)
          );
      }

      this.reportLog(`[RoomClient] emit localVideoTrack event`);

      this._webcamProducer.on("transportclose", () => {
        this.reportLog(`[RoomClientD] _webcamProducer on transportclose`);
        this._webcamProducer = null;
      });

      this._webcamProducer.on("trackended", () => {
        this.reportLog(`[RoomClientD] _webcamProducer on transportclose`);
        this.disableWebcam().catch(() => {}); // eslint-disable-line
      });
    } catch (error) {
      this.reportLog(`enableWebcam() | failed:${error}`);
      if (track) track.stop();
    }
  }

  async disableWebcam() {
    this.reportLog("disableWebcam()");

    if (!this._webcamProducer) return;
    console.log(`[RoomClient111] this._webcamProducer.close`);
    this._webcamProducer.close();

    try {
      await this._protoo.request("closeProducer", {
        producerId: this._webcamProducer.id,
        transportId: this._sendTransport!.id,
        transportType: "webrtc",
      });
    } catch (error) {}

    this._webcamProducer = null;
  }

  async enableShare(encParam: QRTCVideoEncParams) {
    this.reportLog(`[RoomClient] enableShare()`);

    await this._createSendTransport();

    if (this._shareProducer) return;
    //else if (this._webcamProducer)
    //await this.disableWebcam();

    if (!this._mediasoupDevice!.canProduce("video")) {
      this.reportLog("[RoomClient] enableShare() | cannot produce video");

      return;
    }
    let track;
    try {
      this.reportLog("[RoomClient] enableShare() | calling getUserMedia()");
      const resolutionInfo = this.getResolutionInfo(encParam.videoResolution);

      let stream: any = null;
      try {
        stream = await (navigator.mediaDevices as any).getDisplayMedia({
          audio: false,
          video: {
            cursor: true,
            displaySurface: "monitor",
            frameRate: { max: encParam.videoFps },
            height: { max: resolutionInfo.resHeight },
            logicalSurface: true,
            width: { max: resolutionInfo.resWidth },
          },
        });
      } catch (error: any) {
        //this.reportLog(123456, error.name, error.message)
        if (error.name === "NotAllowedError") {
          this.safeEmit("error", { code: -1308 });
        } else {
          this.safeEmit("error", { code: -1309 });
        }
      }

      // May mean cancelled (in some implementations).
      if (!stream) {
        return;
      }

      track = stream.getVideoTracks()[0];

      let encodings;
      let codec;
      const codecOptions = {
        videoGoogleStartBitrate: 1000,
      };

      if (this._forceH264) {
        codec = this._mediasoupDevice!.rtpCapabilities!.codecs!.find(
          (c) => c.mimeType.toLowerCase() === "video/h264"
        );

        if (!codec) {
          throw new Error("desired H264 codec+configuration is not supported");
        }
      }

      if (this._useSharingSimulcast && resolutionInfo.canScaleDown) {
        // If VP9 is the only available video codec then use SVC.
        const firstVideoCodec = // eslint-disable-line
          this._mediasoupDevice!.rtpCapabilities!.codecs!.find(
            (c) => c.kind === "video"
          );

        //let encodings;
        const simulcastInfo = [
          {
            maxBitrate: resolutionInfo.maxDownBitrate * 1000,
            scaleResolutionDownBy: resolutionInfo.scaleDownBy,
            
          },
          {
            maxBitrate: resolutionInfo.maxUpBitrate * 1000,
            scaleResolutionDownBy: 1,
          },
        ];

        this.reportLog(`[RoomClient] firstVideoCodec is not vp9...`);
        // eslint-disable-next-line no-shadow
        const encodings = simulcastInfo.map((encoding) => ({
          ...encoding,
          dtx: true,
        }));

        this._shareProducer = await this._sendTransport!.produce({
          appData: {
            peerId: this._roomInfo!.userId,
            peerName: this._roomInfo!.userName,
            share: true,
          },
          codecOptions: {
            videoGoogleStartBitrate: 1000,
          },
          encodings,
          track,
        });
      } else {
        const encodeInfo = [
          { maxBitrate: this._encoderParam.maxVideoBitrate * 1000 },
        ];
        encodings = encodeInfo;
        this._shareProducer = await this._sendTransport!.produce({
          appData: {
            peerId: this._roomInfo!.userId,
            peerName: this._roomInfo!.userName,
            share: true,
          },
          codec,
          codecOptions,
          encodings,
          track,
        });
      }
      this.safeEmit("screenCaptureStarted");
      this.reportLog(
        `[RoomClient] startScreenShare using codec=${
          this._shareProducer.rtpParameters.codecs[0].mimeType.split("/")[1]
        }`
      );

      this._shareProducer.on("transportclose", () => {
        this.reportLog(`[RoomClient] shareProducer transportclose!`);
        this._shareProducer = null;
      });

      this._shareProducer.on("trackended", async () => {
        this.reportLog(`[RoomClient] shareProducer trackended!`);
        await this.disableShare().catch(() => {}); // eslint-disable-line
      });
    } catch (error: any) {
      this.reportLog(`enableShare() | failed:${error}`);

      if (error.name !== "NotAllowedError") {

      }

      if (track) track.stop();
    }
  }

  async disableShare() {
    this.reportLog("disableShare()");

    if (!this._shareProducer) return;

    this._shareProducer.close();

    try {
      await this._protoo.request("closeProducer", {
        producerId: this._shareProducer.id,
        transportId: this._sendTransport!.id,
        transportType: "webrtc",
      });
    } catch (error) {

    }

    this.safeEmit("screenCaptureStopped");

    this._shareProducer = null;
  }

  async muteAudio() { // eslint-disable-line
    this.reportLog("muteAudio()");
  }

  async unmuteAudio() { // eslint-disable-line
    this.reportLog("unmuteAudio()");
  }

  async restartIce() {
    this.reportLog("restartIce()");

    try {
      if (this._sendTransport) {
        const iceParameters = await this._protoo.request("restartIce", {
          transportId: this._sendTransport.id,
        });
        // this.reportLog(
        //   `[RoomClient] restartIce iceParameters=${JSON.stringify(
        //     iceParameters
        //   )}`
        // );
        await this._sendTransport.restartIce({ iceParameters });
      }
    } catch (error) {
      this.reportLog(`restartIce() | failed:${error}`);
    }
  }

  async setMaxSendingSpatialLayer(spatialLayer: any) {
    try {
      if (this._webcamProducer)
        await this._webcamProducer.setMaxSpatialLayer(spatialLayer);
      else if (this._shareProducer)
        await this._shareProducer.setMaxSpatialLayer(spatialLayer);
    } catch (error) {
      this.reportLog(`setMaxSendingSpatialLayer() | failed:${error}`);
    }
  }

  async setConsumerPreferredLayers(
    consumerId: any,
    spatialLayer: any,
    temporalLayer: any
  ) {
    try {
      await this._protoo.request("setConsumerPreferredLayers", {
        consumerId,
        spatialLayer,
        temporalLayer,
      });
    } catch (error) {
      this.reportLog(`setConsumerPreferredLayers() | failed:${error}`);
    }
  }

  async setConsumerPriority(consumerId: any, priority: any) {
    try {
      await this._protoo.request("setConsumerPriority", {
        consumerId,
        priority,
      });
    } catch (error) {
      this.reportLog(`setConsumerPriority() | failed:${error}`);
    }
  }

  async requestConsumerKeyFrame(consumerId: any) {
    this.reportLog(`requestConsumerKeyFrame() [consumerId:${consumerId}]`);

    try {
      await this._protoo.request("requestConsumerKeyFrame", { consumerId });
    } catch (error) {
      this.reportLog(`requestConsumerKeyFrame() | failed:${error}`);
    }
  }

  async enableChatDataProducer() {
    this.reportLog("enableChatDataProducer()");

    if (!this._useDataChannel) return;

    // NOTE: Should enable this code but it's useful for testing.
    // if (this._chatDataProducer)
    // 	return;

    try {
      // Create chat DataProducer.
      this._chatDataProducer = await this._sendTransport!.produceData({
        appData: { info: "my-chat-DataProducer" },
        label: "chat",
        maxRetransmits: 1,
        ordered: false,
        priority: "medium",
      });

      this._chatDataProducer.on("transportclose", () => {
        this._chatDataProducer = null;
      });

      this._chatDataProducer.on("open", () => {
        this.reportLog('chat DataProducer "open" event');
      });

      this._chatDataProducer.on("close", () => {
        this.reportLog('chat DataProducer "close" event');

        this._chatDataProducer = null;
      });

      this._chatDataProducer.on("error", (error) => {
        this.reportLog(`chat DataProducer "error" event:${error}`);
      });

      this._chatDataProducer.on("bufferedamountlow", () => {
        this.reportLog('chat DataProducer "bufferedamountlow" event');
      });
    } catch (error) {
      this.reportLog(`enableChatDataProducer() | failed:${error}`);
      throw error;
    }
  }

  async enableBotDataProducer() {
    this.reportLog("enableBotDataProducer()");

    if (!this._useDataChannel) return;

    // NOTE: Should enable this code but it's useful for testing.
    // if (this._botDataProducer)
    // 	return;

    try {
      // Create chat DataProducer.
      this._botDataProducer = await this._sendTransport!.produceData({
        appData: { info: "my-bot-DataProducer" },
        label: "bot",
        maxPacketLifeTime: 2000,
        ordered: false,
        priority: "medium",
      });

      this._botDataProducer.on("transportclose", () => {
        this._botDataProducer = null;
      });

      this._botDataProducer.on("open", () => {
        this.reportLog('bot DataProducer "open" event');
      });

      this._botDataProducer.on("close", () => {
        this.reportLog('bot DataProducer "close" event');

        this._botDataProducer = null;
      });

      this._botDataProducer.on("error", (error) => {
        this.reportLog(`bot DataProducer "error" event:${error}`);
      });

      this._botDataProducer.on("bufferedamountlow", () => {
        this.reportLog('bot DataProducer "bufferedamountlow" event');
      });
    } catch (error) {
      this.reportLog(`enableBotDataProducer() | failed:${error}`);
      throw error;
    }
  }

  async sendCustomCmdMsg(cmdId: number, data: string) {
    await this._protoo.notify("customCmdMsg", {
      cmdId: cmdId,
      data: data,
      peerId: this._roomInfo!.userId,
      seq: 1,
    });
  }

  async sendChatMessage(text: string) { // eslint-disable-line
    this.reportLog(`sendChatMessage() [text:${text}]`);

    if (!this._chatDataProducer) {
      return;
    }

    try {
      this._chatDataProducer.send(text);
    } catch (error) {
      this.reportLog(`chat DataProducer.send() failed:${error}`);
    }
  }

  async sendBotMessage(text: string) { // eslint-disable-line
    if (!this._botDataProducer) {
      return;
    }

    try {
      this._botDataProducer.send(text);
    } catch (error) {
      this.reportLog(`bot DataProducer.send() failed:${error}`);
    }
  }

  async changeDisplayName(displayName: string) {
    this.reportLog(`changeDisplayName() [displayName:${displayName}]`);

    // Store in cookie.
    cookiesManager.setUser({ displayName });

    try {
      await this._protoo.request("changeDisplayName", { displayName });

      this._roomInfo!.userName = displayName;
    } catch (error) {
      this.reportLog(`changeDisplayName() | failed: ${error}`);
    }
  }

  async getSendTransportRemoteStats() { // eslint-disable-line
    this.reportLog("getSendTransportRemoteStats()");

    if (!this._sendTransport) return;

    return this._protoo.request("getTransportStats", {
      transportId: this._sendTransport.id,
    });
  }

  async getAudioRemoteStats() { // eslint-disable-line
    this.reportLog("getAudioRemoteStats()");

    if (!this._micProducer) return;

    return this._protoo.request("getProducerStats", {
      producerId: this._micProducer.id,
    });
  }

  async getVideoRemoteStats() { // eslint-disable-line
    this.reportLog("getVideoRemoteStats()");

    const producer = this._webcamProducer || this._shareProducer;

    if (!producer) return;

    return this._protoo.request("getProducerStats", {
      producerId: producer.id,
    });
  }

  async getConsumerRemoteStats(consumerId: any) { // eslint-disable-line
    this.reportLog("getConsumerRemoteStats()");

    const consumer = this._consumers.get(consumerId);

    if (!consumer) return;

    return this._protoo.request("getConsumerStats", { consumerId });
  }

  async getChatDataProducerRemoteStats() { // eslint-disable-line
    this.reportLog("getChatDataProducerRemoteStats()");

    const dataProducer = this._chatDataProducer;

    if (!dataProducer) return;

    return this._protoo.request("getDataProducerStats", {
      dataProducerId: dataProducer.id,
    });
  }

  async getBotDataProducerRemoteStats() { // eslint-disable-line
    this.reportLog("getBotDataProducerRemoteStats()");

    const dataProducer = this._botDataProducer;

    if (!dataProducer) return;

    return this._protoo.request("getDataProducerStats", {
      dataProducerId: dataProducer.id,
    });
  }

  async getDataConsumerRemoteStats(dataConsumerId: any) { // eslint-disable-line
    this.reportLog("getDataConsumerRemoteStats()");

    const dataConsumer = this._dataConsumers.get(dataConsumerId);

    if (!dataConsumer) return;

    return this._protoo.request("getDataConsumerStats", { dataConsumerId });
  }

  async getSendTransportLocalStats() { // eslint-disable-line
    // this.reportLog("getSendTransportLocalStats()");

    if (!this._sendTransport) return null;

    return this._sendTransport.getStats();
  }

  async getAudioLocalStats() { // eslint-disable-line
    this.reportLog("getAudioLocalStats()");

    if (!this._micProducer) return null;

    return this._micProducer.getStats();
  }

  async getVideoLocalStats() { // eslint-disable-line
    this.reportLog("getVideoLocalStats()");

    const producer = this._webcamProducer || this._shareProducer;

    if (!producer) return null;

    return producer.getStats();
  }

  async getConsumerLocalStats(consumerId: any) { // eslint-disable-line
    const consumer = this._consumers.get(consumerId);

    if (!consumer) return null;

    return consumer.getStats();
  }

  async applyNetworkThrottle({ uplink, downlink, rtt, secret }: any) {
    try {
      await this._protoo.request("applyNetworkThrottle", {
        downlink,
        rtt,
        secret,
        uplink,
      });
    } catch (error) {
      this.reportLog(`applyNetworkThrottle() | failed:${error}`);
    }
  }

  async resetNetworkThrottle({ silent = false, secret }: any) {
    this.reportLog("resetNetworkThrottle()");

    try {
      await this._protoo.request("resetNetworkThrottle", { secret });
    } catch (error) {
      if (!silent) {
        this.reportLog(`resetNetworkThrottle() | failed:${error}`);
      }
    }
  }

  async _enterRoom() {
      this._mediasoupDevice = new mediasoupClient.Device({
        handlerName: this._handlerName as BuiltinHandlerName,
      });
      // const requestData = {
      //   sdkAppId: this._roomInfo!.sdkAppId,
      //   streamId: this._roomInfo!.userId,
      //   tokenId: this._roomInfo!.xConferenceToken,
      // };
      // console.log(`[RoomClient] requestData=${JSON.stringify(requestData)}`)
      const routerRtpCapabilities2 = await this._protoo.request(
        "getRouterRtpCapabilities",
        {
          sdkAppId: this._roomInfo!.sdkAppId,
          streamId: this._roomInfo!.userId,
          tokenId: this._roomInfo!.xConferenceToken,
        }
      );

      const routerRtpCapabilities = routerRtpCapabilities2.rtpCapabilities;
      // this.reportLog(
      //   `[RoomClient] getRouterRtpCapabilities:${JSON.stringify(
      //     routerRtpCapabilities
      //   )}`
      // );
    try {
      await this._mediasoupDevice.load({ routerRtpCapabilities });
    } catch (error) {
      const enterError = new QRTCError("请求进房超时，请检查网络", "", QRTCErrorInfo.ERR_ENTER_ROOM_TIMEOUT);
      throw enterError;
    }
      
      // NOTE: Stuff to play remote audios due to browsers' new autoplay policy.
      //
      // Just get access to the mic and DO NOT close the mic track for a while.
      // Super hack!
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const audioTrack = stream.getAudioTracks()[0];

        audioTrack.enabled = false;

        setTimeout(() => audioTrack.stop(), 120000);
      }catch(error:any){
        const audioError = new QRTCError(error.name, error.message, QRTCErrorInfo.ERR_MIC_START_FAIL);
        this.handleGetUserMediaError(audioError, 'audio');
      }

      try {
        const joinedInfo = await this._protoo.request("join", {
          acceptLanguage: this._roomInfo!.acceptLanguage,
          avatarUrl: this._roomInfo!.avatarUrl,
          device: this._device,
          displayName: this._roomInfo!.userName,
          rtpCapabilities: this._mediasoupDevice.rtpCapabilities,
          sctpCapabilities: undefined, // this._useDataChannel && this._mediasoupDevice.sctpCapabilities
          xConferenceToken: this._roomInfo!.xConferenceToken, //later will change to set token at getroutercapabilities
        });

        const { peerList, selfTimeMs } = joinedInfo;

        this.safeEmit("enterRoom", { selfTimeMs });

        const needQueryTrack = [];

        for (const peer of peerList) {
          if (peer.peerId === this._roomInfo!.userId) {
            continue;
          }
          this._peers.set(peer.peerId, peer);

          // this.reportLog(
          //   `[RoomClient] remoteUserEnterRoom: userId=${peer.peerId} userName=${peer.displayName}`
          // );
          this.safeEmit("remoteUserEnterRoom", {
            avatarUrl: peer.avatarUrl,
            timeMs: peer.timeMs,
            userDevice: peer.device,
            userId: peer.peerId,
            userName: peer.displayName,
          });

          const simpleTrack = peer.simpleTrack;
          if (
            simpleTrack &&
            (simpleTrack.audio || simpleTrack.video || simpleTrack.share)
          ) {
            needQueryTrack.push(peer.peerId);
          }
        }

        // this.reportLog(
        //   `[RoomClient] joined needQueryTrack:${JSON.stringify(needQueryTrack)}`
        // );

        if (needQueryTrack.length > 0) {
          this._queryPeerListTrack(needQueryTrack);
        }

        // if (Array.isArray(voiceList) && voiceList.length > 0) {
        //   for (let j = 0; j < voiceList.length; j++) {
        //     const item = voiceList[j].voiceEnergy;

        //     this.safeEmit("userSpeaking", {
        //       action: item.action,
        //       userId: item.peerID,
        //     });
        //   }
        // }
      } catch (error) {
        //this.safeEmit('errorMsg', { code: QRTCErrorInfo.ERR_ENTER_ROOM_TIMEOUT, errorData: { msg: "请求进房超时，请检查网络" } });
        //let enterError = new YRTCError("请求进房超时，请检查网络", "", QRTCErrorInfo.ERR_ENTER_ROOM_TIMEOUT);
        //reject(error);
        const enterError = new QRTCError("Request to enter room timed out, please check your network", "", QRTCErrorInfo.ERR_ENTER_ROOM_TIMEOUT);
        throw enterError;
      }

     await this.calculateRoomStats();
  }

  handleGetUserMediaError(error:QRTCError, type:string){
    if (error.errorName === 'OverconstrainedError') {
        if (type === "video") {
            error.errorCode = QRTCErrorInfo.ERR_CAMERA_START_FAIL;
            this.safeEmit('errorMsg', { code: QRTCErrorInfo.ERR_CAMERA_START_FAIL, errorData: { msg: "Resolution not supported" } })
        } else {
            error.errorCode = QRTCErrorInfo.ERR_MIC_START_FAIL;
            this.safeEmit('errorMsg', { code: QRTCErrorInfo.ERR_MIC_START_FAIL, errorData: { msg: "Microphone OverconstrainedError" } })
        }
    } else if (error.errorName === 'AbortError') {
        if (type === "video") {
            error.errorCode = QRTCErrorInfo.ERR_CAMERA_NO_GRANT;
            this.safeEmit('errorMsg', { code: QRTCErrorInfo.ERR_CAMERA_NO_GRANT, errorData: { msg: "Camera device AbortError" } })
        } else {
            error.errorCode = QRTCErrorInfo.ERR_MIC_NO_GRANT;
            this.safeEmit('errorMsg', { code: QRTCErrorInfo.ERR_MIC_NO_GRANT, errorData: { msg: "Microphone device AbortError" } })
        }
    }else if (error.errorName === 'NotAllowedError') {
        if (type === "video") {
            error.errorCode = QRTCErrorInfo.ERR_CAMERA_NO_GRANT;
            this.safeEmit('errorMsg', { code: QRTCErrorInfo.ERR_CAMERA_NO_GRANT, errorData: { msg: "Camera device not authorized, possibly due to user denial of permission" } })
        } else {
            error.errorCode = QRTCErrorInfo.ERR_MIC_NO_GRANT;
            this.safeEmit('errorMsg', { code: QRTCErrorInfo.ERR_MIC_NO_GRANT, errorData: { msg: "Microphone device not authorized, possibly due to user denial of permission" } })
        }
    }else if (error.errorName === 'NotFoundError') {
        if (type === "video") {
            error.errorCode = QRTCErrorInfo.ERR_CAMERA_START_FAIL;
            this.safeEmit('errorMsg', { code: QRTCErrorInfo.ERR_CAMERA_START_FAIL, errorData: { msg: "Camera device not found" } })
        } else {
            error.errorCode = QRTCErrorInfo.ERR_MIC_START_FAIL;
            this.safeEmit('errorMsg', { code: QRTCErrorInfo.ERR_MIC_START_FAIL, errorData: { msg: "Microphone device not found" } })
        }
    }else if (error.errorName === 'NotReadableError') {
        if (type === "video") {
            error.errorCode = QRTCErrorInfo.ERR_CAMERA_OCCUPIED;
            this.safeEmit('errorMsg', { code: QRTCErrorInfo.ERR_CAMERA_OCCUPIED, errorData: { msg: "Failed to open camera" } })
        } else {
            error.errorCode = QRTCErrorInfo.ERR_MIC_OCCUPIED;
            this.safeEmit('errorMsg', { code: QRTCErrorInfo.ERR_MIC_OCCUPIED, errorData: { msg: "Failed to open microphone" } })
        }
    }else if (error.errorName === 'SecurityError') {
        if (type === "video") {
            error.errorCode = QRTCErrorInfo.ERR_CAMERA_START_FAIL;
            this.safeEmit('errorMsg', { code: QRTCErrorInfo.ERR_CAMERA_START_FAIL, errorData: { msg: "Camera device does not meet security requirements" } })
        } else {
            error.errorCode = QRTCErrorInfo.ERR_MIC_START_FAIL;
            this.safeEmit('errorMsg', { code: QRTCErrorInfo.ERR_MIC_START_FAIL, errorData: { msg: "Microphone device does not meet security requirements" } })
        }
    }else{
        if (type === "video") {
            error.errorCode = QRTCErrorInfo.ERR_CAMERA_START_FAIL;
            this.safeEmit('errorMsg', { code: QRTCErrorInfo.ERR_CAMERA_START_FAIL, errorData: { msg: "Unknown camera error" } })
        } else {
            error.errorCode = QRTCErrorInfo.ERR_MIC_START_FAIL;
            this.safeEmit('errorMsg', { code: QRTCErrorInfo.ERR_MIC_START_FAIL, errorData: { msg: "Unknown microphone error" } })
        }
    }

}

async calculateRoomStats() { // eslint-disable-line
  // Periodically obtain producer and consumer stats
  const that = this
  let counter = 0
  // If disableDataStats is not passed in the room information, data statistics are enabled by default
  if (!this._roomInfo!.disableDataStats) {
      this._audioLevelQueryInterval = setInterval(async function () { // eslint-disable-line
          let localVideoStats: LocalVideoStats = { // eslint-disable-line
              framesPerSecond: 0, // Downstream video frame rate
              jitter: 0, // Upstream video network jitter
              jitterBufferDelay: 0, // Upstream video delay
              packetBitrate: 0, // Upstream video rate
              packetsLostRatio: 0 // Upstream video packet loss rate
          }
          let localShareStats: LocalShareStats = { // eslint-disable-line
              framesPerSecond: 0, // Upstream video frame rate
              jitter: 0, // Upstream video network jitter
              jitterBufferDelay: 0, // Upstream video delay
              packetBitrate: 0, // Upstream video rate
              packetsLostRatio: 0, // Upstream video packet loss rate
          }
          let localAudioStats: LocalAudioStats = { // eslint-disable-line
              jitter: 0, // Upstream audio network jitter
              jitterBufferDelay: 0, // Upstream audio delay
              packetBitrate: 0, // Upstream audio rate
              packetsLostRatio: 0, // Upstream audio packet loss rate
          }
				//webcamproducer
				if (that._webcamProducer) {
					if (!that._webcamProducer.closed) {
						const webcamProducerStats = await that._webcamProducer.getStats();
						that._localVideoStats = that.handleLocalVideoStats(webcamProducerStats, that._webcamProducer);
					}
				}
				//micProducer
				if (that._micProducer) {
					if (!that._micProducer.closed) {
						const micProducerStats = await that._micProducer.getStats();
					   that._localAudioStats = that.handleLocalAudioStats(micProducerStats, that._micProducer);
					}
				}
				if(that._shareProducer) {
					if (!that._shareProducer.closed) {
						const shareProducerStats = await that._shareProducer.getStats();
						that._localShareStats = that.handleLocalShareStats(shareProducerStats, that._shareProducer);
					}
				}
        const totalRemoteVideoStats: RemoteVideoStats = {
          framesPerSecond: 0,//Downstream video frame rate
          jitter: 0,//Downstream video network jitter
          jitterBufferDelay: 0,//Downstream video delay
          packetBitrate: 0,//Downstream video rate
          packetsLostRatio: 0,//Downstream video packet loss rate
      }
      const totalRemoteShareStats: RemoteShareStats = {
          framesPerSecond: 0,//Downstream video frame rate  
          jitter: 0,//Downstream video network jitter
          jitterBufferDelay: 0,//Downstream video delay
          packetBitrate: 0,//Downstream video rate
          packetsLostRatio: 0,//Downstream video packet loss rate 
      }
      const totalRemoteAudioStats: RemoteAudioStats = {
          jitter:0, //Downstream audio network jitterjitter
          jitterBufferDelay:0, //Downstream audio delay
          packetBitrate:0, //Downstream audio rate
          packetsLostRatio:0, //Downstream audio packet loss rate
      }
				let shareCount=0;
				let videoCount=0;
				let audioCount=0;
				for(const [,consumer] of that._consumers){
					const consumerStats = await that.getConsumerLocalStats(consumer.id)
					let userVolumes: QRTCVolumeInfo[] = [] // eslint-disable-line
					let totalVolume: number = 0 // eslint-disable-line
					const isShare = consumer.appData.share != undefined;
					if(isShare){
						const remoteShareStats =that.handleRemoteShareStats(consumerStats, consumer);
						totalRemoteShareStats.packetBitrate += remoteShareStats.packetBitrate;
						totalRemoteShareStats.packetsLostRatio += remoteShareStats.packetsLostRatio;
						totalRemoteShareStats.jitterBufferDelay += remoteShareStats.jitterBufferDelay;
						totalRemoteShareStats.jitter += remoteShareStats.jitter;
						totalRemoteShareStats.framesPerSecond += remoteShareStats.framesPerSecond;
						shareCount++; // eslint-disable-line
					}else if(consumer.kind == 'video'){
						const remoteVideoStats = that.handleRemoteVideoStats(consumerStats, consumer);
						totalRemoteVideoStats.packetBitrate += remoteVideoStats.packetBitrate;
						totalRemoteVideoStats.packetsLostRatio += remoteVideoStats.packetsLostRatio;
						totalRemoteVideoStats.jitterBufferDelay += remoteVideoStats.jitterBufferDelay;
						totalRemoteVideoStats.jitter += remoteVideoStats.jitter;
						totalRemoteVideoStats.framesPerSecond += remoteVideoStats.framesPerSecond;
						videoCount++;
					}else{
						const remoteAudiooStats = that.handleRemoteAudioStats(consumerStats, consumer);
						totalRemoteAudioStats.packetBitrate += remoteAudiooStats.packetBitrate;
						totalRemoteAudioStats.packetsLostRatio += remoteAudiooStats.packetsLostRatio;
						totalRemoteAudioStats.jitterBufferDelay += remoteAudiooStats.jitterBufferDelay;
						totalRemoteAudioStats.jitter += remoteAudiooStats.jitter;
						audioCount++;
					}
				}
				//Calculate the average statistical parameters of video, screen sharing, and audio

				that._averageRemoteShareStats = totalRemoteShareStats;//共享目前只有一个
				if(videoCount>0){
					that._averageRemoteVideoStats = {
            framesPerSecond: totalRemoteVideoStats.framesPerSecond/videoCount,
            jitter: totalRemoteVideoStats.jitter/videoCount,
            jitterBufferDelay: totalRemoteVideoStats.jitterBufferDelay/videoCount,
						packetBitrate: totalRemoteVideoStats.packetBitrate/videoCount,
						packetsLostRatio: totalRemoteVideoStats.packetsLostRatio/videoCount,
						}
						//console.log(`[RoomClient] averageRemoteVideoStats ${JSON.stringify(that._averageRemoteVideoStats)}`);
				}
				if(audioCount>0){
					that._averageRemoteAudioStats = {
            jitter: totalRemoteAudioStats.jitter/audioCount,
            jitterBufferDelay: totalRemoteAudioStats.jitterBufferDelay/audioCount,
						packetBitrate: totalRemoteAudioStats.packetBitrate/audioCount,
						packetsLostRatio: totalRemoteAudioStats.packetsLostRatio/audioCount,
						}
						//console.log(`[RoomClient] averageRemoteAudioStats ${JSON.stringify(that._averageRemoteAudioStats)}`);
				}


				//Calculate upstream and downstream packet loss rates
				let upQuality: QRTCQuality = QRTCQuality.QRTCQuality_Unknown
				let downQuality: QRTCQuality = QRTCQuality.QRTCQuality_Unknown
				let upAveragePacketLost: Number = 0.0
				let downAveragePacketLost: Number = 0.0
				if (counter == 1000) {
					counter = 0
				}
				counter++
				if (counter % 5 == 0) {
					//Periodically obtain upstream packet loss rate.
					if (that._sendTransport) {
						let sendPacketlost = 0;
						let packetsSent = 0;
            //let audioLevels = that.getAudioLevels();
            //console.log(`[RoomClient] audioLevels=${[...audioLevels.entries()]}`)
						const localstats = await that.getSendTransportLocalStats()
						for (const [key, value] of localstats!) {
							//this.reportLog(`[APP] localstats key=${key} value=${JSON.stringify(value)}`)
							if (key.startsWith('RTCRemoteInboundRtp')) {
								sendPacketlost += value.packetsLost
								//The packet loss count and timestamp are already in milliseconds, so there is no need to multiply by 1000.```
							} else if (key.startsWith('RTCTransport')) {
								packetsSent += value.packetsSent
							}
						}
						//Calculate the average upstream quality (average upstream packet loss rate).
						const packetsSentPerSecond = packetsSent - that._sendTransport.lastpacketsSentOrRecv
						if (packetsSentPerSecond > 0) {
							const averagePacketLost = ((sendPacketlost - that._sendTransport.lastpacketlost) / packetsSentPerSecond) * 100
							upAveragePacketLost = averagePacketLost
							// that.reportLog(`[RoomClient] uplink averagePacketLost=${averagePacketLost}`)
							// eslint-disable-next-line default-case
							switch (true) {
								case averagePacketLost <= 0.001:
									upQuality = QRTCQuality.QRTCQuality_Excellent
									break;
								case averagePacketLost > 0.001 && averagePacketLost <= 2:
									upQuality = QRTCQuality.QRTCQuality_Good
									break;
								case averagePacketLost > 2 && averagePacketLost <= 5:
									upQuality = QRTCQuality.QRTCQuality_Poor
									break;
								case averagePacketLost > 5 && averagePacketLost <= 10:
									upQuality = QRTCQuality.QRTCQuality_Bad
									break;
								case averagePacketLost > 10 && averagePacketLost <= 20:
									upQuality = QRTCQuality.QRTCQuality_Vbad
									break;
								case averagePacketLost > 10 && averagePacketLost > 20:
									upQuality = QRTCQuality.QRTCQuality_Down
									break;
							}
						}
						that._sendTransport.lastpacketlost = sendPacketlost
						that._sendTransport.lastpacketsSentOrRecv = packetsSent
					}

					//Periodically obtain downstream packet loss rate.
          let allAveragePacketLost = 0;//The sum of the packet loss rate per second for all recvTransports
          for (const [recvTransportKey, recvTransportValue] of that._recvTransports) { // eslint-disable-line
            const remoteStats = await recvTransportValue.getStats()
              let recvPacketLost = 0
              let recvPacketsReceived = 0

              //Search for the relevant statistics
              for (const [remoteStatKey, remoteStatValue] of remoteStats) {
                  if (remoteStatKey.startsWith('RTCInboundRTP')) {
                      recvPacketLost += remoteStatValue.packetsLost
                  } else if (remoteStatKey.startsWith('RTCTransport')) {
                      recvPacketsReceived += remoteStatValue.packetsReceived
                  }
              }
              //The number of packets received per second
              const packetsReceivedPerSecond = recvPacketsReceived - recvTransportValue.lastpacketsSentOrRecv
              if (packetsReceivedPerSecond > 0) {
                  const averagePacketLost = (recvPacketLost - recvTransportValue.lastpacketlost) / packetsReceivedPerSecond
                  allAveragePacketLost += averagePacketLost
              }
              recvTransportValue.lastpacketlost = recvPacketLost
              recvTransportValue.lastpacketsSentOrRecv = recvPacketsReceived
          }
          //Calculate the average packet loss rate for all receiving transports
					const recvAveragePacketLost = (allAveragePacketLost / that._recvTransports.size) * 100
					downAveragePacketLost = recvAveragePacketLost
					// that.reportLog(`[RoomClient] downlink recvAveragePacketLost=${recvAveragePacketLost}`)
					// eslint-disable-next-line default-case
					switch (true) {
						case recvAveragePacketLost <= 0.001:
							downQuality = QRTCQuality.QRTCQuality_Excellent
							break;
						case recvAveragePacketLost > 0.001 && recvAveragePacketLost <= 2:
							downQuality = QRTCQuality.QRTCQuality_Good
							break;
						case recvAveragePacketLost > 2 && recvAveragePacketLost <= 5:
							downQuality = QRTCQuality.QRTCQuality_Poor
							break;
						case recvAveragePacketLost > 5 && recvAveragePacketLost <= 10:
							downQuality = QRTCQuality.QRTCQuality_Bad
							break;
						case recvAveragePacketLost > 10 && recvAveragePacketLost <= 20:
							downQuality = QRTCQuality.QRTCQuality_Vbad
							break;
						case recvAveragePacketLost > 10 && recvAveragePacketLost > 20:
							downQuality = QRTCQuality.QRTCQuality_Down
							break;
					}

          // Callback to the upper layer
          // Score based on averagePacketLost
          const qualityInfo = {
            downAveragePacketLost,
            downlink: downQuality,
            upAveragePacketLost,
            uplink: upQuality
          }
					//  that.reportLog(`[RoomClient] networkQuality:${JSON.stringify(qualityInfo)}`)
					that.safeEmit("networkQuality", qualityInfo)

				}

			}, 1000)
		}
	}

handleLocalShareStats(producerStats: any, producer: Producer):LocalShareStats {
  const localShareStats: LocalShareStats = {
    framesPerSecond: 0,
    jitter: 0,
    jitterBufferDelay: 0,
    packetBitrate: 0,
    packetsLostRatio: 0,
  };
  producerStats.forEach(function (value: any, key: any, map: any) { // eslint-disable-line
    //TODO too much logs
    // that.reportLog(`[RoomClient] handleRemoteShareStats consumerStats:${JSON.stringify(value)}`)
    //console.log(`[RoomClient] handleLocalShareStats producerStats:${JSON.stringify(value)}`)
    if (value.type == 'outbound-rtp') {
      //jitter
      localShareStats.packetsLostRatio = value.bytesSent > 0 ? value.retransmittedBytesSent / value.bytesSent : 0;
      localShareStats.packetBitrate = value.bytesSent - producer.bytesLastSent;
      localShareStats.framesPerSecond = value.framesPerSecond ? value.framesPerSecond:0;
      producer.bytesLastSent = value.bytesSent;
    }else if(value.type == "remote-inbound-rtp"){
      localShareStats.jitter = value.jitter != undefined ? value.jitter : 0;
      localShareStats.jitterBufferDelay = value.jitter != undefined ? value.jitter : 0;
    }
  });

  return localShareStats;
  }

  handleLocalVideoStats(producerStats: any, producer: Producer):LocalVideoStats {
    const that = this;
    const localVideoStats: LocalVideoStats = {
      framesPerSecond: 0,
      jitter: 0,
      jitterBufferDelay: 0,
      packetBitrate: 0,
      packetsLostRatio: 0,
    };
    producerStats.forEach(function (value: any, key: any, map: any) { // eslint-disable-line
      //TODO too much logs
      // that.reportLog(`[RoomClient] handleRemoteShareStats consumerStats:${JSON.stringify(value)}`)
      //console.log(`[RoomClient] handleLocalVideoStats producerStats:${JSON.stringify(value)}`)
      if (value.type == 'outbound-rtp') {
        if(value.framesSent){
          that._webcamProducer!.totalFramesSent = value.framesSent
        }
        //jitter
        localVideoStats.packetsLostRatio = value.bytesSent > 0 ? value.retransmittedBytesSent / value.bytesSent : 0;
        localVideoStats.packetBitrate = value.bytesSent - producer.bytesLastSent;
        localVideoStats.framesPerSecond = value.framesPerSecond ? value.framesPerSecond:0;
        producer.bytesLastSent = value.bytesSent;
      }else if(value.type == "remote-inbound-rtp"){
        localVideoStats.jitter = value.jitter != undefined ? value.jitter : 0;
        localVideoStats.jitterBufferDelay = value.jitter != undefined ? value.jitter : 0;
      }
    });
    return localVideoStats;
  }

  handleLocalAudioStats(producerStats:any, producer:Producer):LocalAudioStats{
    const that = this;
    const localAudioStats: LocalAudioStats = {
      jitter: 0, // Downstream audio network jitter
      jitterBufferDelay: 0, // Downstream audio delay
      packetBitrate: 0, // Downstream audio rate
      packetsLostRatio: 0, // Downstream audio packet loss rate
    };
    producerStats.forEach(function (value: any, key: any, map: any) { // eslint-disable-line
      //TODO too much logs
      //console.log(`[RoomClient] handleLocalAudioStats producerStats:${JSON.stringify(value)}`)
      if (value.type == "outbound-rtp") {
        if(value.packetsSent){
          that._micProducer!.totalFramesSent = value.packetsSent
        }
        localAudioStats.packetsLostRatio = value.packetsSent > 0 ? value.retransmittedPacketsSent / value.packetsSent : 0;
        localAudioStats.packetBitrate = value.bytesSent - producer.bytesLastSent;
        producer.bytesLastSent = value.bytesSent;
        if(value.bytesSent > 0){
          //console.error(`[RoomClient] audio handleLocalAudioStats value.bytesSent=${value.bytesSent}`);
        }
      }else if (value.type == "media-source"){
        const audioLevelInt = value.audioLevel != undefined ? Math.round(value.audioLevel * 100) : 0;
        // console.log(`[RoomClient] handleLocalAudioStats audioLevelInt=${audioLevelInt}`)
        const peerId = that._roomInfo!.userId;
        let action = audioLevelInt > 15 ? "unmute" : null;

        // User speaking...

        // if(that._audioLevelMap.has(peerId)){
        //   if( that._audioLevelMap.get(peerId) > 15 && audioLevelInt <= 15 ) action = "mute";
        //   else if( that._audioLevelMap.get(peerId) > 15 ) action = null;
        // }

        if(action){
          that.safeEmit('userSpeaking',{
            action: action,
            userId: peerId
          })
        }

        that._audioLevelMap.set(that._roomInfo!.userId, audioLevelInt > 100 ? 100 : audioLevelInt);
      }else if (value.type == "remote-inbound-rtp"){
        localAudioStats.jitter = value.jitter != undefined ? value.jitter : 0;
        localAudioStats.jitterBufferDelay = value.jitter != undefined ? value.jitter : 0;
      }
    });

    return localAudioStats;
  }

  handleRemoteShareStats(consumerStats: any, consumer: Consumer):RemoteShareStats {
    const that = this;
    const remoteShareStats: RemoteShareStats = {
      framesPerSecond: 0,//Downstream video frame rate
      jitter: 0,//Downstream video network jitter
      jitterBufferDelay: 0,//Downstream video delay
      packetBitrate: 0,//Downstream video rate
      packetsLostRatio: 0,//Downstream video packet loss rate
      //Downstream video resolution, which cannot be averaged
    };
    consumerStats.forEach(function (value: any, key: any, map: any) { // eslint-disable-line
			//TODO too much logs
			// that.reportLog(`[RoomClient] handleRemoteShareStats consumerStats:${JSON.stringify(value)}`)
			// console.log(`[RoomClient] handleRemoteShareStats consumerStats:${JSON.stringify(value)}`)
			if (value.type == 'inbound-rtp') {
				//jitter
				remoteShareStats.jitter = (value.jitter != undefined ? value.jitter : 0);
				remoteShareStats.jitterBufferDelay = value.jitterBufferDelay != undefined ? value.jitterBufferDelay : 0;
				remoteShareStats.packetsLostRatio = value.packetsReceived > 0 ? value.packetsLost / (value.packetsReceived + value.packetsLost) : 0;
				remoteShareStats.packetBitrate = value.bytesReceived - consumer.bytesLastReceived;
				consumer.SetBytesLastReceived(value.bytesReceived);

				const framesReceived = value.framesReceived;
				const diff = framesReceived - consumer.framesLastReceived;
				consumer.SetFramesLastReceived(framesReceived);
				if (diff == 0) {
                  consumer.SetNoFrameRecvInterval(consumer.noFrameRecvInterval+1);
				  if(consumer.noFrameRecvInterval == 5 || consumer.noFrameRecvInterval == 10 || consumer.noFrameRecvInterval == 15){
					if (!consumer.remotePaused) {

						that.safeEmit('errorMsg', {code: QRTCErrorInfo.NETWORK_CONSUME_NO__FRAME_IN5S, errorData: {userId:consumer.appData.peerId, share:true, msg:"连续几秒（5/10/15）未收到订阅的指定流的媒体包"}}); // eslint-disable-line
					}
                }
				}else{
					// If this is the first frame of the consumer, tell the server to cancel the loading if there is no loading, no need to process it
          if(!consumer.firstFrameArrived){
            // If data is received and there is no data received for a period of time, notify the upper layer to cancel the loading
             that.safeEmit('errorMsg', {code: QRTCErrorInfo.NETWORK_CONSUME_FRAME_RECOVERY, errorData: {userId:consumer.appData.peerId, share:true, msg:"The media package of the subscribed instruction stream is received again"}}); // eslint-disable-line
             consumer.SetFirstFrameArrived(true);
         }
         if(consumer.noFrameRecvInterval>=3){
             // If data is received and there is no data received for a period of time, notify the upper layer to cancel the loading
             that.safeEmit('errorMsg', {code: QRTCErrorInfo.NETWORK_CONSUME_FRAME_RECOVERY, errorData: {userId:consumer.appData.peerId, share:true, msg:"The media package of the subscribed instruction stream is received again"}}); // eslint-disable-line
         }
         consumer.SetNoFrameRecvInterval(0); // Reset if data is received
				}
			}
		});
		return remoteShareStats;
	}

	handleRemoteVideoStats(consumerStats: any, consumer: Consumer):RemoteVideoStats {
		const that = this;
    const remoteVideoStats: RemoteVideoStats = {
      //Downstream video resolution, which cannot be averaged
      framesPerSecond: 0,//Downstream video frame rate
      jitter: 0,//Downstream video network jitter
      jitterBufferDelay: 0,//Downstream video delay
      packetBitrate: 0,//Downstream video rate
      packetsLostRatio: 0,//Downstream video packet loss rate
  };
		for(const [key,value] of consumerStats) { // eslint-disable-line
			//TODO too much logs
			//that.reportLog(`[RoomClient] handleRemoteVideoStats consumerStats:${JSON.stringify(value)}`)
			if (value.type == 'inbound-rtp') {
				//jitter
				remoteVideoStats.jitter = value.jitter != undefined ? value.jitter : 0;
				remoteVideoStats.jitterBufferDelay = value.jitterBufferDelay != undefined ? value.jitterBufferDelay : 0;
				remoteVideoStats.packetsLostRatio = value.packetsReceived > 0 ? value.packetsLost / (value.packetsReceived + value.packetsLost) : 0;
				remoteVideoStats.packetBitrate = value.bytesReceived - consumer.bytesLastReceived;
				consumer.SetBytesLastReceived(value.bytesReceived);

				const framesReceived = value.framesReceived;
				const diff = framesReceived - consumer.framesLastReceived;

				//console.log(`[RoomClient111] handleRemoteVideoStats consumerid=${consumer.id} packetsReceived=${framesReceived} packetsLastReceived=${consumer.framesLastReceived}`)
				consumer.SetFramesLastReceived(framesReceived);
				if (diff == 0) {
                  consumer.SetNoFrameRecvInterval(consumer.noFrameRecvInterval+1);
				  //console.log(`[RoomClient111] handleRemoteVideoStats noPacketRecvInterval=${consumer.noFrameRecvInterval} isRemotePaused=${consumer.remotePaused} isClosed=${consumer.closed}`)
				  if(consumer.noFrameRecvInterval == 5 || consumer.noFrameRecvInterval == 10 || consumer.noFrameRecvInterval == 15){
					if (!consumer.remotePaused) {
						that.safeEmit('errorMsg', {code: QRTCErrorInfo.NETWORK_CONSUME_NO__FRAME_IN5S, errorData: {appData: consumer.appData, msg:"连续几秒（5/10/15）未收到订阅的指定流的媒体包", remoteVideoStats, share:false, userId:consumer.appData.peerId, }});
					}
                }
        } else {
          // If this is the first frame of the consumer, tell the server to cancel the loading if there is loading, no need to process it
          if (!consumer.firstFrameArrived) {
            // If data is received and there is no data received for a period of time, notify the upper layer to cancel the loading
            that.safeEmit('errorMsg', { code: QRTCErrorInfo.NETWORK_CONSUME_FRAME_RECOVERY, errorData: { appData: consumer.appData, msg: "The media package of the subscribed instruction stream is received again", share: false, userId: consumer.appData.peerId } });
            consumer.SetFirstFrameArrived(true);
          }
          if (consumer.noFrameRecvInterval >= 3) {
            // If data is received and there is no data received for a period of time, notify the upper layer to cancel the loading
            that.safeEmit('errorMsg', { code: QRTCErrorInfo.NETWORK_CONSUME_FRAME_RECOVERY, errorData: { appData: consumer.appData, msg: "The media package of the subscribed instruction stream is received again", share: false, userId: consumer.appData.peerId } });
          }
          consumer.SetNoFrameRecvInterval(0); // Reset if data is received
        }

				that.safeEmit('errorMsg', {code: QRTCErrorInfo.NETWORK_CONSUME_FRAME_RECOVERY, errorData: { appData: consumer.appData, msg:"视频相关信息", remoteVideoStats, share:false, userId:consumer.appData.peerId } })
			}
		}
		return remoteVideoStats;
	}

// Return JSON for averaging
handleRemoteAudioStats(consumerStats: any, consumer: Consumer): RemoteAudioStats {
  const that = this;
  const peerId = consumer.appData.peerId;
  const remoteAudioStats: RemoteAudioStats = {
      jitter: 0, // Downstream audio network jitter
      jitterBufferDelay: 0, // Downstream audio delay
      packetBitrate: 0, // Downstream audio rate
      packetsLostRatio: 0, // Downstream audio packet loss rate
  };
		for(const [key,value] of consumerStats){ // eslint-disable-line
			//TODO too much logs
			if (value.type == "inbound-rtp") {
				//audio level
        const audioLevelInt = value.audioLevel != undefined ? Math.round(value.audioLevel * 100) : 0;
        let action = audioLevelInt > 15 ? "unmute" : null;

        // User speaking....

        // if(that._audioLevelMap.has(peerId)){
        //   if( that._audioLevelMap.get(peerId) > 15 && audioLevelInt <= 15 ) action = "mute";
        //   else if( that._audioLevelMap.get(peerId) > 15 ) action = null;
        // }

        if(action){
          that.safeEmit('userSpeaking',{
            action: action,
            userId: peerId
          })
        }

        that._audioLevelMap.set(peerId, audioLevelInt > 100 ? 100 : audioLevelInt); 
        //jitter
				remoteAudioStats.jitter = value.jitter != undefined ? value.jitter : 0;
				remoteAudioStats.jitterBufferDelay = value.jitterBufferDelay != undefined ? value.jitterBufferDelay : 0;
				remoteAudioStats.packetsLostRatio = value.packetsReceived > 0 ? value.packetsLost / (value.packetsReceived + value.packetsLost) : 0;
				remoteAudioStats.packetBitrate = value.bytesReceived - consumer.bytesLastReceived;
				consumer.SetBytesLastReceived(value.bytesReceived);

				const packetsReceived = value.packetsReceived;
				const diff = packetsReceived - consumer.packetsLastReceived;
				//console.log(`[RoomClient1111] handleRemoteAudioStats consumerid=${consumer.id} packetsReceived=${packetsReceived} packetsLastReceived=${consumer.packetsLastReceived} diff=${diff}`)
				consumer.SetPacketsLastReceived(packetsReceived);

				if (diff == 0) {
					consumer.SetNoPacketRecvInterval(consumer.noPacketRecvInterval + 1);
					//console.log(`[RoomClient111] handleRemoteAudioStats noPacketRecvInterval=${consumer.noPacketRecvInterval} isRemotePaused=${consumer.remotePaused} isClosed=${consumer.closed}`)
					if (consumer.noPacketRecvInterval == 5 || consumer.noPacketRecvInterval == 10 || consumer.noPacketRecvInterval == 15) {
						//console.log(`[RoomClient1111] handleRemoteAudioStats noPacketRecvInterval trigger value = ${consumer.noPacketRecvInterval} consumer.id=${consumer.id} consumer.paused=${consumer.paused} consumer.remotePaused=${consumer.remotePaused}`)
						if (!consumer.remotePaused && !consumer.closed) {
							//console.log(`[RoomClient111] noPacketRecvInterval trigger value = ${consumer.noPacketRecvInterval} consumer.paused=${consumer.paused} consumer.remotePaused=${consumer.remotePaused}`)
							that.safeEmit('errorMsg', { code: QRTCErrorInfo.NETWORK_CONSUME_NO_PACKET_IN5S, errorData: { appData: consumer.appData, msg: "连续几秒（5/10/15）未收到订阅的指定流的音频包", userId: consumer.appData.peerId } });
						}
					}
				} else {
					//如果是这个consumer的第一帧,则也要告诉服务器有loading则取消,没有则不用处理
					if (!consumer.firstPacketArrived) {
						//收到数据了,并且之前有段时间收不到,则通知上层取消loading
						that.safeEmit('errorMsg', { code: QRTCErrorInfo.NETWORK_CONSUME_PACKET_RECOVERY, errorData: { appData: consumer.appData, msg: "订阅的指令流的音频包重新收到", userId: consumer.appData.peerId } });
						consumer.SetFirstPacketArrived(true);
					}
					if (consumer.noPacketRecvInterval >= 5) {
						//收到数据了,并且之前有段时间收不到,则通知上层取消loading
						that.safeEmit('errorMsg', { code: QRTCErrorInfo.NETWORK_CONSUME_PACKET_RECOVERY, errorData: { appData: consumer.appData, msg: "订阅的指令流的音频包重新收到", userId: consumer.appData.peerId } });
					}
					consumer.SetNoPacketRecvInterval(0); //收到数据则重置
				}
			}
		}

		return remoteAudioStats;
	}

	//called by app leavel

	//upload stats
	getLocalMediaStats():[LocalVideoStats,LocalAudioStats,LocalShareStats]{
		return [this._localVideoStats, this._localAudioStats, this._localShareStats];
	}
	//download stats
	getRemoteMediaStats():[RemoteVideoStats,RemoteAudioStats,RemoteShareStats]{
		return [this._averageRemoteVideoStats, this._averageRemoteAudioStats, this._averageRemoteShareStats];
	}
	//audio level map
	getAudioLevels():Map<string,number>{
		return this._audioLevelMap;
	}


  _queryPeerListTrack(needQueryTrack: any) {
    const splitTrackArr = this._splitQueryTrack(needQueryTrack, 50);

    // console.error("需要请求的track数组--------");
    // console.log(splitTrackArr);

    splitTrackArr.forEach((arr) => {
      this._protoo
        .request("queryUser", {
          peerId: arr,
        })
        .then((res: any) => {
          // console.error(666666667);
          // console.log(res.peerList);

          for (const queryPeer of res.peerList) {
            for (const track of queryPeer.track) {
              // this.reportLog(`[RoomClient] joined get track:${JSON.stringify(track)}`)
              const { producerId, kind, paused, appData } = track;

              //这个要存一下
              this._needConsumes.set(producerId, {
                appData,
                kind,
                peerId: queryPeer.peerId,
                producerId,
              });

              const emitMsgAvailable =
                appData.share !== undefined
                  ? "userShareAvailable"
                  : kind == "video"
                  ? "userVideoAvailable"
                  : "userAudioAvailable";

              if (paused) {
                //远端暂停,意味着远端这个视频不存在,因此不处理
                this.reportLog(
                  `[RoomClient] after enterRoom, there is track paused!`
                );

                if (emitMsgAvailable === "userShareAvailable") {
                  this.safeEmit(emitMsgAvailable, {
                    appData,
                    available: true,
                    paused: true,
                    producerId,
                    userId: queryPeer.peerId,
                  });
                }
              } else {
                this.safeEmit(emitMsgAvailable, {
                  appData,
                  available: true,
                  producerId,
                  userId: queryPeer.peerId,
                });
              }
            }
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    });
  }

  _splitQueryTrack(data: any, num: number) {
    let index = 0;
    const arr = [];
    while (index < data.length) {
      arr.push(data.slice(index, (index += num)));
    }

    return arr;
  }

  //sdk提供接口getPageUserInfo(pageNo:页码)，上层调用此接口,传入要获取信息的页码,sdk层通过该页码，
  //向服务器请求对应信息(getPeerList)，此处sdk需要在遍历peerlist的时候，与之前的用户列表进行一下
  //去重操作，并通过(queryUser)获取对应媒体信息,最后通过OnRemoteUserEnterRoom也将信息回调给上层
  async getPageUserInfo(pageNo: number) {
    const pageUserInfo = await this._protoo.request("getPeerList", {
      page: pageNo,
    });
    // this.reportLog(
    //   `[RoomClient] getPageUserInfo:${JSON.stringify(pageUserInfo)}`
    // );
    const {
      peerNumberInRoom,
      peerPages,
      currentPage,
      peerNumberInCurrentPage,
      peerList,
    } = pageUserInfo;
    //先通知总量等信息
    this.safeEmit("pageUserInfo", {
      currentPage, //当前页码
      peerNumberInCurrentPage, //每页人数
      peerNumberInRoom, //总人数
      peerPages, //总页数
    });
    //再通知具体信息
    //let peerIdList = [];
    for (const peer of peerList) {
      if (peer.id === this._roomInfo!.userId) {
        continue;
      }
      //先判断是否重复
      //如果重复则不通知上层
      //没重复则加入map并通知上层
      if (!this._peers.has(peer.id)) {
        //peerIdList.push(peer.id);
        this._peers.set(peer.id, peer);
        //通知上层
        this.safeEmit("remoteUserEnterRoom", {
          avatarUrl: peer.avatarUrl,
          timeMs: peer.timeMs,
          userDevice: peer.device,
          userId: peer.id,
          userName: peer.displayName,
        });
        //遍历该peer的track信息
        for (const track of peer.track) {
          // this.reportLog(
          //   `[RoomClient] joined get track:${JSON.stringify(track)}`
          // );
          const { producerId, kind, paused, appData } = track; //TODO by pcg 需要让服务器加上这个appData
          //这个要存一下
          this._needConsumes.set(producerId, {
            appData,
            kind,
            peerId: peer.peerId,
            producerId,
          });
          const emitMsgAvailable =
            appData.share !== undefined
              ? "userShareAvailable"
              : kind === "video"
              ? "userVideoAvailable"
              : "userAudioAvailable";

          if (paused) {
            //远端暂停,意味着远端这个视频不存在,因此不处理
            this.reportLog(
              `[RoomClient] after enterRoom, there is track paused!`
            );
          } else {
            this.safeEmit(emitMsgAvailable, {
              available: true,
              userId: peer.peerId,
            });
          }
        }
      }
    }
  }

  async _updateWebcams() {
    this.reportLog("_updateWebcams()");

    // Reset the list.
    this._webcams = new Map();

    this.reportLog("_updateWebcams() | calling enumerateDevices()");

    const devices = await navigator.mediaDevices.enumerateDevices();

    for (const device of devices) {
      if (device.kind !== "videoinput") continue;

      this._webcams.set(device.deviceId, device);
    }

    const array = Array.from(this._webcams.values());
    const len = array.length;
    const currentWebcamId = this._webcam.device
      ? this._webcam.device.deviceId
      : undefined;

    this.reportLog(`_updateWebcams() [webcams:${array}]`);

    if (len === 0) this._webcam.device = null;
    else if (!this._webcams.has(currentWebcamId!))
      this._webcam.device = array[0];
  }

  _getWebcamType(device: any) {
    let state = "front"
    if (/(back|rear)/i.test(device.label)) {
      this.reportLog("_getWebcamType() | it seems to be a back camera");
      state = "back";
    } else {
      this.reportLog("_getWebcamType() | it seems to be a front camera");
    }
    return state;
  }

  async _pauseConsumer(consumer: any) {
    // if (consumer.paused)
    // 	return;
    try {
      await this._protoo.request("pauseConsumer", {
        consumerId: consumer.id,
        producerId: consumer.producerId,
        transportId: consumer.recvTransportId,
        transportType: "webrtc",
      });
      //consumer.resume();
    } catch (error) {
      this.reportLog(`_resumeConsumer() | failed:${error}`);
    }
  }

  async _resumeConsumer(consumer: Consumer) {
    // if (!consumer.paused)
    // 	return;
    try {
      await this._protoo.request("resumeConsumer", {
        consumerId: consumer.id,
        producerId: consumer.producerId,
        transportId: consumer.recvTransportId,
        transportType: "webrtc",
      });
      //consumer.resume();
    } catch (error) {
      this.reportLog(`_resumeConsumer() | failed:${error}`);
    }
  }

  getErrorInfo(code: Number) {
    switch (code) {
      case 16385:
        return { SIGNAL_CAHNNEL_SETUP_FAILED: "信令通道建立失败" };
      default:
        return {};
    }
  }

  // Get information for the corresponding resolution, such as whether it supports simucast, etc.
  getResolutionInfo(resolution: QRTCVideoResolution): ResolutionInfo {
    const resInfo: ResolutionInfo = {
      canScaleDown: true,
      maxDownBitrate: 100,
      maxUpBitrate: 300,
      resHeight: 1,
      resWidth: 1,
      scaleDownBy: 2
    };
  // Need to limit the maximum bitrate of each simulcast stream
    switch (resolution) {
      case QRTCVideoResolution.QRTCVideoResolution_120_120:
        resInfo.resWidth = 120;
        resInfo.resHeight = 120;
        resInfo.canScaleDown = false;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_160_160:
        resInfo.resWidth = 160;
        resInfo.resHeight = 160;
        resInfo.canScaleDown = false;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_270_270:
        resInfo.resWidth = 270;
        resInfo.resHeight = 270;
        resInfo.canScaleDown = false;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_480_480:
        resInfo.resWidth = 480;
        resInfo.resHeight = 480;
        resInfo.canScaleDown = false;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_160_120:
        resInfo.resWidth = 160;
        resInfo.resHeight = 120;
        resInfo.canScaleDown = false;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_240_180:
        resInfo.resWidth = 240;
        resInfo.resHeight = 180;
        resInfo.canScaleDown = false;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_280_210:
        resInfo.resWidth = 280;
        resInfo.resHeight = 210;
        resInfo.canScaleDown = false;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_320_240:
        resInfo.resWidth = 320;
        resInfo.resHeight = 240;
        resInfo.canScaleDown = true;
        resInfo.maxDownBitrate = 150; //kbps
        resInfo.maxUpBitrate = 375;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_400_300:
        resInfo.resWidth = 400;
        resInfo.resHeight = 300;
        resInfo.canScaleDown = false;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_480_360:
        resInfo.resWidth = 480;
        resInfo.resHeight = 360;
        resInfo.canScaleDown = true;
        resInfo.maxDownBitrate = 225; //kbps
        resInfo.maxUpBitrate = 600;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_640_480:
        resInfo.resWidth = 640;
        resInfo.resHeight = 480;
        resInfo.canScaleDown = true;
        resInfo.scaleDownBy = 4;
        resInfo.maxDownBitrate = 150; //kbps
        resInfo.maxUpBitrate = 900;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_960_720:
        resInfo.resWidth = 960;
        resInfo.resHeight = 720;
        resInfo.canScaleDown = true;
        resInfo.scaleDownBy = 4;
        resInfo.maxDownBitrate = 200; //kbps
        resInfo.maxUpBitrate = 1100;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_160_90:
        resInfo.resWidth = 160;
        resInfo.resHeight = 90;
        resInfo.canScaleDown = false;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_256_144:
        resInfo.resWidth = 256;
        resInfo.resHeight = 144;
        resInfo.canScaleDown = false;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_320_180:
        resInfo.resWidth = 320;
        resInfo.resHeight = 180;
        resInfo.canScaleDown = true;
        resInfo.maxDownBitrate = 200; //kbps
        resInfo.maxUpBitrate = 300;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_480_270:
        resInfo.resWidth = 480;
        resInfo.resHeight = 270;
        resInfo.canScaleDown = false;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_640_360:
        resInfo.resWidth = 640;
        resInfo.resHeight = 360;
        resInfo.canScaleDown = true;
        resInfo.scaleDownBy = 4;
        resInfo.maxDownBitrate = 200; //kbps
        resInfo.maxUpBitrate = 600;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_960_540:
        resInfo.resWidth = 960;
        resInfo.resHeight = 540;
        resInfo.canScaleDown = true;
        resInfo.maxDownBitrate = 400; //kbps
        resInfo.maxUpBitrate = 900;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_1280_720:
        resInfo.resWidth = 1280;
        resInfo.resHeight = 720;
        resInfo.canScaleDown = true;
        resInfo.scaleDownBy = 4;
        resInfo.maxDownBitrate = 300; //kbps
        resInfo.maxUpBitrate = 1300;
        break;
      case QRTCVideoResolution.QRTCVideoResolution_1920_1080:
        resInfo.resWidth = 1920;
        resInfo.resHeight = 1080;
        resInfo.canScaleDown = true;
        resInfo.scaleDownBy = 4;
        resInfo.maxDownBitrate = 400; //kbps
        resInfo.maxUpBitrate = 2100;
        break;
      default:
        break;
    }
    return resInfo;
  }

  //Enable device change monitoring
  async enableDevicesMonitor() {
    this.reportLog("[RoomClient] devicechanged");
    this._camDeviceList = await this.getCameraDeviceList();
    this._micDeviceList = await this.getMicDevicesList();
    this._speakerDeviceList = await this.getSpeakerDevicesList();

    navigator.mediaDevices.addEventListener("devicechange", async (event) => {
      this.reportLog(
        `[RoomClient] devicechanged event=${JSON.stringify(event)}`
      );
      const newCameraList = await this.getCameraDeviceList();
      const newMicList = await this.getMicDevicesList();
      const newSpeakerList = await this.getSpeakerDevicesList();
      let subSet = [];
      let deviceEvent: QRTCMediaDeviceChangeEvent =
        QRTCMediaDeviceChangeEvent.QRTCMediaDevice_Unknown;
      //let currentCameraDevice = newCameraList.find(obj => obj.deviceId == this._webcam.device.deviceId);
      if (newCameraList.length !== this._camDeviceList.length) {
        subSet = getSubSet(
          newCameraList as [any],
          this._camDeviceList as [any]
        );
        deviceEvent =
          newCameraList.length > this._camDeviceList.length
            ? QRTCMediaDeviceChangeEvent.QRTCMediaDevice_WebCamAdded
            : QRTCMediaDeviceChangeEvent.QRTCMediaDevice_WebCamRemoved;
        this.safeEmit("deviceChanged", {
          deviceId: subSet[0].deviceId,
          event: deviceEvent,
        });
        this.reportLog(
          `[RoomClient] cam changed deviceId=${subSet[0].deviceId}`
        );
      }
      if (newMicList.length !== this._micDeviceList.length) {
        subSet = getSubSet(newMicList as [any], this._micDeviceList as [any]);
        deviceEvent =
          newMicList.length > this._micDeviceList.length
            ? QRTCMediaDeviceChangeEvent.QRTCMediaDevice_MicAdded
            : QRTCMediaDeviceChangeEvent.QRTCMediaDevice_MicRemoved;
        this.safeEmit("deviceChanged", {
          deviceId: subSet[0].deviceId,
          event: deviceEvent,
        });
        this.reportLog(
          `[RoomClient] mic changed deviceId=${subSet[0].deviceId}`
        );
      }
      if (newSpeakerList.length !== this._speakerDeviceList.length) {
        subSet = getSubSet(
          newSpeakerList as [any],
          this._speakerDeviceList as [any]
        );
        deviceEvent =
          newSpeakerList.length > this._speakerDeviceList.length
            ? QRTCMediaDeviceChangeEvent.QRTCMediaDevice_SpeakerAdded
            : QRTCMediaDeviceChangeEvent.QRTCMediaDevice_SpeakerRemoved;
        this.safeEmit("deviceChanged", {
          deviceId: subSet[0].deviceId,
          event: deviceEvent
        });
        this.reportLog(
          `[RoomClient] speaker changed deviceId=${subSet[0].deviceId}`
        );
      }

      this._camDeviceList = newCameraList;
      this._micDeviceList = newMicList;
      this._speakerDeviceList = newSpeakerList;
    });
  }

  reportLog(msg: String) {
    console.log(msg);
    this.safeEmit("sdkLogReport", msg);
  }
  //#################################private funcs end#############################################

  //#################################SDK static func###############################
  static audioContext: AudioContext|null = null;
  static soundMeter: SoundMeter|null = null;
  static micTestVolume: number = 0;
  static micTestVolumeQueryInterval: any = null;
  static micStream: any = null;
// Enable microphone volume detection
static async enableMicTest() {
  try {
    // Instantiate AudioContext
    this.audioContext = new AudioContext();
  } catch (e) {
    console.log("Web audio API not supported.");
  }

  // SoundMeter is used for sound volume measurement
  this.soundMeter = new SoundMeter(this.audioContext);

  const constraints = { // eslint-disable-line
    // Enable audio
    audio: true,
    // Disable video
    video: false,
  };
  try {
    this.micStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    const audioTrack = this.micStream.getAudioTracks()[0]; // eslint-disable-line
    // Connect the sound measurement object to the stream
    this.soundMeter.connectToSource(this.micStream);
    // Start reading the volume value in real time, call the soundMeterProcess function every 200 milliseconds to simulate real-time detection of audio volume
    this.micTestVolumeQueryInterval = setInterval(() => {
      // Read the volume value, and then multiply it by a coefficient to obtain the width of the volume bar
      const instance: number = this.soundMeter!.instant;
      this.micTestVolume = instance * 348;
      console.log(`[RoomClient] micTestVolume = ${this.micTestVolume}`);
    }, 500);
  } catch (error) {}
  }
// Get microphone volume in the range of 0-348
static getMicTestVolume() {
  return this.micTestVolume;
}
// Stop microphone volume detection
static async disableMicTest() {
  // Stop the timer
  if (this.micTestVolumeQueryInterval) {
    clearInterval(this.micTestVolumeQueryInterval);
    this.micTestVolumeQueryInterval = null;
  }
  // Disconnect the sound meter
  this.soundMeter!.stop();
  // Close the stream
  if (this.micStream) {
    let tracks, i, len;
    // Check if the getTracks method exists
    if (this.micStream.getTracks) {
      // Get all tracks
      tracks = this.micStream.getTracks();
      // Iterate over all tracks
      for (i = 0, len = tracks.length; i < len; i += 1) {
        // Stop each track
        await tracks[i].stop();
      }
    } else {
      // Get all audio tracks
      tracks = this.micStream.getAudioTracks();
      // Iterate over all audio tracks
      for (i = 0, len = tracks.length; i < len; i += 1) {
        // Stop each track
        tracks[i].stop();
      }
    }
  }
  // Reset variables
    this.audioContext = null;
    this.soundMeter = null;
    this.micTestVolume = 0;
    this.micStream = null;
  }

// Test the connection status and time of a public IP (such as www.baidu.com)
static testHttpConnection(httpUrl?: string) {
  const p = new Promise(function (resolve, reject) {
    ping(httpUrl ? httpUrl : "https://baidu.com", 0.3)
      .then(function (delta: any) {
        console.log(`[RoomClient] Ping time was ${delta} ms`); //unit: ms
        resolve({ pingTime: delta }); //unit: ms
      })
      .catch(function (err: any) {
        console.error("Could not ping remote URL", err);
        reject(err);
      });
  });
  return p;
}

// Test the connection status and time of a websocket
  static testWSConnection(wsUrl: string) {
    const p = new Promise(function (resolve, reject) { // eslint-disable-line
      const protooTransport = new WebSocketTransport(wsUrl, {});
      protooTransport.on("open", () => { console.log("Test open") });
      protooTransport.on("connectionTimeOut", () => { console.log("Test connectionTimeOut") });
      protooTransport.on("error", () => { console.log("Test error") });
    });
    return p;
  }
}
