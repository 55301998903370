import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Outlet } from '@tanstack/react-router';

export function AppLayout() {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          width: '100vw',
        }}
      >
        <Container maxWidth="xs" sx={{ display: 'grid', placeItems: 'center' }}>
          <Outlet />
        </Container>
      </Box>
      <img
        src="power200.png"
        alt="Powered by Callpro"
        width={200}
        style={{ position: 'fixed', bottom: 10, right: 20 }}
      />
    </Box>
  );
}
