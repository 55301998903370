//const W3CWebSocket = require('websocket').w3cwebsocket;
import { w3cwebsocket as W3CWebSocket } from "websocket";
import * as retry from 'retry';
import { EnhancedEventEmitter } from '../EnhancedEventEmitter';
import { Message } from '../Message';

const WS_SUBPROTOCOL = 'protoo';
const DEFAULT_RETRY_OPTIONS = { //eslint-disable-line
    //retries: 5,
    factor: 2,
    maxRetryTime: 60*1000,
    maxTimeout: 8 * 1000,
    minTimeout: 1 * 1000
};

const ConnectState = {
    none: 1,
    connecting: 2, //eslint-disable-line
    connected: 3, //eslint-disable-line
    disconnected: 4,
    reconnecting: 5,
    reconnected: 6 //eslint-disable-line
}

export default class WebSocketTransport extends EnhancedEventEmitter {
    /**
     * @param {String} url - WebSocket URL.
     * @param {Object} [options] - Options for WebSocket-Node.W3CWebSocket and retry.
     */
    constructor(url, options) {
        super();

        // console.log('[WebSocketTransport] constructor() [url:%s, options:%o]', url, options);

        // Closed flag.
        // @type {Boolean}
        this._closed = false;

        // WebSocket URL.
        // @type {String}
        this._url = url;

        // Options.
        // @type {Object}
        this._options = options || {};

        // WebSocket instance.
        // @type {WebSocket}
        this._ws = null;

        // Heartbeat related
        this.isCheckSuccess = false
        this.pingCountwithoutPong = 0;
        this.sendHeartTimer = null
        this.checkDisconnectTimer = null

        this._retryOption = {
            //retries: 5,
            factor: 2,
            maxRetryTime: 60*1000,
            maxTimeout: 8 * 1000,
            minTimeout: 1 * 1000
        };

        this._connectState = ConnectState.none

        if (options.maxRetryTime !== undefined){
            this._retryOption.maxRetryTime = options.maxRetryTime
        }
        console.log(`[WebSocketTransport] retryOption=${JSON.stringify(this._retryOption)}`)
        this._pingFromServer = options.pingFromServer !== undefined ? options.pingFromServer:false;
        // Run the WebSocket.
        this._runWebSocket();
    }

    get closed() {
        return this._closed;
    }

    close() {
        if (this._closed)
            return;

        console.log('[WebSocketTransport] close()');

        // Don't wait for the WebSocket 'close' event, do it now.
        this._closed = true;
        this.safeEmit('close');

        try {
            this._ws.onopen = null;
            // this._ws.onclose = null;
            this._ws.onerror = null;
            this._ws.onmessage = null;
            this._clearPingTimer()
            this._ws.close();
        } catch (error) {
            console.log('[WebSocketTransport] close() | error closing the WebSocket: %o', error);
        }
    }

    disconnect() {
        console.log('[WebSocketTransport] ws.close');
        this._ws.close();
    }

    async send(message) { //eslint-disable-line
        if (this._closed)
           return
            //throw new Error('transport closed');

        try {
            this._ws.send(JSON.stringify(message));
        } catch (error) {
            console.log(`[WebSocketTransport] send() failed:${error}`);
            throw error;
        }
    }

    _runWebSocket() {
        const operation = retry.operation(this._retryOption);

        let wasConnected = false;

        operation.attempt((currentAttempt) => {
            if (this._closed) {
                operation.stop();
                return;
            }

            console.log(`[WebSocketTransport] _runWebSocket() [currentAttempt:${currentAttempt}]`);
            this._ws = new W3CWebSocket(
                this._url,
                WS_SUBPROTOCOL,
                this._options.origin,
                this._options.headers,
                this._options.requestOptions,
                this._options.clientConfig);

            const that = this;
            this._ws.onopen = () => {
                if (that._closed)
                    return;
                wasConnected = true;

                if (!this._options.pingFromServer) {
                    // Start heartbeat detection
                    this._checkHeart()
                }
    

                if (that._connectState === ConnectState.reconnecting) {
                    that._connectState = ConnectState.reconnected
                    console.log(`[WebSocketTransport] WebSocket connectState reconnected`)
                    that.safeEmit('reconnected');
                } else {
                    console.log(`[WebSocketTransport] WebSocket connectState connected`)
                    that._connectState = ConnectState.connected
                }

                // Emit 'open' event.
                this.safeEmit('open');
            };

            this._ws.onclose = (event) => {
                if (this._closed){
                    this.safeEmit('close');
                    return;
                }

                if (!this._options.pingFromServer) {
                // 停止心跳检测
                this._clearPingTimer()
                }

                console.log(`[WebSocketTransport] WebSocket close event [wasClean:${event.wasClean}, code:${event.code}, reason:${event.reason}]`);

                // Don't retry if code is 4000 (closed by the server).
                if (event.code !== 4000) {
                    // If it was not connected, try again.
                    if (!wasConnected) {
                        //this.safeEmit('failed', currentAttempt);                      
                        if (this._closed)
                            return;
          
                        if (this._connectState !== ConnectState.reconnecting) {
                            this._connectState = ConnectState.disconnected
                            console.log(`[WebSocketTransport] WebSocket connectState disconnected`)
                            this.safeEmit('disconnected');
                        }
                        this._connectState = ConnectState.reconnecting
                        console.log(`[WebSocketTransport] WebSocket connectState reconnecting...${currentAttempt}`)
                        this.safeEmit('tryToReconnect');
                        if (operation.retry(true))
                            return;
                    }
                    // If it was connected, start from scratch.
                    else {
                        operation.stop();

                        this.safeEmit('disconnected');

                        if (this._closed)
                            return;

                        this._runWebSocket();

                        return;
                    }
                }
                console.log(`[WebSocketTransport] WebSocket closed event:${event.code}`);

                this._closed = true;

                // Emit 'close' event.
                console.error(`[WebSocketTransport] WebSocket connectState connectionTimeOut`)
                this.safeEmit('connectionTimeOut');
            };

            this._ws.onerror = () => {
                if (this._closed)
                    return;

                this.safeEmit('error');
                console.log('WebSocket "error" event');
            };

            this._ws.onmessage = (event) => {
                if (this._closed)
                    return;

                const data = event.data

                if (data === 'pong') {
                    // console.error('return pong')
                    //console.log('[WebSocketTransport] received pong');
                    this.pingCountwithoutPong = 0
                } else {
                    
                    const message = Message.parse(data);
                    // console.log(`[WebSocketTransport] on message:${JSON.stringify(message)}`)
                    if (!message) {
                        //80000801 server error
                        this.safeEmit('errorParseMessage');
                        return;
                    }
                    

                    if (this.listenerCount('message') === 0) {
                        console.log(
                            'no listeners for WebSocket "message" event, ignoring received message');

                        return;
                    }

                    // Emit 'message' event.
                    this.safeEmit('message', message);
                }
            };
        });
    }


    _checkHeart() {
        // stop previous timer
        this._clearPingTimer()
        this.pingCountwithoutPong = 0

        // send ping
        // console.error(`发送ping---${new Date().getTime() / 1000}`)
        if(!this._closed) {
            this._ws.send("ping")
        }

        this.checkDisconnectTimer = setInterval(() => { // 9秒检测一次
            // console.error('检测结果--')

            if (this.pingCountwithoutPong>5) { 
              // 断线重连
                console.error('[WebSocketTransport] Heartbeat detection and reconnection on disconnection------------');
                // clear timer
                this._clearPingTimer()
                this.pingCountwithoutPong = 0
                this.disconnect()
            }
        }, 3000)

        this.sendHeartTimer = setInterval(() => {
            // console.error(`发送ping---${new Date().getTime() / 1000}`)
            //console.log('[WebSocketTransport] send ping');
            this._ws.send("ping")
        }, 2000)
    }

    _clearPingTimer() {
        clearInterval(this.checkDisconnectTimer)
        clearInterval(this.sendHeartTimer)
    }
}

//module.exports = WebSocketTransport;
