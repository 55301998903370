// import AgoraRTC, {
//   createClient,
//   createMicrophoneAudioTrack,
// } from 'agora-rtc-react';
// AgoraRTC.setLogLevel(2);
import RoomClient from "../qrtc_web_sdk/RoomClient100";

export const roomClient = new RoomClient();
// export const useClient = createClient({
//   mode: 'live',
//   codec: 'vp8',
// });

// export roomClient;
