import { Box, Typography } from '@mui/material';
import { logger } from 'util/logger';

export type ErrorProps = {
  message: string;
  display?: string;
};

export function Error(props: ErrorProps) {
  const { message, display } = props;

  logger(message);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <img
        src="cloud.jpeg"
        alt="Cloud"
        width={200}
        style={{ border: '1px solid tranparent', borderRadius: '50%' }}
      />
      <Typography sx={{ mt: 3, fontWeight: 'bold', color: '#0071BC' }}>
        {display || 'Системийн админтай холбогдоно уу'}
      </Typography>
    </Box>
  );
}
