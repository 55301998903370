/**
 * Clones the given object/array.
 *
 * @param {Object|Array} obj
 *
 * @returns {Object|Array}
 */
export function clone(obj: any): any
{
	if (typeof obj !== 'object')
		return {};

	return JSON.parse(JSON.stringify(obj));
}

/**
 * Generates a random positive integer.
 */
export function generateRandomNumber(): number
{
	return Math.round(Math.random() * 10000000);
}

export function getSubSet(arr1: any[], arr2: any[]) {
	const set1 = new Set(arr1);
	const set2 = new Set(arr2);
	const subset = [];

	for (const item of set1) {
		if (!set2.has(item)) {
			subset.push(item);
		}
	}
	return subset;
}

    /**
     * Creates and loads an image element by url.
     * @param  {String} url
     * @return {Promise} promise that resolves to an image element or
     *                   fails to an Error.
     */
	 function request_image(url:string) {
        return new Promise(function(resolve, reject) {
            const img = new Image();
            img.onload = function() { resolve(img); };
            img.onerror = function() { reject(url); };
            img.src = `${url  }?random-no-cache=${  Math.floor((1 + Math.random()) * 0x10000).toString(16)}`;
        });
    }

    /**
     * Pings a url.
     * @param  {String} url
     * @param  {Number} multiplier - optional, factor to adjust the ping by.  0.3 works well for HTTP servers.
     * @return {Promise} promise that resolves to a ping (ms, float).
     */
    export function ping(url:string, multiplier:number) {
        return new Promise(function(resolve, reject) {
            const start = (new Date()).getTime();
            const response = function() { 
                let delta = ((new Date()).getTime() - start);
                delta *= (multiplier || 1);
                resolve(delta); 
            };
            request_image(url).then(response).catch(response);
            
            // Set a timeout for max-pings, 5s.
            setTimeout(function() { reject(Error('Timeout')); }, 5000);
        });
    }
