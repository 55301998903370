import { createRoot } from 'react-dom/client';
import { QueryProvider, RouterProvider } from 'components/providers/tanstack';

import '@fontsource/nunito';
import { MuiProvider } from 'components/MuiProvider';

const element = document.getElementById('root') as HTMLElement;

const root = createRoot(element);

root.render(
  <MuiProvider>
    <QueryProvider>
      <RouterProvider />
    </QueryProvider>
  </MuiProvider>
);
