import EventEmitter from "events";


export class EnhancedEventEmitter extends EventEmitter
{
	constructor()
	{
		super();
		this.setMaxListeners(Infinity);
	}

	safeEmit(event: string, ...args: any[]): boolean
	{
		const numListeners = this.listenerCount(event);

		try
		{
			return this.emit(event, ...args);
		}
		catch (error)
		{
			console.log(
				'safeEmit() | event listener threw an error [event:%s]:%o',
				event, error);

			return Boolean(numListeners);
		}
	}

	// eslint-disable-next-line require-await
	async safeEmitAsPromise(event: string, ...args: any[]): Promise<any>
	{
		return new Promise((resolve, reject) => (
			this.safeEmit(event, ...args, resolve, reject) //eslint-disable-line
		));
	}
}
